import {memo, useMemo} from 'react';
import {useTranslation} from 'react-i18next';

import Events from './Events';

import {P1_X_P2} from '../../../Constants/MatchFilters';

const Markets = ({
  gameItem,
  displayKey,
  isDisabled,
  marketFilter,
  currentMarkets,
  marketColsCount,
}) => {
  const {t} = useTranslation();

  const marketsArray = useMemo(
    () =>
      Object.values(currentMarkets || {})?.find?.(
        item =>
          (marketFilter === P1_X_P2 && item?.type === P1_X_P2) ||
          (item?.display_key === displayKey &&
            item?.display_sub_key === marketFilter),
      ),
    [currentMarkets, displayKey, marketFilter],
  );

  const EventsArray = useMemo(
    () => Object.values(marketsArray?.event || {}),
    [marketsArray?.event],
  );

  return EventsArray?.length ? (
    <Events
      gameItem={gameItem}
      displayKey={displayKey}
      marketsArray={marketsArray}
      isSuspended={EventsArray?.length < 0 || !!isDisabled}
    />
  ) : (
    <div
      style={{
        background: '#01010126',
        width: marketColsCount === 2 ? 95 : 145,
      }}
      className="px-md paddingVertical-9 rounded-borders flex items-center justify-center">
      <span style={{color: '#021F4B'}} className="bold-600 font-13">
        {t('suspended')}
      </span>
    </div>
  );
};

export default memo(Markets);
