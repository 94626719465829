import React, {
  memo,
  useRef,
  useMemo,
  useState,
  useEffect,
  useCallback,
} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';

import {
  getWssSocket,
  getIsLoggedIn,
} from '../../../../Redux/AppSlice/AppSelectors';
import {setShowMobileBetSlip} from '../../../../Redux/AppSlice';
import {getUser} from '../../../../Redux/UserSlice/UserSelectors';
import {getCurrency} from '../../../../Redux/PartnerSlice/PartnerSelectors';

import {useScreenSize, useSocket} from '../../../../Hooks';

import {fixedNumberWithoutRound} from '../../../../Utils/GetOddValue';
import {bigNumbersPrettier} from '../../../../Utils/BigNumbersPrettier';
import {getCombinations} from '../../../../Utils/CombinationsGenerator';

import {
  DO_BET,
  BOOK_BET,
  GET_MAX_BET,
  SOCKET_RESPONSES,
  GET_FREE_BETS_FOR_BETSLIP,
} from '../../../../Constants/Socket';
import {
  SINGLE_BET,
  SYSTEM_BET,
  MULTIPLE_BET,
} from '../../../../Constants/Betting';
import {BREAKPOINT_XS} from '../../../../Constants/Globals';

import {
  AppInput,
  AppButton,
  AppSwitcher,
  AppNewCallToAction,
} from '../../../UI';
import FreeBet from '../FreeBet';
import BonusInfo from '../BonusInfo';
import ErrorContainer from '../ErrorContainer';
import CalculationsBlock from '../CalculationsBlock';

const OddsContainer = ({
  betType,
  setBetId,
  oddsAmount,
  hasLiveEvent,
  allEventsData,
  systemBetCount,
  isBettingBlocked,
  removeAllHandler,
  notAllowToBetData,
  removeItemHandler,
  setIsSuccessModalVisible,
}) => {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const {width} = useScreenSize();
  const {getMaxBet, doBet, bookBet, getFreeBetsForBetSlip} = useSocket();

  const user = useSelector(getUser);
  const currency = useSelector(getCurrency);
  const wssSocket = useSelector(getWssSocket);
  const isLoggedIn = useSelector(getIsLoggedIn);

  const callsCountRef = useRef(0);
  const stakeInputRef = useRef(null);
  const copyTimeoutRef = useRef(null);
  const successBetsCountRef = useRef(0);

  const [errorMsg, setErrorMsg] = useState(null);
  const [stakeAmount, setStakeAmount] = useState('');
  const [freeBetData, setFreeBetData] = useState([]);
  const [isMaxLoading, setIsMaxLoading] = useState(false);
  const [copyText, setCopyText] = useState('copyBetUpper');
  const [isCopyLoading, setIsCopyLoading] = useState(false);
  const [enableFreeBet, setEnableFreeBet] = useState(false);
  const [selectedFreeBet, setSelectedFreeBet] = useState({});
  const [acceptOddChanges, setAcceptOddChanges] = useState(true);
  const [isPlaceBetLoading, setIsPlaceBetLoading] = useState(false);

  const type = useMemo(
    () =>
      betType === SINGLE_BET
        ? 1
        : betType === MULTIPLE_BET
        ? 2
        : betType === SYSTEM_BET
        ? 3
        : 4,
    [betType],
  );

  const eventIdsArray = useMemo(
    () => allEventsData?.map(item => item?.id),
    [allEventsData],
  );

  const eventsCount = useMemo(
    () => eventIdsArray?.length,
    [eventIdsArray?.length],
  );

  const showCalculationsBlock = useMemo(
    () =>
      !!stakeAmount?.trim?.() &&
      +stakeAmount >= 1 &&
      (betType !== SINGLE_BET || (eventsCount === 1 && betType === SINGLE_BET)),
    [betType, eventsCount, stakeAmount],
  );

  const isOddChanged = useMemo(
    () =>
      allEventsData?.find(
        item => item?.lastPrice && item?.lastPrice !== item?.price,
      ),
    [allEventsData],
  );

  const showCantCombineWarning = useMemo(
    () =>
      !!notAllowToBetData?.length && betType !== SINGLE_BET && eventsCount > 1,
    [betType, eventsCount, notAllowToBetData?.length],
  );

  const showBalanceError = useMemo(
    () =>
      isLoggedIn &&
      !selectedFreeBet?.id &&
      !enableFreeBet &&
      +user?.balance <
        (+stakeAmount ||
          allEventsData?.reduce(
            (acc, curr) =>
              +acc + (!curr?.isEventDeleted && (+curr.amount || 0)),
            0,
          )),
    [
      isLoggedIn,
      stakeAmount,
      allEventsData,
      enableFreeBet,
      user?.balance,
      selectedFreeBet?.id,
    ],
  );

  const isPlaceBetDisabled = useMemo(() => {
    if (showBalanceError) {
      return true;
    }
    if (
      !isLoggedIn ||
      isBettingBlocked ||
      (!!isOddChanged && !acceptOddChanges)
    ) {
      return true;
    }
    if (betType !== SINGLE_BET) {
      if (
        !stakeAmount?.trim() ||
        +stakeAmount <= 0 ||
        !!notAllowToBetData?.length
      ) {
        return true;
      }
    } else {
      if (eventsCount === 1 && (!stakeAmount?.trim() || +stakeAmount <= 0)) {
        return true;
      }
      if (eventsCount > 1 && allEventsData?.every(item => !item?.amount)) {
        return true;
      }
    }
  }, [
    betType,
    isLoggedIn,
    stakeAmount,
    eventsCount,
    isOddChanged,
    allEventsData,
    showBalanceError,
    isBettingBlocked,
    acceptOddChanges,
    notAllowToBetData?.length,
  ]);

  const placeBet = useCallback(() => {
    setIsPlaceBetLoading(true);
    const betsArray = allEventsData?.map?.(item => ({
      event_id: item?.id,
      price: item?.price,
      isEventDeleted: item?.isEventDeleted,
    }));
    if (betType === SINGLE_BET && eventsCount !== 1) {
      betsArray?.map(({event_id, price}) => {
        const currentEventAmount =
          +allEventsData?.find(item => item?.id === event_id)?.amount || 0;
        if (currentEventAmount > 0) {
          callsCountRef.current++;
          doBet({
            type,
            amount: +currentEventAmount,
            mode: acceptOddChanges ? 2 : 0,
            betsArray: [{event_id, price}],
            bonus_id:
              enableFreeBet && !!selectedFreeBet?.id
                ? selectedFreeBet?.id
                : null,
          });
        }
      });
    } else {
      doBet({
        type,
        betsArray,
        bonus_id:
          enableFreeBet && !!selectedFreeBet?.id ? selectedFreeBet?.id : null,
        amount:
          betType === SYSTEM_BET
            ? +stakeAmount *
              getCombinations(allEventsData?.length, +systemBetCount)?.length
            : +stakeAmount,
        mode: acceptOddChanges ? 2 : 0,
        ...(betType === SYSTEM_BET && {sys_bet: systemBetCount}),
      });
    }
  }, [
    type,
    doBet,
    betType,
    eventsCount,
    stakeAmount,
    allEventsData,
    enableFreeBet,
    systemBetCount,
    acceptOddChanges,
    selectedFreeBet?.id,
  ]);

  const copyBetPressHandler = useCallback(() => {
    clearTimeout(copyTimeoutRef.current);
    setIsCopyLoading(true);
    const bets = allEventsData?.map?.(item => ({
      event_id: item?.id,
      price: item?.price,
    }));

    bookBet({
      type,
      bets,
      amount:
        betType === SYSTEM_BET
          ? +stakeAmount *
            getCombinations(allEventsData?.length, +systemBetCount)?.length
          : +stakeAmount,
      ...(betType === SYSTEM_BET && {sys_bet: systemBetCount}),
    });
  }, [allEventsData, betType, bookBet, stakeAmount, systemBetCount, type]);

  const getMax = useCallback(() => {
    setIsMaxLoading(true);
    getMaxBet({
      type,
      eventsArray: eventIdsArray,
      ...(type === 3 && {sys_min_count: systemBetCount}),
    });
  }, [eventIdsArray, getMaxBet, systemBetCount, type]);

  const onMessageCb = useCallback(
    event => {
      const data = JSON.parse(event?.data);
      switch (data?.rid) {
        case DO_BET:
          setBetId(data?.data?.details?.bet_id);
          if (data?.data?.result === SOCKET_RESPONSES.OK) {
            setErrorMsg('');
            successBetsCountRef.current++;
            if (betType === SINGLE_BET && eventsCount !== 1) {
              removeItemHandler(data?.data?.details?.events?.[0]?.selection_id);
              if (successBetsCountRef.current === callsCountRef.current) {
                callsCountRef.current = 0;
                successBetsCountRef.current = 0;
                setIsSuccessModalVisible(true);
              }
            } else if (betType !== SINGLE_BET || eventsCount === 1) {
              removeAllHandler();
              setIsSuccessModalVisible(true);
            }
            if (width < BREAKPOINT_XS) {
              dispatch(setShowMobileBetSlip(false));
            }
          } else {
            callsCountRef.current = 0;
            successBetsCountRef.current = 0;
            stakeInputRef?.current?.blur();
            switch (data?.data?.result) {
              case SOCKET_RESPONSES.BET_STAKE_LIMIT:
                setErrorMsg(t('minimumStakeError', {currency}));
                break;
              case SOCKET_RESPONSES.MAX_STAKE_ERROR:
                setErrorMsg(
                  t('maximumStakeError', {
                    maxBet: data?.data?.details?.max_bet,
                    currency,
                  }),
                );
                break;
              case SOCKET_RESPONSES.BET_SELECTION_CHANGED:
                setErrorMsg(t('betSelectionChanged'));
                break;
              default:
                setErrorMsg(data?.data?.result_text);
                break;
            }
          }
          setIsPlaceBetLoading(false);
          break;
        case GET_MAX_BET:
          if (data?.data?.result === 0) {
            setStakeAmount(data?.data?.details?.amount?.toString?.());
          }
          setIsMaxLoading(false);

          break;
        case BOOK_BET:
          if (data?.data?.result === 0) {
            navigator.clipboard
              .writeText(data?.data?.details?.number?.toString?.())
              ?.then(() => {
                setCopyText('betCopiedUpper');
                setTimeout(() => {
                  setCopyText('copyBetUpper');
                }, 2000);
              })
              ?.catch(() => {});
          }
          setIsCopyLoading(false);
          break;
        case GET_FREE_BETS_FOR_BETSLIP:
          if (data?.data?.result === 0 && !!data?.data?.details?.length) {
            setFreeBetData(data?.data?.details);
            setSelectedFreeBet(data?.data?.details?.[0]);
            setStakeAmount(data?.data?.details?.[0]?.amount?.toString?.());
          } else {
            setFreeBetData([]);
            setSelectedFreeBet({});
          }
          break;
        default:
          break;
      }
    },
    [
      t,
      width,
      betType,
      setBetId,
      dispatch,
      currency,
      eventsCount,
      removeAllHandler,
      removeItemHandler,
      setIsSuccessModalVisible,
    ],
  );

  useEffect(() => {
    if (isLoggedIn && user?.has_free_bets && !isBettingBlocked) {
      const betsArray = allEventsData?.map?.(item => ({
        SelectionId: item?.id,
        Coeficient: item?.price,
      }));
      getFreeBetsForBetSlip({
        betsArray,
        IsLive: hasLiveEvent,
      });
    }
  }, [
    isLoggedIn,
    hasLiveEvent,
    allEventsData,
    isBettingBlocked,
    user?.has_free_bets,
    getFreeBetsForBetSlip,
  ]);

  useEffect(() => {
    wssSocket?.addEventListener('message', onMessageCb);

    return () => {
      wssSocket?.removeEventListener('message', onMessageCb);
    };
  }, [onMessageCb, wssSocket]);

  return (
    <div className="oddsContainerWrapper column gap-10">
      {(betType !== SINGLE_BET || eventsCount === 1) && (
        <>
          {betType === MULTIPLE_BET && <AppNewCallToAction />}
          <div className="row items-center justify-between paddingVertical-7 blueText whiteText bold-700 ">
            <span className="font-16">{t('totalOdds')}</span>
            <span className="font-15">
              {bigNumbersPrettier(fixedNumberWithoutRound(oddsAmount))}
            </span>
          </div>
          {(!selectedFreeBet?.id || !enableFreeBet) && (
            <div className="row items-center justify-between gap-5">
              <div className="row items-center gap-5 full-width">
                <AppInput
                  type={7}
                  height={40}
                  error={errorMsg}
                  inputType="number"
                  inputRef={stakeInputRef}
                  inputValue={stakeAmount}
                  setErrorMsg={setErrorMsg}
                  onChange={setStakeAmount}
                  className="bold-700 font-14 mx-md"
                  placeholder={t('amountPlaceholder')}
                />

                {/*{isLoggedIn && (*/}
                {/*  <AppButton*/}
                {/*    type={1}*/}
                {/*    height={45}*/}
                {/*    onClick={getMax}*/}
                {/*    className="pa-md"*/}
                {/*    width="fit-content"*/}
                {/*    loading={isMaxLoading}*/}
                {/*    title={*/}
                {/*      <span className="blueText bold-600 font-15">*/}
                {/*        {t('max')}*/}
                {/*      </span>*/}
                {/*    }*/}
                {/*  />*/}
                {/*)}*/}
              </div>
            </div>
          )}
        </>
      )}

      {showCalculationsBlock && (
        <CalculationsBlock
          odds={oddsAmount}
          betType={betType}
          eventsCount={eventsCount}
          stakeAmount={stakeAmount}
          allEventsData={allEventsData}
          systemBetCount={systemBetCount}
        />
      )}
      {isLoggedIn && (
        <div
          className={`row items-center justify-${
            betType === MULTIPLE_BET ? 'between' : 'end'
          }`}>
          {betType === MULTIPLE_BET && <BonusInfo />}
          <div className="self-end flex">
            <AppSwitcher
              type={5}
              isToggled={acceptOddChanges}
              labelClassName="font-12 whiteText"
              label={t('acceptOddChanges')}
              onToggle={() => setAcceptOddChanges(prevState => !prevState)}
            />
          </div>
        </div>
      )}
      <ErrorContainer
        showBalanceError={showBalanceError}
        isBettingBlocked={isBettingBlocked}
        showCantCombineWarning={showCantCombineWarning}
      />
      <div className="flex gap-4">
        <AppButton
          type={1}
          height={40}
          fontSize={15}
          loading={isCopyLoading}
          disabled={isCopyLoading}
          title={`${t(copyText)}`}
          onClick={copyBetPressHandler}
          titleClassName="bold-600"
          className="flex items-center justify-center"
        />
        {(!!stakeAmount?.trim() ||
          (betType === SINGLE_BET && eventsCount > 1)) && (
          <AppButton
            type={3}
            height={40}
            fontSize={15}
            onClick={placeBet}
            titleClassName="bold-600"
            title={t('placeBet')}
            loading={isPlaceBetLoading}
            disabled={isPlaceBetDisabled}
            className="flex items-center justify-center"
          />
        )}
      </div>
      {isLoggedIn &&
        user?.has_free_bets &&
        !isBettingBlocked &&
        !!freeBetData?.length && (
          <FreeBet
            freeBetData={freeBetData}
            enableFreeBet={enableFreeBet}
            setStakeAmount={setStakeAmount}
            selectedFreeBet={selectedFreeBet}
            setEnableFreeBet={setEnableFreeBet}
            setSelectedFreeBet={setSelectedFreeBet}
          />
        )}
    </div>
  );
};

export default memo(OddsContainer);
