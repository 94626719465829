export const SportIcons = {
  Soccer: require('../Assets/Icons/Sport/Soccer.png'),
  IceHockey: require('../Assets/Icons/Sport/IceHockey.png'),
  Basketball: require('../Assets/Icons/Sport/Basketball.png'),
  Tennis: require('../Assets/Icons/Sport/Tennis.png'),
  Volleyball: require('../Assets/Icons/Sport/Volleyball.png'),
  AmericanFootball: require('../Assets/Icons/Sport/AmericanFootball.png'),
  AustralianFootball: require('../Assets/Icons/Sport/AustralianFootball.png'),
  BallHockey: require('../Assets/Icons/Sport/BallHockey.png'),
  Baseball: require('../Assets/Icons/Sport/Baseball.png'),
  Boxing: require('../Assets/Icons/Sport/Boxing.png'),
  Chess: require('../Assets/Icons/Sport/Chess.png'),
  Cricket: require('../Assets/Icons/Sport/Cricket.png'),
  Cycling: require('../Assets/Icons/Sport/Cycling.png'),
  Darts: require('../Assets/Icons/Sport/Darts.png'),
  Formula1: require('../Assets/Icons/Sport/Formula1.png'),
  Futsal: require('../Assets/Icons/Sport/Futsal.png'),
  Curling: require('../Assets/Icons/Sport/Curling.png'),
  Rally: require('../Assets/Icons/Sport/Rally.png'),
  CrossFire: require('../Assets/Icons/Sport/CrossFire.png'),
  RainbowSix: require('../Assets/Icons/Sport/RainbowSix.png'),
  CompoundArchery: require('../Assets/Icons/Sport/CompoundArchery.png'),
  GaelicFootball: require('../Assets/Icons/Sport/GaelicFootball.png'),
  BeachFootball: require('../Assets/Icons/Sport/BeachFootball.png'),
  Athletics: require('../Assets/Icons/Sport/Athletics.png'),
  StarCraft2: require('../Assets/Icons/Sport/StarCraft2.png'),
  FutureHorseRacing: require('../Assets/Icons/Sport/FutureHorseRacing.png'),
  SISGreyhound: require('../Assets/Icons/Sport/SISGreyhound.png'),
  Golf: require('../Assets/Icons/Sport/Golf.png'),
  Hockey: require('../Assets/Icons/Sport/Hockey.png'),
  RinkHockey: require('../Assets/Icons/Sport/RinckHockey.png'),
  Softball: require('../Assets/Icons/Sport/Softball.png'),
  Pool: require('../Assets/Icons/Sport/Pool.png'),
  Sumo: require('../Assets/Icons/Sport/Sumo.png'),
  Pesapallo: require('../Assets/Icons/Sport/Pesapallo.png'),
  Teqball: require('../Assets/Icons/Sport/Teqball.png'),
  Bowls: require('../Assets/Icons/Sport/Bowls.png'),
  CrossCountrySkiing: require('../Assets/Icons/Sport/CrossCountrySkiing.png'),
  ArcheryH2H: require('../Assets/Icons/Sport/ArcheryH2H.png'),
  BeachVolleyball: require('../Assets/Icons/Sport/BeachVolleyball.png'),
  KingOfGlory: require('../Assets/Icons/Sport/KingOfGlory.png'),
  VirtualDragRacing: require('../Assets/Icons/Sport/VirtualDragRacing.png'),
  Handball: require('../Assets/Icons/Sport/Handball.png'),
  Lacross: require('../Assets/Icons/Sport/Lacross.png'),
  StarCraft: require('../Assets/Icons/Sport/StarCraft.png'),
  Netball: require('../Assets/Icons/Sport/Netball.png'),
  Weightlifting: require('../Assets/Icons/Sport/Weightlifting.png'),
  Squash: require('../Assets/Icons/Sport/Squash.png'),
  WarcraftIII: require('../Assets/Icons/Sport/WarcraftIII.png'),
  ApexLegends: require('../Assets/Icons/Sport/ApexLegends.png'),
  VirtualGreyhoundRacing: require('../Assets/Icons/Sport/VirtualGreyhoundRacing.png'),
  VirtualMarbleRacing: require('../Assets/Icons/Sport/VirtualMarbleRacing.png'),
  VirtualHorseRacing: require('../Assets/Icons/Sport/VirtualHorseRacing.png'),
  VirtualFootballPro: require('../Assets/Icons/Sport/VirtualFootballPro.png'),
  VirtualBicycle: require('../Assets/Icons/Sport/VirtualBicycle.png'),
  VirtualFootballLeague: require('../Assets/Icons/Sport/VirtualFootballLeague.png'),
  Archery: require('../Assets/Icons/Sport/Archery.png'),
  Speedway: require('../Assets/Icons/Sport/Speedway.png'),
  Yachting: require('../Assets/Icons/Sport/Sailing.png'),
  Eurovision: require('../Assets/Icons/Sport/Eurovision.png'),
  GrecoRomanWrestling: require('../Assets/Icons/Sport/GrecoRomanWrestling.png'),
  FreestyleWrestling: require('../Assets/Icons/Sport/FreestyleWrestling.png'),
  PistolShooting: require('../Assets/Icons/Sport/PistolShooting.png'),
  HorseRacing: require('../Assets/Icons/Sport/HorseRacing.png'),
  ThePenaltyKicks: require('../Assets/Icons/Sport/ThePenaltyKicks.png'),
  Nascar: require('../Assets/Icons/Sport/Nascar.png'),
  Badminton: require('../Assets/Icons/Sport/Badminton.png'),
  Valorant: require('../Assets/Icons/Sport/Valorant.png'),
  Floorball: require('../Assets/Icons/Sport/Floorball.png'),
  Dota2: require('../Assets/Icons/Sport/Dota2.png'),
  RocketLeague: require('../Assets/Icons/Sport/RocketLeague.png'),
  Surfing: require('../Assets/Icons/Sport/Surfing.png'),
  PistolHead2Head: require('../Assets/Icons/Sport/Shooting.png'),
  CounterStrike: require('../Assets/Icons/Sport/CounterStrike.png'),
  MobileLegends: require('../Assets/Icons/Sport/MobileLegends.png'),
  LeagueofLegendsWildRift: require('../Assets/Icons/Sport/LeagueofLegendsWildRift.png'),
  CallOfDuty: require('../Assets/Icons/Sport/CallofDuty.png'),
  ArenaofValor: require('../Assets/Icons/Sport/ArenaOfValor.png'),
  '3x3Basketball': require('../Assets/Icons/Sport/3x3Basketball.png'),
  AutoRacing: require('../Assets/Icons/Sport/AutoRacing.png'),
  Triathlon: require('../Assets/Icons/Sport/Triathlon.png'),
  BrawlStars: require('../Assets/Icons/Sport/BrawlStars.png'),
  Motorsport: require('../Assets/Icons/Sport/Motorsport.png'),
  Overwatch: require('../Assets/Icons/Sport/OverWatch.png'),
  BasketballShots: require('../Assets/Icons/Sport/BasketballShots.png'),
  RugbyLeague: require('../Assets/Icons/Sport/RugbyLeague.png'),
  RugbyUnion: require('../Assets/Icons/Sport/RugbyUnion.png'),
  RugbySevens: require('../Assets/Icons/Sport/RugbySevens.png'),
  Snooker: require('../Assets/Icons/Sport/Snooker.png'),
  AgeofEmpires: require('../Assets/Icons/Sport/AgeOfEmpires.png'),
  TableTennis: require('../Assets/Icons/Sport/TableTennis.png'),
  WaterPolo: require('../Assets/Icons/Sport/WaterPolo.png'),
  Mma: require('../Assets/Icons/Sport/Mma.png'),
  CyberFootball: require('../Assets/Icons/Sport/CyberFootball.png'),
  EBasketball: require('../Assets/Icons/Sport/EBasketball.png'),
  LeagueOfLegends: require('../Assets/Icons/Sport/LeagueOfLegends.png'),
  Politics: require('../Assets/Icons/Sport/Politics.png'),
  Biathlon: require('../Assets/Icons/Sport/Biathlon.png'),
  SpecialBets: require('../Assets/Icons/Sport/SpecialBets.png'),
  Oscar: require('../Assets/Icons/Sport/Oscar.png'),
  Pelota: require('../Assets/Icons/Sport/Pelota.png'),
  Olympics: require('../Assets/Icons/Sport/Olympics.png'),
  Swimming: require('../Assets/Icons/Sport/Swimming.png'),
  TVShowsAndMovies: require('../Assets/Icons/Sport/TVShowsAndMovies.png'),
  GeoGuessr: require('../Assets/Icons/Sport/GeoGuessr.png'),
  Deadlock: require('../Assets/Icons/Sport/Deadlock.png'),
  SkiJumping: require('../Assets/Icons/Sport/SkiJumping.png'),
  Skateboarding: require('../Assets/Icons/Sport/Skateboarding.png'),
};

// OLD ICONS WITH SVG's

// export const SportIcons = {
//   Soccer: require('../Assets/Icons/Sport/Soccer.svg').default,
//   IceHockey: require('../Assets/Icons/Sport/IceHockey.svg').default,
//   Basketball: require('../Assets/Icons/Sport/Basketball.svg').default,
//   Tennis: require('../Assets/Icons/Sport/Tennis.svg').default,
//   Volleyball: require('../Assets/Icons/Sport/Volleyball.svg').default,
//   AmericanFootball: require('../Assets/Icons/Sport/AmericanFootball.svg')
//       .default,
//   AustralianFootball: require('../Assets/Icons/Sport/AustralianFootball.svg')
//       .default,
//   BallHockey: require('../Assets/Icons/Sport/BallHockey.svg').default,
//   Baseball: require('../Assets/Icons/Sport/Baseball.svg').default,
//   Boxing: require('../Assets/Icons/Sport/Boxing.svg').default,
//   Chess: require('../Assets/Icons/Sport/Chess.svg').default,
//   Cricket: require('../Assets/Icons/Sport/Cricket.svg').default,
//   Cycling: require('../Assets/Icons/Sport/Cycling.svg').default,
//   Darts: require('../Assets/Icons/Sport/Darts.svg').default,
//   Formula1: require('../Assets/Icons/Sport/Formula1.svg').default,
//   Futsal: require('../Assets/Icons/Sport/Futsal.svg').default,
//   Curling: require('../Assets/Icons/Sport/Curling.svg').default,
//   Rally: require('../Assets/Icons/Sport/Rally.svg').default,
//   CrossFire: require('../Assets/Icons/Sport/CrossFire.svg').default,
//   RainbowSix: require('../Assets/Icons/Sport/RainbowSix.svg').default,
//   CompoundArchery: require('../Assets/Icons/Sport/CompoundArchery.svg').default,
//   GaelicFootball: require('../Assets/Icons/Sport/GaelicFootball.svg').default,
//   BeachFootball: require('../Assets/Icons/Sport/BeachFootball.svg').default,
//   Athletics: require('../Assets/Icons/Sport/Athletics.svg').default,
//   StarCraft2: require('../Assets/Icons/Sport/StarCraft2.svg').default,
//   FutureHorseRacing: require('../Assets/Icons/Sport/FutureHorseRacing.svg')
//       .default,
//   SISGreyhound: require('../Assets/Icons/Sport/SISGreyhound.svg').default,
//   Golf: require('../Assets/Icons/Sport/Golf.svg').default,
//   Hockey: require('../Assets/Icons/Sport/Hockey.svg').default,
//   RinkHockey: require('../Assets/Icons/Sport/RinckHockey.svg').default,
//   Softball: require('../Assets/Icons/Sport/Softball.svg').default,
//   Pool: require('../Assets/Icons/Sport/Pool.svg').default,
//   Sumo: require('../Assets/Icons/Sport/Sumo.svg').default,
//   Pesapallo: require('../Assets/Icons/Sport/Pesapallo.svg').default,
//   Teqball: require('../Assets/Icons/Sport/Teqball.svg').default,
//   Bowls: require('../Assets/Icons/Sport/Bowls.svg').default,
//   CrossCountrySkiing: require('../Assets/Icons/Sport/CrossCountrySkiing.svg')
//       .default,
//   ArcheryH2H: require('../Assets/Icons/Sport/ArcheryH2H.svg').default,
//   BeachVolleyball: require('../Assets/Icons/Sport/BeachVolleyball.svg').default,
//   KingOfGlory: require('../Assets/Icons/Sport/KingOfGlory.svg').default,
//   VirtualDragRacing: require('../Assets/Icons/Sport/VirtualDragRacing.svg')
//       .default,
//   Handball: require('../Assets/Icons/Sport/Handball.svg').default,
//   Lacross: require('../Assets/Icons/Sport/Lacross.svg').default,
//   StarCraft: require('../Assets/Icons/Sport/StarCraft.svg').default,
//   Netball: require('../Assets/Icons/Sport/Netball.svg').default,
//   Weightlifting: require('../Assets/Icons/Sport/Weightlifting.svg').default,
//   Squash: require('../Assets/Icons/Sport/Squash.svg').default,
//   WarcraftIII: require('../Assets/Icons/Sport/WarcraftIII.svg').default,
//   ApexLegends: require('../Assets/Icons/Sport/ApexLegends.svg').default,
//   VirtualGreyhoundRacing:
//   require('../Assets/Icons/Sport/VirtualGreyhoundRacing.svg').default,
//   VirtualMarbleRacing: require('../Assets/Icons/Sport/VirtualMarbleRacing.svg')
//       .default,
//   VirtualHorseRacing: require('../Assets/Icons/Sport/VirtualHorseRacing.svg')
//       .default,
//   VirtualFootballPro: require('../Assets/Icons/Sport/VirtualFootballPro.svg')
//       .default,
//   VirtualBicycle: require('../Assets/Icons/Sport/VirtualBicycle.svg').default,
//   VirtualFootballLeague:
//   require('../Assets/Icons/Sport/VirtualFootballLeague.svg').default,
//   Archery: require('../Assets/Icons/Sport/Archery.svg').default,
//   Speedway: require('../Assets/Icons/Sport/Speedway.svg').default,
//   Yachting: require('../Assets/Icons/Sport/Sailing.svg').default,
//   Eurovision: require('../Assets/Icons/Sport/Eurovision.svg').default,
//   GrecoRomanWrestling: require('../Assets/Icons/Sport/GrecoRomanWrestling.svg')
//       .default,
//   FreestyleWrestling: require('../Assets/Icons/Sport/FreestyleWrestling.svg')
//       .default,
//   PistolShooting: require('../Assets/Icons/Sport/PistolShooting.svg').default,
//   HorseRacing: require('../Assets/Icons/Sport/HorseRacing.svg').default,
//   ThePenaltyKicks: require('../Assets/Icons/Sport/ThePenaltyKicks.svg').default,
//   Nascar: require('../Assets/Icons/Sport/Nascar.svg').default,
//   Badminton: require('../Assets/Icons/Sport/Badminton.svg').default,
//   Valorant: require('../Assets/Icons/Sport/Valorant.svg').default,
//   Floorball: require('../Assets/Icons/Sport/Floorball.svg').default,
//   Dota2: require('../Assets/Icons/Sport/Dota2.svg').default,
//   RocketLeague: require('../Assets/Icons/Sport/RocketLeague.svg').default,
//   Surfing: require('../Assets/Icons/Sport/Surfing.svg').default,
//   PistolHead2Head: require('../Assets/Icons/Sport/Shooting.svg').default,
//   CounterStrike: require('../Assets/Icons/Sport/CounterStrike.svg').default,
//   MobileLegends: require('../Assets/Icons/Sport/MobileLegends.svg').default,
//   LeagueofLegendsWildRift:
//   require('../Assets/Icons/Sport/LeagueofLegendsWildRift.svg').default,
//   CallOfDuty: require('../Assets/Icons/Sport/CallofDuty.svg').default,
//   ArenaofValor: require('../Assets/Icons/Sport/ArenaOfValor.svg').default,
//   '3x3Basketball': require('../Assets/Icons/Sport/3x3Basketball.svg').default,
//   AutoRacing: require('../Assets/Icons/Sport/AutoRacing.svg').default,
//   Triathlon: require('../Assets/Icons/Sport/Triathlon.svg').default,
//   BrawlStars: require('../Assets/Icons/Sport/BrawlStars.svg').default,
//   Motorsport: require('../Assets/Icons/Sport/Motorbikes.svg').default,
//   Overwatch: require('../Assets/Icons/Sport/OverWatch.svg').default,
//   BasketballShots: require('../Assets/Icons/Sport/BasketballShots.svg').default,
//   RugbyLeague: require('../Assets/Icons/Sport/RugbyLeague.svg').default,
//   RugbyUnion: require('../Assets/Icons/Sport/RugbyUnion.svg').default,
//   RugbySevens: require('../Assets/Icons/Sport/RugbyUnion.svg').default,
//   Snooker: require('../Assets/Icons/Sport/Snooker.svg').default,
//   AgeofEmpires: require('../Assets/Icons/Sport/AgeOfEmpires.svg').default,
//   TableTennis: require('../Assets/Icons/Sport/TableTennis.svg').default,
//   WaterPolo: require('../Assets/Icons/Sport/WaterPolo.svg').default,
//   Mma: require('../Assets/Icons/Sport/Mma.svg').default,
//   CyberFootball: require('../Assets/Icons/Sport/CyberFootball.svg').default,
//   EBasketball: require('../Assets/Icons/Sport/EBasketball.svg').default,
//   LeagueOfLegends: require('../Assets/Icons/Sport/LeagueOfLegends.svg').default,
//   Politics: require('../Assets/Icons/Sport/Politics.svg').default,
//   Biathlon: require('../Assets/Icons/Sport/Biathlon.svg').default,
//   SpecialBets: require('../Assets/Icons/Sport/SpecialBets.svg').default,
//   Oscar: require('../Assets/Icons/Sport/Oscar.svg').default,
//   Pelota: require('../Assets/Icons/Sport/Pelota.svg').default,
//   Olympics: require('../Assets/Icons/Sport/olympics.svg').default,
//   Swimming: require('../Assets/Icons/Sport/Swimming.svg').default,
//   TVShowsAndMovies: require('../Assets/Icons/Sport/TVShowsAndMovies.svg')
//       .default,
//   Sample: require('../Assets/Icons/BottomTabs/sample.svg').default,
// };
