export const MOBILE_FIXED_COMPETITIONS = {
  //Football
  Soccer: [
    {
      id: 1,
      regionId: 226,
      competitionId: 11193,
      title: 'NBC League',
      regionAlias: 'United Republic of Tanzania',
      logo: require('../Assets/Images/FixedCompetitions/Soccer/nbcpremier.png'),
    },
    {
      id: 2,
      regionId: 257,
      competitionId: 538,
      title: 'Premier League',
      regionAlias: 'England',
    },
    {
      id: 3,
      regionId: 215,
      competitionId: 545,
      title: 'La Liga',
      regionAlias: 'Spain',
    },
    {
      id: 4,
      regionId: 117,
      competitionId: 543,
      title: 'Serie A',
      regionAlias: 'Italy',
    },
    {
      id: 5,
      regionId: 90,
      competitionId: 541,
      title: 'Bundesliga',
      regionAlias: 'Germany',
    },
    {
      id: 6,
      regionId: 83,
      competitionId: 548,
      title: 'Ligue 1',
      regionAlias: 'France',
    },
    {
      id: 7,
      regionId: 164,
      competitionId: 1957,
      title: 'Eredivisie',
      regionAlias: 'Netherlands',
    },
    {
      id: 8,
      regionId: 2,
      competitionId: 566,
      title: 'UEFA Champions League',
      regionAlias: 'Europe',
    },
    {
      id: 9,
      regionId: 2,
      competitionId: 1861,
      title: 'UEFA Europa League',
      regionAlias: 'Europe',
    },
    {
      id: 10,
      regionId: 2,
      competitionId: 18278410,
      title: 'Europa Conference League',
      regionAlias: 'Europe',
      logo: require('../Assets/Images/FixedCompetitions/Soccer/EuropaConferenceLeague.png'),
    },
    {
      id: 11,
      regionId: 257,
      competitionId: 1840,
      title: 'FA Cup',
      regionAlias: 'England',
    },
  ],
  //Basketball
  Basketball: [
    {
      id: 1,
      regionId: 242,
      competitionId: 756,
      title: 'NBA',
      regionAlias: 'North America',
    },
    {
      id: 2,
      regionId: 2,
      competitionId: 686,
      title: 'Euro League',
      regionAlias: 'Europe',
    },
    {
      id: 3,
      regionId: 242,
      competitionId: 755,
      title: 'WNBA',
      regionAlias: 'North America',
    },
    {
      id: 4,
      regionId: 215,
      competitionId: 792,
      title: "Spain's Liga ACB",
      regionAlias: 'Spain',
    },
    {
      id: 5,
      regionId: 53,
      competitionId: 660,
      title: 'Chinese Basketball Association (CBA)',
      regionAlias: 'China',
    },
    {
      id: 6,
      regionId: 21,
      competitionId: 637,
      title: 'Australian NBL',
      regionAlias: 'Australia',
    },
  ],
  //Volleyball
  Volleyball: [
    {
      id: 1,
      regionId: 1,
      competitionId: 1630,
      title: 'World FIVB World Championship',
      regionAlias: 'World',
    },
    {
      id: 2,
      regionId: 1,
      competitionId: 1629,
      title: 'World FIVB World Championship - Women',
      regionAlias: 'World',
    },
    {
      id: 3,
      regionId: 1,
      competitionId: 1632,
      title: 'World FIVB World Cup',
      regionAlias: 'World',
    },
    {
      id: 4,
      regionId: 1,
      competitionId: 1631,
      title: 'World FIVB World Cup - Women',
      regionAlias: 'World',
    },
    {
      id: 5,
      regionId: 2,
      competitionId: 1460,
      title: 'CEV Champions League',
      regionAlias: 'Europe',
    },
    {
      id: 6,
      regionId: 2,
      competitionId: 1459,
      title: 'CEV Champions League - Women',
      regionAlias: 'Europe',
    },
    {
      id: 7,
      regionId: 2,
      competitionId: 1468,
      title: 'CEV European Championship',
      regionAlias: 'Europe',
    },
    {
      id: 8,
      regionId: 2,
      competitionId: 1467,
      title: 'CEV European Championship - Women',
      regionAlias: 'Europe',
    },
  ],
  //Tennis
  Tennis: [
    {
      id: 1,
      regionId: 19,
      competitionId: 1130,
      title: 'Australian Open - Men',
      regionAlias: 'Australia',
    },
    {
      id: 2,
      regionId: 19,
      competitionId: 1129,
      title: 'Australian Open - Women',
      regionAlias: 'Australia',
    },
    {
      id: 3,
      regionId: 257,
      competitionId: 4621,
      title: 'Wimbledon - Women',
      regionAlias: 'United Kingdom',
      logo: require('../Assets/Images/FixedCompetitions/Tennis/Wimbledon.png'),
    },
    {
      id: 4,
      regionId: 257,
      competitionId: 4871,
      title: 'Wimbledon - Men',
      regionAlias: 'United Kingdom',
      logo: require('../Assets/Images/FixedCompetitions/Tennis/Wimbledon.png'),
    },
    {
      id: 5,
      regionId: 83,
      competitionId: 4736,
      title: 'Roland Garros - Men',
      regionAlias: 'France',
      logo: require('../Assets/Images/FixedCompetitions/Tennis/FrenchOpen.png'),
    },
    {
      id: 6,
      regionId: 83,
      competitionId: 4737,
      title: 'Roland Garros - Women',
      regionAlias: 'France',
      logo: require('../Assets/Images/FixedCompetitions/Tennis/FrenchOpen.png'),
    },
    {
      id: 7,
      regionId: 242,
      competitionId: 4835,
      title: 'US Open - Men',
      regionAlias: 'USA',
      logo: require('../Assets/Images/FixedCompetitions/Tennis/USOpen.png'),
    },
    {
      id: 8,
      regionId: 242,
      competitionId: 4836,
      title: 'US Open - Women',
      regionAlias: 'USA',
      logo: require('../Assets/Images/FixedCompetitions/Tennis/USOpen.png'),
    },
  ],
  //Table Tennis
  TableTennis: [
    {
      id: 1,
      regionId: 1,
      competitionId: 18281742,
      title: 'TT Cup Men',
      regionAlias: 'World',
      logo: require('../Assets/Images/FixedCompetitions/TableTennis/TTCup.png'),
    },
    {
      id: 2,
      regionId: 67,
      competitionId: 18275895,
      title: 'Liga Pro',
      regionAlias: 'Russia',
      logo: require('../Assets/Images/FixedCompetitions/TableTennis/RussiaLigaPro.png'),
    },
    {
      id: 3,
      regionId: 239,
      competitionId: 18265301,
      title: 'Setka Cup',
      regionAlias: 'Ukraine',
      logo: require('../Assets/Images/FixedCompetitions/TableTennis/SetkaCup.png'),
    },
    {
      id: 4,
      regionId: 67,
      competitionId: 18273007,
      title: 'TT Star Series Men',
      regionAlias: 'Czech Republic',
      logo: require('../Assets/Images/FixedCompetitions/TableTennis/TTStarSeriesMen.png'),
    },
  ],
};
