export const POPULAR_LEAGUES_COMPETITIONS_DATA = {
  //Football
  Soccer: [
    {
      id: 1,
      regionId: 226,
      competitionId: 11193,
      title: 'NBC League',
      regionAlias: 'United Republic of Tanzania',
      logo: require('../Assets/Images/FixedCompetitions/Soccer/nbcpremier.png'),
    },
    {
      id: 2,
      regionId: 257,
      competitionId: 538,
      title: 'Premier League',
      regionAlias: 'England',
    },
    {
      id: 3,
      regionId: 215,
      competitionId: 545,
      title: 'La Liga',
      regionAlias: 'Spain',
    },
    {
      id: 4,
      regionId: 90,
      competitionId: 541,
      title: 'Bundesliga',
      regionAlias: 'Germany',
    },
    {
      id: 5,
      regionId: 164,
      competitionId: 1957,
      title: 'Eredivisie',
      regionAlias: 'Netherlands',
    },
    {
      id: 6,
      regionId: 2,
      competitionId: 566,
      title: 'UEFA Champions League',
      regionAlias: 'Europe',
    },
    {
      id: 7,
      regionId: 2,
      competitionId: 1861,
      title: 'UEFA Europa League',
      regionAlias: 'Europe',
    },
    {
      id: 8,
      regionId: 2,
      competitionId: 18278410,
      title: 'Europa Conference League',
      regionAlias: 'Europe',
      logo: require('../Assets/Images/FixedCompetitions/Soccer/EuropaConferenceLeague.png'),
    },
    {
      id: 9,
      regionId: 257,
      competitionId: 1840,
      title: 'FA Cup',
      regionAlias: 'England',
    },
  ],

  //Volleyball
  Volleyball: [
    {
      id: 1,
      regionId: 1,
      competitionId: 1630,
      title: 'World FIVB World Championship',
      regionAlias: 'World',
    },
    {
      id: 2,
      regionId: 1,
      competitionId: 1629,
      title: 'World FIVB World Championship - Women',
      regionAlias: 'World',
    },
    {
      id: 3,
      regionId: 1,
      competitionId: 1632,
      title: 'World FIVB World Cup',
      regionAlias: 'World',
    },
    {
      id: 4,
      regionId: 1,
      competitionId: 1631,
      title: 'World FIVB World Cup - Women',
      regionAlias: 'World',
    },
    {
      id: 5,
      regionId: 2,
      competitionId: 1460,
      title: 'CEV Champions League',
      regionAlias: 'Europe',
    },
    {
      id: 6,
      regionId: 2,
      competitionId: 1459,
      title: 'CEV Champions League - Women',
      regionAlias: 'Europe',
    },
    {
      id: 7,
      regionId: 2,
      competitionId: 1468,
      title: 'CEV European Championship',
      regionAlias: 'Europe',
    },
    {
      id: 8,
      regionId: 2,
      competitionId: 1467,
      title: 'CEV European Championship - Women',
      regionAlias: 'Europe',
    },
  ],

  // Table Tennis
  TableTennis: [
    {
      id: 1,
      regionId: 1,
      regionAlias: 'World',
      competitionId: 18281742,
      title: 'TT Cup Men',
      logo: require('../Assets/Images/FixedCompetitions/TableTennis/TTCup.png'),
    },
    {
      id: 2,
      regionId: 67,
      regionAlias: 'Czech Republic',
      competitionId: 18275895,
      title: 'Liga Pro',
      logo: require('../Assets/Images/FixedCompetitions/TableTennis/CzechLigaPro.png'),
    },
    {
      id: 3,
      regionId: 239,
      regionAlias: 'Ukraine',
      competitionId: 18265301,
      title: 'Setka Cup',
      logo: require('../Assets/Images/FixedCompetitions/TableTennis/SetkaCup.png'),
    },
    {
      id: 4,
      regionId: 67,
      regionAlias: 'Czech Republic',
      competitionId: 18273007,
      title: 'TT Star Series Men',
      logo: require('../Assets/Images/FixedCompetitions/TableTennis/TTStarSeriesMen.png'),
    },
    {
      id: 5,
      regionId: 1,
      regionAlias: 'World',
      competitionId: 1663,
      title: 'Challenger Series Men',
    },
    {
      id: 6,
      regionId: 239,
      regionAlias: 'Ukraine',
      competitionId: 18267215,
      title: 'Setka Cup Women',
      logo: require('../Assets/Images/FixedCompetitions/TableTennis/SetkaCup.png'),
    },
  ],

  // E-Football
  CyberFootball: [
    {
      id: 1,
      regionId: 2,
      regionAlias: 'Europe',
      competitionId: 18272812,
      title: 'Europa League',
      logo: require('../Assets/Images/FixedCompetitions/CyberFootball/EuropaLeague.png'),
    },
    {
      id: 2,
      regionId: 2,
      regionAlias: 'Europe',
      competitionId: 18273153,
      title: 'FIFA Champions League A',
      logo: require('../Assets/Images/FixedCompetitions/CyberFootball/FIFAChampionsLeagueA.png'),
    },
    {
      id: 3,
      regionId: 2,
      regionAlias: 'Europe',
      competitionId: 18273145,
      title: 'FIFA Champions League B',
      logo: require('../Assets/Images/FixedCompetitions/CyberFootball/FIFAChampionsLeagueB.png'),
    },
    {
      id: 4,
      regionId: 2,
      regionAlias: 'Europe',
      competitionId: 18273128,
      title: 'FIFA Champions League A',
      logo: require('../Assets/Images/FixedCompetitions/CyberFootball/FIFAChampionsLeagueA.png'),
    },
    {
      id: 5,
      regionId: 1,
      regionAlias: 'World',
      competitionId: 18274347,
      title: 'Esoccer GT Leagues',
      logo: require('../Assets/Images/FixedCompetitions/CyberFootball/EsoccerGTLeagues.png'),
    },
    {
      id: 6,
      regionId: 2,
      regionAlias: 'Europe',
      competitionId: 18272809,
      title: 'Champions League',
      logo: require('../Assets/Images/FixedCompetitions/CyberFootball/ChampionsLeague.png'),
    },
    {
      id: 7,
      regionId: 2,
      regionAlias: 'Europe',
      competitionId: 18289972,
      title: 'Euro 2024',
      logo: require('../Assets/Images/FixedCompetitions/CyberFootball/Euro2024.png'),
    },
  ],

  // Basketball
  Basketball: [
    {
      id: 1,
      regionId: 242,
      regionAlias: 'USA',
      competitionId: 756,
      title: 'Basketball USA - NBA',
    },
    {
      id: 2,
      regionId: 2,
      regionAlias: 'Europe',
      competitionId: 686,
      title: 'Basketball Europe - Euro League',
    },
    {
      id: 3,
      regionId: 242,
      regionAlias: 'USA',
      competitionId: 755,
      title: 'Basketball USA - WNBA',
    },
    {
      id: 4,
      regionId: 215,
      regionAlias: 'Spain',
      competitionId: 792,
      title: 'Basketball Spain - Liga ACB',
    },
    {
      id: 5,
      regionId: 53,
      regionAlias: 'China',
      competitionId: 660,
      title: 'Basketball China - CBA',
    },
    {
      id: 6,
      regionId: 21,
      regionAlias: 'Australia',
      competitionId: 637,
      title: 'Basketball Australia - NBL',
    },
  ],

  //Cricket
  Cricket: [
    {
      id: 1,
      regionId: 110,
      competitionId: 10851,
      regionAlias: 'India',
      title: 'Indian Premier League',
      logo: require('../Assets/Images/FixedCompetitions/Cricket/IPL.png'),
    },
    {
      id: 2,
      regionId: 19,
      competitionId: 18280824,
      regionAlias: 'Australia',
      title: 'Big Bash League',
      logo: require('../Assets/Images/FixedCompetitions/Cricket/BigBashLeague.png'),
    },
    {
      id: 3,
      regionId: 19,
      competitionId: 17316,
      regionAlias: 'West Indies',
      title: 'Caribbean Premier League',
      logo: require('../Assets/Images/FixedCompetitions/Cricket/CaribbeanPremierLeague.png'),
    },
    {
      id: 4,
      regionId: 110,
      competitionId: 14937,
      regionAlias: 'Pakistan',
      title: 'Pakistan Super League',
      logo: require('../Assets/Images/FixedCompetitions/Cricket/PakistanSuperLeague.png'),
    },
    {
      id: 5,
      regionId: 257,
      competitionId: 18269450,
      regionAlias: 'England',
      title: 'The Hundred',
      logo: require('../Assets/Images/FixedCompetitions/Cricket/TheHundred.png'),
    },
    {
      id: 6,
      regionId: 3,
      competitionId: 15547,
      regionAlias: 'Asia',
      title: 'Asia Cup',
      logo: require('../Assets/Images/FixedCompetitions/Cricket/AsiaCup.png'),
    },
    {
      id: 7,
      regionId: 1,
      competitionId: 10389,
      regionAlias: 'World',
      title: 'ICC Cricket World Cup',
      logo: require('../Assets/Images/FixedCompetitions/Cricket/ICCWorldCup.png'),
    },
    {
      id: 8,
      regionId: 1,
      competitionId: 18281640,
      regionAlias: 'World',
      title: 'T20 World Cup',
      logo: require('../Assets/Images/FixedCompetitions/Cricket/T20WorldCup.png'),
    },
    {
      id: 9,
      regionId: 1,
      competitionId: 15104,
      regionAlias: 'World',
      title: 'T20 World Cup',
      logo: require('../Assets/Images/FixedCompetitions/Cricket/T20WorldCup.png'),
    },
    {
      id: 10,
      regionId: 1,
      competitionId: null,
      regionAlias: 'World',
      title: 'Championship Trophy',
      logo: require('../Assets/Images/FixedCompetitions/Cricket/ChampionshipTrophy.png'),
    },
    {
      id: 11,
      regionId: 1,
      competitionId: 21300,
      regionAlias: 'World',
      title: 'World Cup',
      logo: require('../Assets/Images/FixedCompetitions/Cricket/WorldCup.png'),
    },
    {
      id: 12,
      regionId: 1,
      competitionId: 14505,
      regionAlias: 'England/Australia',
      title: 'Ashes Test Series',
      logo: require('../Assets/Images/FixedCompetitions/Cricket/AshesTestSeries.png'),
    },
  ],

  //Tennis
  Tennis: [
    {
      id: 1,
      regionId: 210004,
      competitionId: 1130,
      regionAlias: 'Australia',
      title: 'Australian Open-Men',
      logo: require('../Assets/Images/FixedCompetitions/Tennis/AustralianOpen.png'),
    },
    {
      id: 2,
      regionId: 210004,
      competitionId: 1129,
      regionAlias: 'Australia',
      title: 'Australian Open-Women',
      logo: require('../Assets/Images/FixedCompetitions/Tennis/AustralianOpen.png'),
    },
    {
      id: 3,
      title: 'Wimbledon-Women',
      regionId: 2410004,
      competitionId: 4621,
      regionAlias: 'United Kingdom',
      logo: require('../Assets/Images/FixedCompetitions/Tennis/Wimbledon.png'),
    },
    {
      id: 4,
      regionId: 2410004,
      competitionId: 4871,
      title: 'Wimbledon-Men',
      regionAlias: 'United Kingdom',
      logo: require('../Assets/Images/FixedCompetitions/Tennis/Wimbledon.png'),
    },
    {
      id: 5,
      regionId: 830004,
      competitionId: 4736,
      regionAlias: 'France',
      title: 'French Open-Men',
      logo: require('../Assets/Images/FixedCompetitions/Tennis/FrenchOpen.png'),
    },
    {
      id: 6,
      regionId: 830004,
      competitionId: 4737,
      regionAlias: 'France',
      title: 'French Open-Women',
      logo: require('../Assets/Images/FixedCompetitions/Tennis/FrenchOpen.png'),
    },
    {
      id: 7,
      regionId: 2420004,
      regionAlias: 'USA',
      competitionId: 4835,
      title: 'US Open-Men',
      logo: require('../Assets/Images/FixedCompetitions/Tennis/USOpen.png'),
    },
    {
      id: 8,
      regionId: 2420004,
      regionAlias: 'USA',
      competitionId: 4836,
      title: 'US Open-Women',
      logo: require('../Assets/Images/FixedCompetitions/Tennis/USOpen.png'),
    },
  ],

  //RugbyUnion
  RugbyUnion: [
    {
      id: 1,
      regionId: 10037,
      title: 'World Cup',
      competitionId: 12035,
      regionAlias: 'World',
      logo: require('../Assets/Images/FixedCompetitions/RugbyUnion/RugbyWorldCup.png'),
    },
    {
      id: 2,
      regionId: 20037,
      competitionId: 9385,
      title: 'Six Nations',
      regionAlias: 'Europe',
      logo: require('../Assets/Images/FixedCompetitions/RugbyUnion/SixNations.png'),
    },
    {
      id: 3,
      regionId: 10037,
      competitionId: 10439,
      regionAlias: 'World',
      title: 'Super Rugby',
      logo: require('../Assets/Images/FixedCompetitions/RugbyUnion/SuperRugby.png'),
    },
    {
      id: 4,
      regionId: 257,
      competitionId: 10451,
      regionAlias: 'England',
      title: 'Premiership Rugby',
      logo: require('../Assets/Images/FixedCompetitions/RugbyUnion/PremiershipRugby.png'),
    },
  ],

  //Golf
  Golf: [
    {
      id: 1,
      regionId: 5,
      competitionId: 14578,
      regionAlias: 'USA',
      title: 'The Masters',
      logo: require('../Assets/Images/FixedCompetitions/Golf/MastersTournament.png'),
    },
    {
      id: 2,
      regionId: 2,
      competitionId: 14580,
      regionAlias: 'UK',
      title: 'The Open Championship',
      logo: require('../Assets/Images/FixedCompetitions/Golf/TheOpenChampionship.png'),
    },
    {
      id: 3,
      regionId: 5,
      competitionId: 14621,
      regionAlias: 'USA',
      title: 'USPGA Championship',
      logo: require('../Assets/Images/FixedCompetitions/Golf/USPGAChampionship.png'),
    },
    {
      id: 4,
      regionId: 1,
      competitionId: 11617,
      regionAlias: 'World',
      title: 'Ryder Cup',
      logo: require('../Assets/Images/FixedCompetitions/Golf/RyderCup.png'),
    },
  ],

  //Ice Hockey
  IceHockey: [
    {
      id: 1,
      regionId: 5,
      competitionId: 1732,
      regionAlias: 'USA/Canada',
      title: 'NHL',
    },
  ],

  //Field Hockey
  Hockey: [
    {
      id: 1,
      regionId: 1,
      competitionId: 18288610,
      regionAlias: 'World',
      title: 'FIH Hockey5s World Cup',
      logo: require('../Assets/Images/FixedCompetitions/Hockey/FIHHockey5sWorldCup.png'),
    },
    {
      id: 2,
      regionId: 1,
      competitionId: 18288577,
      regionAlias: 'World',
      title: 'FIH Hockey5s World Cup - Women',
      logo: require('../Assets/Images/FixedCompetitions/Hockey/FIHHockey5sWorldCup.png'),
    },
  ],
};
