import React, {memo, useCallback, useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {useSearchParams} from 'react-router-dom';

import {ReactComponent as CloseIcon} from '../../../../Assets/Icons/Globals/coloredClose.svg';

const SelectedCompetitionItem = ({item}) => {
  const {t} = useTranslation();
  const [getParam, setGetParam] = useSearchParams();

  const regionParam = useMemo(() => getParam.get('region'), [getParam]);

  const removeClickHandler = useCallback(() => {
    if (!!regionParam) {
      const splitArray = regionParam.split('-');

      const updatedArray = splitArray
        .map(part => {
          const [label, ...ids] = part.split('_');
          const filteredIds = ids.filter(id => id !== String(item?.id));

          return filteredIds.length > 0
            ? `${label}_${filteredIds.join('_')}`
            : null;
        })
        .filter(Boolean);

      if (!!updatedArray.length) {
        getParam.set('region', updatedArray.join('-'));
      } else {
        getParam.delete('region');
      }

      setGetParam(getParam);
    }
  }, [getParam, item?.id, regionParam, setGetParam]);

  return (
    <div
      style={{height: 45}}
      className="row items-center yellowBackground rounded-borders gap-10 pl-lg cursor-pointer">
      <img
        alt={item?.name}
        style={{height: 25, objectFit: 'contain'}}
        src={
          item?.logo ||
          `https://statistics.bcapps.site/images/c/m/0/${item?.id}.png`
        }
      />
      <span className="bold-700 font-12 blueText">{t(item?.name)}</span>
      <div
        onClick={removeClickHandler}
        className="blueBackground full-height row items-center justify-center"
        style={{
          width: 43,
          borderTopRightRadius: 5,
          borderBottomRightRadius: 5,
          borderTop: '1px solid var(--appYellow)',
          borderRight: '1px solid var(--appYellow)',
          borderBottom: '1px solid var(--appYellow)',
        }}>
        <CloseIcon width={18} height={18} />
      </div>
    </div>
  );
};

export default memo(SelectedCompetitionItem);
