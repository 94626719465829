import React, {memo, useCallback, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';

import {setShowMobileBetSlip} from '../../../../Redux/AppSlice';
import {getWssSocket} from '../../../../Redux/AppSlice/AppSelectors';
import {getCurrency} from '../../../../Redux/PartnerSlice/PartnerSelectors';

import {useScreenSize, useSocket} from '../../../../Hooks';

import {CASH_OUT} from '../../../../Constants/Socket';
import {BREAKPOINT_XS} from '../../../../Constants/Globals';

import {bigNumbersPrettier} from '../../../../Utils/BigNumbersPrettier';

import blocked from '../../../../Assets/Icons/Globals/block.svg';
import {ReactComponent as CurrencyIcon} from '../../../../Assets/Icons/Globals/TZS.svg';

import {AppButton} from '../../../UI';

const CashOutActions = ({
  betId,
  cashOutAmount,
  cashOutedAmountRef,
  setIsSuccessModalOpen,
}) => {
  const {t} = useTranslation();
  const {cashOut} = useSocket();
  const dispatch = useDispatch();
  const {width} = useScreenSize();

  const currency = useSelector(getCurrency);
  const wssSocket = useSelector(getWssSocket);

  const [showConfirmation, setShowConfirmation] = useState(false);
  const [isCashOutInProgress, setIsCashOutInProgress] = useState(false);

  const cashOutPressHandler = useCallback(
    e => {
      e.stopPropagation();
      if (showConfirmation) {
        setIsCashOutInProgress(true);
        cashOut({price: cashOutAmount, bet_id: betId});
      } else {
        setShowConfirmation(true);
      }
    },
    [betId, cashOut, cashOutAmount, showConfirmation],
  );

  const onMessageCb = useCallback(
    event => {
      const data = JSON.parse(event.data);
      switch (data?.rid) {
        case CASH_OUT:
          if (data?.data?.result === 'Ok') {
            cashOutedAmountRef.current = data?.data?.details?.price;
            if (width < BREAKPOINT_XS) {
              dispatch(setShowMobileBetSlip(false));
            }
            setIsSuccessModalOpen(true);
          }
          setIsCashOutInProgress(false);
          break;
        default:
          break;
      }
    },
    [cashOutedAmountRef, dispatch, setIsSuccessModalOpen, width],
  );

  useEffect(() => {
    wssSocket?.addEventListener('message', onMessageCb);

    return () => {
      wssSocket?.removeEventListener('message', onMessageCb);
    };
  }, [onMessageCb, wssSocket]);

  return (
    <div className="column">
      <AppButton
        flat
        type={3}
        height={40}
        disabled={!cashOutAmount}
        loading={isCashOutInProgress}
        onClick={cashOutPressHandler}
        className="items-center justify-center"
        title={
          cashOutAmount ? (
            <div className="row items-center justify-center gap-20">
              <span className="whiteText bold-700 font-15">
                {t(showConfirmation ? 'confirmCashOut' : 'cashout')}:
              </span>
              <div className="row items-center gap-10 overflow-hidden">
                <CurrencyIcon
                  width={18}
                  height={10}
                  className="flex-shrink"
                  style={{boxShadow: '0px 4px 4px 0px #00000040'}}
                />
                <span
                  style={{
                    textShadow: '0px 4px 4px #000000D9',
                  }}
                  className={`yellowText font-18 bold-700 ellipsis`}>
                  {bigNumbersPrettier(cashOutAmount)}
                </span>
              </div>
            </div>
          ) : (
            <div className="row items-center justify-center gap-5">
              <span className="whiteText bold-700 font-15">{t('cashout')}</span>
              <img src={blocked} alt="blocked" height={15} width={15} />
            </div>
          )
        }
      />
      {showConfirmation && (
        <div
          className=" full-width"
          style={{height: 1, background: '#B5B5B5'}}></div>
      )}
      {showConfirmation && (
        <AppButton
          flatTop
          type={5}
          height={40}
          className="items-center justify-center"
          onClick={e => {
            e.stopPropagation();
            setShowConfirmation(false);
          }}
          title={
            <span className="whiteText font-15 bold-600">{t('cancel')}</span>
          }
        />
      )}
    </div>
  );
};

export default memo(CashOutActions);
