import React, {memo, useCallback, useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {useSearchParams} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';

import {getIsLoggedIn} from '../../../../Redux/AppSlice/AppSelectors';
import {setFavouriteCompetitionsObject} from '../../../../Redux/SportSlice';
import {getFavouriteCompetitionsObject} from '../../../../Redux/SportSlice/SportSelectors';

import {useSocket} from '../../../../Hooks';

import {POPULAR_LEAGUES_COMPETITIONS_DATA} from '../../../../Constants/PopularLeaguesCompetitionsData';

import {ReactComponent as PinIcon} from '../../../../Assets/Icons/Globals/pin.svg';
import {ReactComponent as StarIcon} from '../../../../Assets/Icons/Globals/whiteStar.svg';
import {ReactComponent as PopularLeaguesIcon} from '../../../../Assets/Icons/SportFilters/popularMatches.svg';

const SideBarPopularLeagues = () => {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const {setFavouriteCompetition} = useSocket();
  const [getParam, setGetParam] = useSearchParams();

  const isLoggedIn = useSelector(getIsLoggedIn);
  const favouriteCompetitionsObject = useSelector(
    getFavouriteCompetitionsObject,
  );

  const sportParam = useMemo(() => getParam.get('sport'), [getParam]);
  const regionParam = useMemo(() => getParam.get('region'), [getParam]);

  const selectedCompetitionsIds = useMemo(() => {
    const splitArray = regionParam?.split?.('-');
    const competitions =
      splitArray?.map(item => item?.split?.('_')?.slice?.(1)) || [];
    return (competitions || [])?.flat?.(Infinity)?.map?.(item => +item) || [];
  }, [regionParam]);

  const onFavouriteSelect = useCallback(
    competitionId => {
      const newFavouriteCompetitionsObject = {...favouriteCompetitionsObject};
      if (
        Object.keys(newFavouriteCompetitionsObject || {})?.includes(
          `${competitionId}`,
        )
      ) {
        delete newFavouriteCompetitionsObject[`${competitionId}`];
      } else {
        newFavouriteCompetitionsObject[`${competitionId}`] = {};
      }
      dispatch(setFavouriteCompetitionsObject(newFavouriteCompetitionsObject));
      setFavouriteCompetition({idsObject: newFavouriteCompetitionsObject});
    },
    [dispatch, setFavouriteCompetition, favouriteCompetitionsObject],
  );

  const onCompetitionClick = useCallback(
    (regionAlias, competitionId) => {
      const splitArray = !!regionParam ? (regionParam || '')?.split?.('-') : [];

      const findIndex = splitArray.findIndex(item =>
        item?.includes(`${regionAlias}`),
      );

      if (!splitArray?.length) {
        splitArray.push(`${regionAlias}_${competitionId}`);
      } else if (findIndex > -1) {
        const competitions = splitArray?.[findIndex]?.split?.('_');
        const foundCompetitionIndex = competitions.indexOf(`${competitionId}`);
        if (foundCompetitionIndex > -1) {
          competitions.splice(foundCompetitionIndex, 1);
        } else {
          competitions.push(`${competitionId}`);
        }
        if (competitions?.length === 1) {
          splitArray?.splice(findIndex, 1);
        } else {
          splitArray[findIndex] = competitions.join('_');
        }
      } else {
        splitArray.push(`${regionAlias}_${competitionId}`);
      }

      if (!!splitArray?.length) {
        getParam.set('region', splitArray.join('-'));
      } else {
        getParam.delete('region');
      }
      setGetParam(getParam);
    },
    [getParam, regionParam, setGetParam],
  );

  return (
    <div className="column">
      <div className="row items-center gap-7 py-md">
        <PopularLeaguesIcon width={11} height={14} fill="white" />
        <div className="row items-center gap-9">
          <span className="bold-600 font-15 text-uppercase whiteText">
            {t('popularLeagues')}
          </span>
          <PinIcon />
        </div>
      </div>
      <div className="column gap-5">
        {POPULAR_LEAGUES_COMPETITIONS_DATA?.[sportParam]?.map(
          fixedCompetitionItem => (
            <div
              key={fixedCompetitionItem?.competitionId}
              onClick={e => {
                e?.preventDefault?.();
                e?.stopPropagation?.();
                onCompetitionClick(
                  fixedCompetitionItem?.regionAlias,
                  fixedCompetitionItem?.competitionId,
                );
              }}
              className="flex row items-center gap-10 px-md py-lg rounded-borders overflow-hidden cursor-pointer"
              style={{
                height: 45,
                border: selectedCompetitionsIds?.includes(
                  fixedCompetitionItem?.competitionId,
                )
                  ? '1px solid transparent'
                  : '1px solid white',
                backgroundColor: selectedCompetitionsIds?.includes(
                  fixedCompetitionItem?.competitionId,
                )
                  ? 'var(--appYellow)'
                  : '#003069',
              }}>
              {!!isLoggedIn && (
                <div
                  className="flex"
                  onClick={e => {
                    e.preventDefault();
                    e.stopPropagation();
                    onFavouriteSelect(fixedCompetitionItem?.competitionId);
                  }}>
                  <StarIcon
                    fill={
                      Object.keys(favouriteCompetitionsObject || {})?.includes(
                        `${fixedCompetitionItem?.competitionId}`,
                      )
                        ? 'var(--appYellow)'
                        : 'transparent'
                    }
                  />
                </div>
              )}
              <img
                height={20}
                className="competitionIcon"
                alt={fixedCompetitionItem?.title}
                style={{maxWidth: 30, objectFit: 'contain'}}
                src={
                  fixedCompetitionItem?.logo ||
                  `https://statistics.bcapps.site/images/c/m/0/${fixedCompetitionItem?.competitionId}.png`
                }
              />
              <span
                className={`font-12 bold-700 ellipsis ${
                  selectedCompetitionsIds?.includes(
                    fixedCompetitionItem?.competitionId,
                  )
                    ? 'blueText'
                    : 'whiteText'
                }`}>
                {t(fixedCompetitionItem?.title)}
              </span>
            </div>
          ),
        )}
      </div>
    </div>
  );
};

export default memo(SideBarPopularLeagues);
