import {
  SET,
  HALF,
  MATCH,
  PERIOD,
  P1_X_P2,
  OVER_UNDER,
  MARKETS_TOTALS,
  MATCH_WINNER_2,
  MATCH_WINNER_3,
  MARKETS_WINNER,
  MARKETS_ODD_EVEN,
  MARKETS_HANDICAP,
  ASIAN_HANDICAP_ASIAN,
  MARKETS_DOUBLE_CHANCE,
  MARKETS_BOTH_TEAM_TO_SCORE,
} from './MatchFilters';

export const DROPDOWN_FOOTBALL_MARKETS_FILTERS = [
  {
    subTitle: MATCH,
    title: 'matchResult',
    name: 'matchResult',
    value: MARKETS_WINNER,
    display_sub_key: MATCH,
    displayKey: MARKETS_WINNER,
  },
  {
    subTitle: MATCH,
    title: 'doubleChanceFilter',
    name: 'doubleChanceFilter',
    display_sub_key: MATCH,
    value: MARKETS_DOUBLE_CHANCE,
    displayKey: MARKETS_DOUBLE_CHANCE,
  },
  {
    subTitle: MATCH,
    title: 'asianHandicap',
    name: 'asianHandicap',
    display_sub_key: MATCH,
    value: MARKETS_HANDICAP,
    displayKey: MARKETS_HANDICAP,
    market_type: ASIAN_HANDICAP_ASIAN,
  },
  {
    subTitle: MATCH,
    title: 'total',
    name: 'total',
    value: MARKETS_TOTALS,
    display_sub_key: MATCH,
    market_type: OVER_UNDER,
    displayKey: MARKETS_TOTALS,
  },
  {
    subTitle: MATCH,
    title: 'bothTeamToScoreTitle',
    name: 'bothTeamToScoreTitle',
    display_sub_key: MATCH,
    value: MARKETS_BOTH_TEAM_TO_SCORE,
    displayKey: MARKETS_BOTH_TEAM_TO_SCORE,
  },
  // {
  //   subTitle: MATCH,
  //   title: 'oddEven',
  //   name: 'oddEven',
  //   display_sub_key: MATCH,
  //   value: MARKETS_ODD_EVEN,
  //   displayKey: MARKETS_ODD_EVEN,
  // },
];
export const DROPDOWN_BASKETBALL_MARKETS_FILTERS = [
  {
    subTitle: MATCH,
    title: 'matchResult',
    name: 'matchResult',
    value: MARKETS_WINNER,
    display_sub_key: MATCH,
    displayKey: MARKETS_WINNER,
    market_type: MATCH_WINNER_2,
  },
  {
    subTitle: P1_X_P2,
    title: 'winner3',
    name: 'winner3',
    type: P1_X_P2,
    value: MATCH_WINNER_3,
    displayKey: MARKETS_WINNER,
    market_type: MATCH_WINNER_3,
  },
  {
    subTitle: MATCH,
    title: 'total',
    name: 'total',
    value: MARKETS_TOTALS,
    display_sub_key: MATCH,
    displayKey: MARKETS_TOTALS,
  },
  {
    subTitle: PERIOD,
    title: 'totalPeriod',
    name: 'totalPeriod',
    value: MARKETS_TOTALS,
    display_sub_key: PERIOD,
    displayKey: MARKETS_TOTALS,
  },
  {
    subTitle: HALF,
    title: 'totalHalf',
    name: 'totalHalf',
    value: MARKETS_TOTALS,
    display_sub_key: HALF,
    displayKey: MARKETS_TOTALS,
  },
];
export const DROPDOWN_TENNIS_MARKETS = [
  {
    subTitle: MATCH,
    title: 'matchResult',
    name: 'matchResult',
    value: MARKETS_WINNER,
    display_sub_key: MATCH,
    displayKey: MARKETS_WINNER,
  },
  {
    subTitle: SET,
    title: 'totalSet',
    name: 'totalSet',
    display_sub_key: SET,
    value: MARKETS_TOTALS,
    displayKey: MARKETS_TOTALS,
  },
  {
    subTitle: MATCH,
    title: 'total',
    name: 'total',
    value: MARKETS_TOTALS,
    display_sub_key: MATCH,
    displayKey: MARKETS_TOTALS,
  },
];

export const DROPDOWN_VOLLEYBALL_MARKETS = [
  {
    subTitle: MATCH,
    title: 'matchResult',
    name: 'matchResult',
    value: MARKETS_WINNER,
    display_sub_key: MATCH,
    displayKey: MARKETS_WINNER,
  },
  {
    subTitle: SET,
    title: 'totalSet',
    name: 'totalSet',
    display_sub_key: SET,
    value: MARKETS_TOTALS,
    displayKey: MARKETS_TOTALS,
  },
  {
    subTitle: MATCH,
    title: 'total',
    name: 'total',
    value: MARKETS_TOTALS,
    display_sub_key: MATCH,
    displayKey: MARKETS_TOTALS,
  },
  {
    subTitle: MATCH,
    title: 'odd/even',
    name: 'odd/even',
    display_sub_key: MATCH,
    value: MARKETS_ODD_EVEN,
    displayKey: MARKETS_ODD_EVEN,
  },
];

export const DROPDOWN_TABLE_TENNIS_MARKETS = [
  {
    subTitle: MATCH,
    title: 'matchResult',
    name: 'matchResult',
    value: MARKETS_WINNER,
    display_sub_key: MATCH,
    displayKey: MARKETS_WINNER,
  },
  {
    subTitle: SET,
    title: 'totalSet',
    name: 'totalSet',
    value: MARKETS_TOTALS,
    display_sub_key: SET,
    displayKey: MARKETS_TOTALS,
  },
  {
    subTitle: MATCH,
    title: 'total',
    name: 'total',
    value: MARKETS_TOTALS,
    display_sub_key: MATCH,
    displayKey: MARKETS_TOTALS,
  },
  {
    subTitle: MATCH,
    title: 'odd/event',
    name: 'odd/event',
    display_sub_key: MATCH,
    value: MARKETS_ODD_EVEN,
    displayKey: MARKETS_ODD_EVEN,
  },
];

export const OTHER_SPORTS_MARKETS_FILTERS = [
  {
    subTitle: MATCH,
    title: 'matchResult',
    name: 'matchResult',
    value: MARKETS_WINNER,
    displayKey: MARKETS_WINNER,
  },
  {
    subTitle: MATCH,
    title: 'total',
    name: 'total',
    value: MARKETS_TOTALS,
    displayKey: MARKETS_TOTALS,
  },
  {
    subTitle: MATCH,
    title: 'handicap',
    name: 'handicap',
    value: MARKETS_HANDICAP,
    displayKey: MARKETS_HANDICAP,
  },
];

export const DROPDOWN_MARKETS_NAMES = {
  Tennis: DROPDOWN_TENNIS_MARKETS,
  // Cricket: DROPDOWN_CRICKET_MARKETS,
  Soccer: DROPDOWN_FOOTBALL_MARKETS_FILTERS,
  Basketball: DROPDOWN_BASKETBALL_MARKETS_FILTERS,
  Volleyball: DROPDOWN_VOLLEYBALL_MARKETS,
  TableTennis: DROPDOWN_TABLE_TENNIS_MARKETS,
  CyberFootball: DROPDOWN_FOOTBALL_MARKETS_FILTERS,
  OTHER_SPORTS_MARKETS: OTHER_SPORTS_MARKETS_FILTERS,
};
