import React, {memo, useEffect, useMemo, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';

import './index.scss';

import {
  setAuthModal,
  setShowBetSlip,
  setShowMobileBetSlip,
} from '../../../Redux/AppSlice';
import {getUser} from '../../../Redux/UserSlice/UserSelectors';
import {
  getIsLoggedIn,
  getShowBetSlip,
  getShowMobileBetSlip,
} from '../../../Redux/AppSlice/AppSelectors';

import {useScreenSize, useStorage} from '../../../Hooks';

import {FUN, REAL, SITE_ID} from '../../../Constants/Socket';
import {BREAKPOINT_XS} from '../../../Constants/Globals';
import {CATEGORY_POKER_ID} from '../../../Constants/CasinoMenuFilter';

import {AppBetSlip, AppButton} from '../../../Components/UI';
import GameActionBarHeader from '../../../Components/Casino/GameActionBar/GameActionBarHeader';
import GameActionBarFooter from '../../../Components/Casino/GameActionBar/GameActionBarFooter';
import MobileHeader from '../../../Components/Header/MobileHeader';
import {useNavigate, useSearchParams} from 'react-router-dom';

import {ReactComponent as CasinoGameBack} from '../../../Assets/Icons/Casino/CasinoGameBack.svg';
import {ReactComponent as FullScreenArrowDown} from '../../../Assets/Icons/Casino/FullScreenArrowDown.svg';
import {ReactComponent as FullScreenArrowTop} from '../../../Assets/Icons/Casino/FullScreenArrowTop.svg';
import {ReactComponent as ShowBetslipArrow} from '../../../Assets/Icons/Casino/showBetslipArrow.svg';
import coloredClose from '../../../Assets/Icons/Globals/coloredClose.svg';

const Poker = () => {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {width} = useScreenSize();

  const [isMenuOpen, setIsMenuOpen] = useState(true);

  const [isFullScreen, setIsFullScreen] = useState(width < BREAKPOINT_XS);

  const user = useSelector(getUser);
  const isLoggedIn = useSelector(getIsLoggedIn);
  const showBetSlip = useSelector(getShowBetSlip);
  const showMobileBetSlip = useSelector(getShowMobileBetSlip);

  useEffect(() => {
    if (isLoggedIn === false) {
      dispatch(setAuthModal({isVisible: true}));
    }
  }, [isLoggedIn]);

  const src = useMemo(
    () =>
      (
        'https://games.pigabet.co.tz/LaunchGame' +
        `?partnerId=${SITE_ID}` +
        '&language=en' +
        '&devicetypeid=1' +
        `&gameId=${CATEGORY_POKER_ID}` +
        '&openType=real' +
        `&token=${user?.auth_token}`
      )?.trim(),
    [user?.auth_token],
  );

  useEffect(() => {
    document.querySelector('body').classList.add('overflow-hidden');
    return () =>
      document.querySelector('body').classList.remove('overflow-hidden');
  }, []);

  useEffect(() => {
    if (
      width < BREAKPOINT_XS &&
      !!document.querySelector('.appBetSlipWrapper_mobile')
    ) {
      document.querySelector('.appBetSlipWrapper_mobile').style.transition =
        'none';
    }
  }, [isLoggedIn, width]);

  useEffect(
    () => () => {
      if (
        document.querySelector('.appBetSlipWrapper_mobile') &&
        width < BREAKPOINT_XS
      ) {
        document.querySelector('.appBetSlipWrapper_mobile').style.transition =
          '';
      }
    },
    [width],
  );

  return isLoggedIn ? (
    <div
      className="pokerGameContainer blueBackground column"
      style={{
        width,
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        position: 'fixed',
        zIndex: width > BREAKPOINT_XS ? 100 : 33,
        height:
          width < BREAKPOINT_XS && isMenuOpen ? 'calc(100% - 46px)' : '100%',
      }}>
      <div className="full-width rowCenter">
        {!isMenuOpen && (
          <div
            className="cursor-pointer headerToggleButton_open"
            onClick={e => {
              e.preventDefault();
              e.stopPropagation();
              setIsMenuOpen(true);
            }}>
            <FullScreenArrowDown width={35} />
          </div>
        )}
        {isMenuOpen && (
          <div className="full-width flex column items-center">
            <MobileHeader
              isCasinoGame
              showMenu={false}
              showFilters={false}
              showBetSlipToggleButton
              showLanguageChooser={false}
              leftIcon={
                <div
                  className="ml-md cursor-pointer rowCenter full-height"
                  onClick={() => {
                    navigate(-2);
                  }}>
                  <CasinoGameBack />
                </div>
              }
            />
            <div
              className="cursor-pointer headerToggleButton"
              onClick={() => setIsMenuOpen(false)}>
              <FullScreenArrowTop width={35} />
            </div>
          </div>
        )}
      </div>

      {!showMobileBetSlip && width < BREAKPOINT_XS && (
        <div
          className="cursor-pointer betslipToggleButton"
          onClick={e => {
            e.preventDefault();
            e.stopPropagation();
            dispatch(setShowMobileBetSlip(true));
          }}>
          <ShowBetslipArrow style={{transform: 'scale(1.5)'}} />
        </div>
      )}
      <div className="row fit">
        <iframe
          src={src}
          width="100%"
          onLoad={() => {
            if (
              width < BREAKPOINT_XS &&
              !!document.querySelector('.appBetSlipWrapper_mobile')
            ) {
              document.querySelector(
                '.appBetSlipWrapper_mobile',
              ).style.transition = '';
            }
          }}
          height="100%"
          scrolling="no"
          title="casino_game"
        />
        {width > BREAKPOINT_XS && (
          <div
            // style={{marginTop: isMenuOpen ? 40 : 0}}
            className={`appBetSlipWrapper_desktop flex-shrink blueBackground rounded-top--borders appBetSlipWrapper ${
              width >= BREAKPOINT_XS ? (!!showBetSlip ? 'column' : 'hide') : ''
            }`}>
            <div
              className="row items-center  justify-between yellowBackground pa-sm"
              style={{
                borderTopLeftRadius: 10,
                borderTopRightRadius: 10,
              }}>
              <div
                style={{
                  width: 26,
                  height: 26,
                }}
              />
              <span className="font-15 blueText bold-600">{t('myBets')}</span>
              <div
                style={{
                  width: 26,
                  height: 26,
                }}
                onClick={() => dispatch(setShowBetSlip(false))}
                className="whiteBackground items-center justify-center flex rounded-borders cursor-pointer z-index-1">
                <img alt="close" width={10} height={10} src={coloredClose} />
              </div>
            </div>
            <div
              className={`full-height betSlipDesktopWrapper rounded-borders`}>
              <AppBetSlip />
            </div>
          </div>
        )}
      </div>
    </div>
  ) : (
    <div className="my-xxl">
      <AppButton
        type={1}
        title={t('signInForPlay')}
        onClick={() => dispatch(setAuthModal({isVisible: true}))}
      />
    </div>
  );
};

export default Poker;
