import React, {
  memo,
  useRef,
  useMemo,
  useState,
  useEffect,
  useCallback,
} from 'react';
import {useTranslation} from 'react-i18next';
import {useLocation, useSearchParams} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';

import './index.scss';

import {
  setBonusInfoData,
  setIsBetSlipDataLoading,
} from '../../../../Redux/BettingSlice';
import {
  getWssSocket,
  getIsLoggedIn,
} from '../../../../Redux/AppSlice/AppSelectors';
import {
  getHeaderTabIndex,
  getIsBetSlipDataLoading,
} from '../../../../Redux/BettingSlice/BettingSelectors';
import {setBetSlipCount, setShowBetSlip} from '../../../../Redux/AppSlice';
import {getMaxOddForMultipleBet} from '../../../../Redux/PartnerSlice/PartnerSelectors';

import {useScreenSize, useSocket, useStorage} from '../../../../Hooks';

import {fixedNumberWithoutRound} from '../../../../Utils/GetOddValue';

import {
  GET_BOOKED_BETS,
  UNSUBSCRIBE_BULK,
  SUBSCRIBE_TO_EVENTS,
  GET_SPORT_BONUS_RULES,
  UNSUBSCRIBE_LIVE_EVENT,
} from '../../../../Constants/Socket';
import {BREAKPOINT_XS} from '../../../../Constants/Globals';
import {SINGLE_BET, SYSTEM_BET} from '../../../../Constants/Betting';

import Zigzag from '../../Components/Zigzag';
import GameRow from '../../Components/GameRow';
import OddsContainer from '../../Components/OddsContainer';
import SystemDropdown from '../../Components/SystemDropdown';
import BetTypeDropdown from '../../Components/BetTypeDropdown';
import BetSlipSuccessModal from '../../Components/SuccessModal';

import BetSlipGameRowSkeleton from '../../../UI/Skeletons/BetSlipGameRowSkeleton';

import Logo from '../../../../Assets/Layout/HeaderLogo.png';
import moment from 'moment';
import trashIcon from '../../../../Assets/Icons/Globals/trash.svg';
import Circles from '../../Components/Circles';
import {setSelectedGameIds} from '../../../../Redux/SportSlice';

const MainBetting = () => {
  const {
    setBetSlipDataToStorage,
    getBetSlipDataFromStorage,
    removeBetSlipDataFromStorage,
  } = useStorage();
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const location = useLocation();
  const [getParam] = useSearchParams();
  const {width, height} = useScreenSize();
  const {unSubscribeBulk, getSportBonusRules, unSubscribeLiveEvents} =
    useSocket();

  const wssSocket = useSelector(getWssSocket);
  const isLoggedIn = useSelector(getIsLoggedIn);
  const headerTabIndex = useSelector(getHeaderTabIndex);
  const isBetSlipDataLoading = useSelector(getIsBetSlipDataLoading);
  const maxOddForMultipleBet = useSelector(getMaxOddForMultipleBet);

  const containerRef = useRef(null);

  const [betType, setBetType] = useState(SINGLE_BET);
  const [eventsData, setEventsData] = useState(
    getBetSlipDataFromStorage() || [],
  );
  const [betId, setBetId] = useState(null);
  const [systemBetCount, setSystemBetCount] = useState(2);
  const [isSuccessModalVisible, setIsSuccessModalVisible] = useState(false);

  const sportGameIdParam = useMemo(
    () => getParam.get('sportGameId'),
    [getParam],
  );
  const casinoGameIdParam = useMemo(
    () => getParam.get('casinoGameId'),
    [getParam],
  );

  const allEventsData = useMemo(
    () =>
      eventsData?.map(
        item =>
          (Object.values(Object.values(item?.market || {})?.[0].event) ||
            {})?.[0],
      ),
    [eventsData],
  );

  const hasLiveEvent = useMemo(
    () => eventsData?.some(item => !!item?.is_live),
    [eventsData],
  );

  const allGamesData = useMemo(
    () =>
      eventsData?.reduce((accumulator, currentValue) => {
        return {
          ...accumulator,
          [currentValue?.id]: currentValue,
        };
      }, {}),
    [eventsData],
  );

  const oddsAmount = useMemo(() => {
    const calculatedOdds = allEventsData?.reduce(
      (acc, curr) => acc * curr.price,
      1,
    );

    return calculatedOdds > maxOddForMultipleBet
      ? maxOddForMultipleBet
      : +fixedNumberWithoutRound(calculatedOdds);
  }, [allEventsData, maxOddForMultipleBet]);

  const isBettingBlocked = useMemo(
    () =>
      Object.values(allGamesData || {})?.find(item => item?.is_blocked) ||
      allEventsData?.some(item => item?.isEventDeleted),
    [allEventsData, allGamesData],
  );

  const showEnterCode = useMemo(
    () => isLoggedIn && !eventsData?.length && !isBetSlipDataLoading,
    [eventsData?.length, isBetSlipDataLoading, isLoggedIn],
  );

  const notAllowToBetData = useMemo(() => {
    let notAllowedGameIds = [];
    for (let i = 0; i < eventsData?.length; i++) {
      const game = eventsData?.[i];
      if (
        eventsData?.find((el, index) => {
          const elMarket = Object.values(el?.market || {})?.[0];
          const gameMarket = Object.values(game?.market || {})?.[0];

          return (
            index !== i &&
            game?.id === el?.id &&
            (elMarket?.prematch_express_id ===
              gameMarket?.prematch_express_id ||
              (elMarket?.express_id ?? gameMarket?.express_id ?? 1) ===
                (gameMarket?.express_id ?? elMarket?.express_id ?? 1))
          );
        })
      ) {
        notAllowedGameIds?.push(game?.id);
      }
    }
    return notAllowedGameIds;
  }, [eventsData]);

  const removeItemHandler = useCallback(
    eventId => {
      unSubscribeLiveEvents({
        rid: `${UNSUBSCRIBE_LIVE_EVENT}_${eventId}`,
        subId: eventsData?.find(game => game?.unique_id === eventId)?.subId,
      });
      setEventsData(prev => {
        const filteredArray = prev?.filter(item => item?.unique_id !== eventId);
        const gameIds = filteredArray?.map(gameItem => gameItem?.id);

        dispatch(setSelectedGameIds([...new Set(gameIds)]));

        return filteredArray;
      });
    },
    [dispatch, eventsData, unSubscribeLiveEvents],
  );

  const removeAllHandler = useCallback(() => {
    setEventsData([]);
    removeBetSlipDataFromStorage();
    const allSubIdsArray = eventsData?.map(item => item?.subId);
    unSubscribeBulk({
      subIdsArray: allSubIdsArray,
      rid: `${UNSUBSCRIBE_BULK}_betSlip`,
    });
    dispatch(setSelectedGameIds([]));
  }, [removeBetSlipDataFromStorage, eventsData, unSubscribeBulk, dispatch]);

  useEffect(() => {
    setBetSlipDataToStorage(eventsData);
  }, [eventsData, setBetSlipDataToStorage]);

  useEffect(() => {
    if (!location?.pathname?.includes('/casino')) {
      dispatch(setShowBetSlip(!!eventsData?.length));
    }
    dispatch(setBetSlipCount(eventsData?.length));
  }, [dispatch, eventsData?.length, location?.pathname]);

  useEffect(() => {
    getSportBonusRules();
  }, [getSportBonusRules]);

  useEffect(() => {
    if (allEventsData?.length === 1) {
      setBetType(SINGLE_BET);
    }
  }, [allEventsData?.length]);

  const dataHandler = useCallback(
    data => {
      const gameId = +Object.keys(data?.data?.data?.game || {})?.[0];
      const marketId = +Object.keys(
        data?.data?.data?.game?.[gameId]?.market || {},
      )?.[0];
      const eventId = +Object.keys(
        data?.data?.data?.game?.[gameId]?.market?.[marketId]?.event || {},
      )?.[0];

      setEventsData(prev => {
        const prevState = [...(prev || [])];

        const foundEventIndex = prevState?.findIndex(
          item => item?.unique_id === eventId,
        );
        if (foundEventIndex > -1) {
          const lastPrice =
            prevState[foundEventIndex].market[marketId].event[eventId]
              ?.lastPrice;

          prevState[foundEventIndex] = data?.data?.data?.game?.[gameId];
          prevState[foundEventIndex].subId = data?.data?.subid;
          prevState[foundEventIndex].timestamp = moment?.()?.unix?.();
          prevState[foundEventIndex].unique_id = eventId;
          prevState[foundEventIndex].market[marketId].event[eventId] = {
            ...prevState[foundEventIndex].market[marketId].event[eventId],
            lastPrice,
          };
          return prevState;
        } else {
          const unique_id = +Object.keys(
            Object.values(
              Object.values(data?.data?.data?.game || {})?.[0]?.market || {},
            )?.[0]?.event || {},
          );
          return [
            ...(prevState || []),
            {
              ...Object.values(data?.data?.data?.game || {})?.[0],
              subId: data?.data?.subid,
              unique_id,
              timestamp: moment?.()?.unix?.(),
            },
          ];
        }
      });
      dispatch(setIsBetSlipDataLoading(false));

      containerRef.current?.scroll?.({
        behavior: 'smooth',
        top: containerRef.current?.scrollHeight,
      });
    },
    [dispatch],
  );

  const onMessageCb = useCallback(
    event => {
      const data = JSON.parse(event.data);

      if (data?.rid?.includes(`${SUBSCRIBE_TO_EVENTS}_`)) {
        const ridPartsArray = data?.rid?.split('_');
        const ridEventId = +ridPartsArray?.[5];
        const ridMarketId = +ridPartsArray?.[4];

        const isEventFound = eventsData?.find(
          item => item?.unique_id === +ridEventId,
        );

        if (isEventFound) {
          if (Object.keys(data?.data?.data?.game || {})?.length) {
            dataHandler(data);
          } else {
            setEventsData(prev => {
              const prevState = [...(prev || [])];
              const foundGame = prevState?.find(
                item => item?.unique_id === +ridEventId,
              );
              const foundMarket = foundGame?.market?.[+ridMarketId];
              const foundEvent = foundMarket?.event?.[+ridEventId];
              if (foundEvent) {
                prevState.find(item => item?.unique_id === +ridEventId).market[
                  +ridMarketId
                ].event[+ridEventId].isEventDeleted = true;
                prevState.find(item => item?.unique_id === +ridEventId).subId =
                  data?.data?.subid;
              }
              return prevState;
            });
          }
        }
      }

      if (data?.rid?.includes(`${UNSUBSCRIBE_LIVE_EVENT}_`)) {
        const unsubscribedEventId = +data?.rid?.split('_')?.[1];
        if (unsubscribedEventId) {
          setEventsData(prevState =>
            prevState?.filter(item => item?.unique_id !== unsubscribedEventId),
          );
        }
      }
      switch (data?.rid) {
        case SUBSCRIBE_TO_EVENTS:
          if (Object.keys(data?.data?.data?.game || {})?.length) {
            dataHandler(data);
          }
          break;
        case GET_SPORT_BONUS_RULES:
          if (data?.data?.result === 0) {
            dispatch(setBonusInfoData(data?.data?.details));
          }
          break;
        default:
          break;
      }
    },
    [dataHandler, dispatch, eventsData],
  );

  useEffect(() => {
    wssSocket?.addEventListener('message', onMessageCb);

    return () => {
      wssSocket?.removeEventListener('message', onMessageCb);
    };
  }, [onMessageCb, wssSocket]);

  return (
    <div
      className={`full-width ${width < BREAKPOINT_XS && 'full-height'} ${
        headerTabIndex === 0 ? '' : 'hidden'
      }`}>
      <div
        className={`mainBettingContainerWrapper  column scroll-auto-y scroll-5 ${
          width > BREAKPOINT_XS ? '' : 'pb-md '
        } justify-between full-width ${
          width > BREAKPOINT_XS ? (isLoggedIn ? 'loggedIn' : 'loggedOut') : ''
        }`}
        style={
          width < BREAKPOINT_XS ||
          (width > BREAKPOINT_XS && !!casinoGameIdParam)
            ? {
                ...(!showEnterCode
                  ? {
                      height:
                        !!casinoGameIdParam && width > BREAKPOINT_XS
                          ? 'calc(100% - 40px)'
                          : '100%',
                    }
                  : {
                      height:
                        !!casinoGameIdParam && width > BREAKPOINT_XS
                          ? 'calc(100dvh - 40px)'
                          : height -
                            40 -
                            54 -
                            46 -
                            150 -
                            10 -
                            10 +
                            (!!sportGameIdParam ? 0 : 0),
                    }),
              }
            : {}
        }>
        <div className="column">
          {!!eventsData?.length && (
            <div
              className={`row justify-between items-center ${
                !isLoggedIn ? 'pt-sm' : 'pt-sm'
              }`}>
              <div className="row items-center gap-5">
                <BetTypeDropdown
                  betType={betType}
                  setBetType={setBetType}
                  allEventsData={allEventsData}
                />
                {betType === SYSTEM_BET && (
                  <SystemDropdown
                    setBetType={setBetType}
                    allEventsData={allEventsData}
                    systemBetCount={systemBetCount}
                    eventsCount={allEventsData?.length}
                    setSystemBetCount={setSystemBetCount}
                  />
                )}
              </div>
              <div
                style={{
                  width: 30,
                  height: 30,
                }}
                onClick={removeAllHandler}
                className="whiteBackground items-center justify-center flex rounded-borders cursor-pointer">
                <img width={13} height={15} alt="removeAll" src={trashIcon} />
              </div>
            </div>
          )}
          <div className="column my-md" ref={containerRef}>
            {eventsData
              ?.sort((a, b) => a?.start_ts - b?.start_ts)
              ?.map((item, index) => {
                const marketId = +Object.keys(item?.market || {})?.[0];
                const eventId = +Object.keys(
                  item?.market?.[marketId]?.event || {},
                )?.[0];

                return (
                  <div key={`${eventId}_${item?.timestamp}`}>
                    <GameRow
                      item={item}
                      eventId={eventId}
                      betType={betType}
                      gameId={item?.id}
                      subId={item?.subId}
                      marketId={marketId}
                      isFirst={index === 0}
                      setBetType={setBetType}
                      isFirstItem={index === 0}
                      is_live={!!item?.is_live}
                      allEventsData={allEventsData}
                      setEventsData={setEventsData}
                      isBlocked={!!item?.is_blocked}
                      score2={item?.info?.score2 || 0}
                      score1={item?.info?.score1 || 0}
                      setCount={item?.info?.set_count}
                      eventsCount={allEventsData?.length}
                      removeItemHandler={removeItemHandler}
                      notAllowToBetData={notAllowToBetData}
                      currentMarket={item?.market?.[marketId]}
                      isLast={index === eventsData?.length - 1}
                      gameState={item?.info?.current_game_state}
                      current_game_time={item?.info?.current_game_time}
                      currentEvent={item?.market?.[marketId]?.event?.[eventId]}
                      current_game_last_set={
                        item?.stats?.[`score_${item?.info?.current_game_state}`]
                      }
                      isEventDeleted={
                        item?.market?.[marketId]?.event?.[eventId]
                          ?.isEventDeleted
                      }
                    />
                    {index !== eventsData?.length - 1 && <Circles />}
                  </div>
                );
              })}
            {isBetSlipDataLoading && <BetSlipGameRowSkeleton count={1} />}
            <div id="scrollView" />
          </div>
        </div>
        {!!eventsData?.length ? (
          <OddsContainer
            betType={betType}
            setBetId={setBetId}
            oddsAmount={oddsAmount}
            hasLiveEvent={hasLiveEvent}
            allEventsData={allEventsData}
            systemBetCount={systemBetCount}
            isBettingBlocked={isBettingBlocked}
            removeAllHandler={removeAllHandler}
            removeItemHandler={removeItemHandler}
            notAllowToBetData={notAllowToBetData}
            setIsSuccessModalVisible={setIsSuccessModalVisible}
          />
        ) : (
          !isBetSlipDataLoading && (
            <span className="whiteText bold-600 font-20 text-center py-xxxl">
              {t('emptyBetSlip')}
            </span>
          )
        )}
        <div className="column items-center">
          <img src={Logo} alt="logo" width={100} className="py-lg" />
          {/*<Zigzag />*/}
        </div>
        <BetSlipSuccessModal
          betId={betId}
          isOpen={isSuccessModalVisible}
          setIsOpen={setIsSuccessModalVisible}
        />
      </div>
    </div>
  );
};

export default memo(MainBetting);
