import React, {
  memo,
  useRef,
  useMemo,
  useState,
  useEffect,
  useCallback,
} from 'react';
import {useTranslation} from 'react-i18next';
import {useCollapse} from 'react-collapsed';
import {useSearchParams} from 'react-router-dom';
import {useSelector} from 'react-redux';

import {getWssSocket} from '../../../Redux/AppSlice/AppSelectors';
import {getTaxPercentage} from '../../../Redux/PartnerSlice/PartnerSelectors';

import {useScreenSize, useSocket} from '../../../Hooks';

import {fixedNumberWithoutRound} from '../../../Utils/GetOddValue';
import {bigNumbersPrettier} from '../../../Utils/BigNumbersPrettier';

import {
  BET_BUILDER_URL,
  SPORT_CAST_CREATE_BET,
} from '../../../Constants/Socket';
import {BREAKPOINT_XS} from '../../../Constants/Globals';

import {ReactComponent as SuccessIcon} from '../../../Assets/Icons/Globals/success.svg';
import {ReactComponent as TopTriangle} from '../../../Assets/Icons/Globals/topArrow.svg';
import {ReactComponent as DownTriangle} from '../../../Assets/Icons/Globals/downArrow.svg';

import {AppButton, AppInput} from '../../UI';
import CalculationRow from '../../BetSlip/Components/CalculationRow';

let successShowTimeout = null;

const BetBuilder = () => {
  const {t} = useTranslation();
  const {width} = useScreenSize();
  const [getParam] = useSearchParams();
  const {createSportCastBet} = useSocket();

  const wssSocket = useSelector(getWssSocket);
  const taxPercentage = useSelector(getTaxPercentage);

  const [isExpanded, setExpanded] = useState(true);

  const {getCollapseProps, getToggleProps} = useCollapse({
    isExpanded,
  });

  const betBuilderIdParam = useMemo(
    () => getParam.get('betBuilderId'),
    [getParam],
  );

  const inputRef = useRef(null);
  const betslipUidRef = useRef(null);

  const [betError, setBetError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [stakeAmount, setStakeAmount] = useState('');
  const [showSuccess, setShowSuccess] = useState(false);
  const [betBuilderData, setBetBuilderData] = useState(null);

  const possibleWinAmount = useMemo(
    () => +stakeAmount * betBuilderData?.PriceDetails?.DecimalPrice,
    [stakeAmount, betBuilderData?.PriceDetails?.DecimalPrice],
  );

  const taxAmount = useMemo(
    () => (possibleWinAmount - +stakeAmount) * (taxPercentage / 100),

    [stakeAmount, taxPercentage, possibleWinAmount],
  );

  const finalPayout = useMemo(
    () => possibleWinAmount - taxAmount,
    [taxAmount, possibleWinAmount],
  );

  const onMessage = useCallback(e => {
    if (e?.origin === 'https://betconstruct-prod-gen2.sportcastlive.com') {
      const data = JSON.parse(e?.data);
      betslipUidRef.current = data?.betslipUid;
      setBetBuilderData(data?.betBuilderData);
    }
  }, []);

  const removeAllHandler = useCallback(() => {
    setBetBuilderData(null);
    setStakeAmount('');
    setBetError(null);
  }, []);

  const betNow = useCallback(() => {
    setIsLoading(true);
    createSportCastBet({
      amount: stakeAmount,
      betslip_uid: betslipUidRef.current,
    });
  }, [createSportCastBet, stakeAmount]);

  const onCloseCb = useCallback(() => {
    removeAllHandler();
    betslipUidRef.current = null;
  }, [removeAllHandler]);

  const uri = useMemo(
    () => BET_BUILDER_URL.replace('{fixture_id}', betBuilderIdParam),
    [betBuilderIdParam],
  );

  useEffect(
    () => () => {
      onCloseCb();
    },
    [],
  );

  useEffect(() => {
    window.addEventListener('message', onMessage);
    return () => {
      window.removeEventListener('message', onMessage);
    };
  }, [onMessage]);

  const onMessageCb = useCallback(
    event => {
      const data = JSON.parse(event?.data);
      switch (data?.rid) {
        case SPORT_CAST_CREATE_BET:
          if (data?.data?.details?.Id) {
            removeAllHandler();
            clearTimeout(successShowTimeout);
            setShowSuccess(true);
            setTimeout(() => setShowSuccess(false), 5000);
          } else if (data?.data?.details?.Message) {
            setBetError(data?.data?.details?.Message);
            inputRef.current?.blur();
          }
          setIsLoading(false);
          break;
        default:
          break;
      }
    },
    [removeAllHandler],
  );

  useEffect(() => {
    wssSocket?.addEventListener('message', onMessageCb);

    return () => {
      wssSocket?.removeEventListener('message', onMessageCb);
    };
  }, [onMessageCb, wssSocket]);

  const ArrowIcon = isExpanded ? TopTriangle : DownTriangle;

  return (
    <div className="fit lightGrayBackground relative">
      <iframe
        src={uri}
        width="100%"
        height="100%"
        title="betBuilder"
        style={{
          height: width < BREAKPOINT_XS ? 'calc(100dvh - 280px)' : '100dvh',
        }}
      />
      {betBuilderData &&
        (width > BREAKPOINT_XS ? (
          <div className="fit absolute-full items-end flex">
            <div
              style={{padding: '24px 24px 20px'}}
              className="row full-width lightGrayBackground gap-20">
              <div
                className="column flex-1 pr-md"
                style={{borderRight: '2px solid #d8d8d8'}}>
                <span style={{color: '#4d4d4d'}} className="bold-600 font-16">
                  {t('betSlipLower')}
                </span>
                <div
                  style={{height: 140}}
                  className="column scroll scroll-5 mt-lg">
                  {(betBuilderData?.Selection || [])?.map(item => (
                    <span key={item}>{item}</span>
                  ))}
                </div>
              </div>

              <div className="column flex-1 gap-10 pl-md">
                <div className="row items-center justify-between">
                  <span
                    style={{color: '#595959'}}
                    onClick={removeAllHandler}
                    className="font-12 text-underline cursor-pointer">
                    {t('removeAll')}
                  </span>
                  <span>
                    <span style={{color: '#595959'}} className="font-12">
                      {t('odds')}:{' '}
                    </span>
                    <span className="orangeText font-12">
                      {bigNumbersPrettier(
                        fixedNumberWithoutRound(
                          betBuilderData?.PriceDetails?.DecimalPrice,
                        ),
                      )}
                    </span>
                  </span>
                </div>
                <AppInput
                  type={10}
                  error={betError}
                  inputRef={inputRef}
                  inputValue={stakeAmount}
                  setErrorMsg={setBetError}
                  onChange={setStakeAmount}
                  placeholder={t('enterStake')}
                />
                {!!stakeAmount?.trim() && (
                  <div>
                    <CalculationRow
                      hideCurrency
                      amountContainerHeight={20}
                      amountContainerClassName="pr-none"
                      amount={
                        <div className="row items-center gap-5 overflow-hidden">
                          <span
                            className={`ellipsis bold-700 font-12 greenText`}>
                            {bigNumbersPrettier(
                              fixedNumberWithoutRound(possibleWinAmount),
                            )}{' '}
                            TZS
                          </span>
                        </div>
                      }
                      amountClassName="whiteText"
                      title={
                        <span
                          style={{color: '#595959'}}
                          className={`font-12 flex-shrink`}>
                          {t('possibleWin')}
                        </span>
                      }
                    />
                    <CalculationRow
                      hideCurrency
                      amountContainerHeight={20}
                      amountContainerClassName="pr-none"
                      amount={
                        <div className="row items-center gap-5 overflow-hidden">
                          <span
                            className={`ellipsis bold-700 font-12 greenText`}>
                            {bigNumbersPrettier(
                              fixedNumberWithoutRound(taxAmount),
                            )}{' '}
                            TZS
                          </span>
                        </div>
                      }
                      amountClassName="whiteText"
                      title={
                        <span
                          style={{color: '#595959'}}
                          className={`font-12 flex-shrink`}>
                          {t('tax')}
                        </span>
                      }
                    />
                    <CalculationRow
                      hideCurrency
                      amountContainerHeight={20}
                      amountContainerClassName="pr-none"
                      amount={
                        <div className="row items-center gap-5 overflow-hidden">
                          <span
                            className={`ellipsis bold-700 font-12 greenText`}>
                            {bigNumbersPrettier(
                              fixedNumberWithoutRound(finalPayout),
                            )}{' '}
                            TZS
                          </span>
                        </div>
                      }
                      amountClassName="whiteText"
                      title={
                        <span
                          style={{color: '#595959'}}
                          className={`font-12 flex-shrink`}>
                          {t('finalPayout')}
                        </span>
                      }
                    />
                  </div>
                )}
                <AppButton
                  type={1}
                  onClick={betNow}
                  loading={isLoading}
                  title={t('betNow')}
                  disabled={!stakeAmount?.trim() || !!betError}
                />
              </div>
            </div>
          </div>
        ) : (
          <div className="fit absolute-full items-end flex">
            <div className="pa-lg full-width lightGrayBackground">
              <div
                {...getToggleProps({
                  onClick: () => setExpanded(prevState => !prevState),
                })}>
                <div className="column gap-5">
                  <div className="row items-center justify-between">
                    <span
                      style={{color: '#4d4d4d'}}
                      className="bold-600 font-16">
                      {t('betSlipLower')}
                    </span>
                    <ArrowIcon width={13} height={13} />
                  </div>
                  <div {...getCollapseProps()}>
                    <div
                      style={{maxHeight: 140}}
                      className="column scroll scroll-5">
                      {(betBuilderData?.Selection || [])?.map(item => (
                        <span key={item}>{item}</span>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
              <div className="column gap-10 pt-md">
                <div className="row items-center justify-between">
                  <span
                    style={{color: '#595959'}}
                    onClick={removeAllHandler}
                    className="font-12 text-underline cursor-pointer">
                    {t('removeAll')}
                  </span>
                  <span>
                    <span style={{color: '#595959'}} className="font-12">
                      {t('odds')}:{' '}
                    </span>
                    <span className="orangeText font-12">
                      {bigNumbersPrettier(
                        fixedNumberWithoutRound(
                          betBuilderData?.PriceDetails?.DecimalPrice,
                        ),
                      )}
                    </span>
                  </span>
                </div>
                <AppInput
                  type={10}
                  error={betError}
                  inputRef={inputRef}
                  inputValue={stakeAmount}
                  setErrorMsg={setBetError}
                  onChange={setStakeAmount}
                  placeholder={t('enterStake')}
                />
                {!!stakeAmount?.trim() && (
                  <div>
                    <CalculationRow
                      hideCurrency
                      amountContainerHeight={20}
                      amountContainerClassName="pr-none"
                      amount={
                        <div className="row items-center gap-5 overflow-hidden">
                          <span
                            className={`ellipsis bold-700 font-12 greenText`}>
                            {bigNumbersPrettier(
                              fixedNumberWithoutRound(possibleWinAmount),
                            )}{' '}
                            TZS
                          </span>
                        </div>
                      }
                      amountClassName="whiteText"
                      title={
                        <span
                          style={{color: '#595959'}}
                          className={`font-12 flex-shrink`}>
                          {t('possibleWin')}
                        </span>
                      }
                    />
                    <CalculationRow
                      hideCurrency
                      amountContainerHeight={20}
                      amountContainerClassName="pr-none"
                      amount={
                        <div className="row items-center gap-5 overflow-hidden">
                          <span
                            className={`ellipsis bold-700 font-12 greenText`}>
                            {bigNumbersPrettier(
                              fixedNumberWithoutRound(taxAmount),
                            )}{' '}
                            TZS
                          </span>
                        </div>
                      }
                      amountClassName="whiteText"
                      title={
                        <span
                          style={{color: '#595959'}}
                          className={`font-12 flex-shrink`}>
                          {t('tax')}
                        </span>
                      }
                    />
                    <CalculationRow
                      hideCurrency
                      amountContainerHeight={20}
                      amountContainerClassName="pr-none"
                      amount={
                        <div className="row items-center gap-5 overflow-hidden">
                          <span
                            className={`ellipsis bold-700 font-12 greenText`}>
                            {bigNumbersPrettier(
                              fixedNumberWithoutRound(finalPayout),
                            )}{' '}
                            TZS
                          </span>
                        </div>
                      }
                      amountClassName="whiteText"
                      title={
                        <span
                          style={{color: '#595959'}}
                          className={`font-12 flex-shrink`}>
                          {t('finalPayout')}
                        </span>
                      }
                    />
                  </div>
                )}
                <AppButton
                  type={1}
                  onClick={betNow}
                  loading={isLoading}
                  title={t('betNow')}
                  disabled={!stakeAmount?.trim() || !!betError}
                />
              </div>
            </div>
          </div>
        ))}
      {showSuccess && (
        <div
          className="absolute row items-center pa-md mb-md whiteText greenBackground font-16 bold-700 justify-center rounded-borders gap-10"
          style={{
            left: 0,
            right: 0,
            bottom: 0,
            justifySelf: 'center',
          }}>
          <SuccessIcon width={20} height={20} />
          <span>{t('betSuccess')}</span>
        </div>
      )}
    </div>
  );
};

export default memo(BetBuilder);
