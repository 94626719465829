import React, {memo, useCallback, useEffect, useMemo, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useCollapse} from 'react-collapsed';
import {useSearchParams} from 'react-router-dom';
import {useTranslation} from 'react-i18next';

import {useSocket} from '../../../../Hooks';

import {
  getIsLoggedIn,
  getWssSocket,
} from '../../../../Redux/AppSlice/AppSelectors';
import {
  getBoostedGameIds,
  getFavouriteCompetitionsObject,
} from '../../../../Redux/SportSlice/SportSelectors';
import {setFavouriteCompetitionsObject} from '../../../../Redux/SportSlice';

import RegionSkeletons from '../../../UI/Skeletons/RegionSkeletons';

import {
  MATCH_FILTERS,
  FILTERS_BOOSTED_ODDS_NAME,
  FILTERS_FAVOURITE_COMPETITIONS_NAME,
} from '../../../../Constants/MatchFilters';
import {Flags} from '../../../../Constants/Flags';
import {GET_COMPETITIONS} from '../../../../Constants/Socket';

import {ReactComponent as PlusIcon} from '../../../../Assets/Icons/Globals/plus.svg';
import {ReactComponent as MinusIcon} from '../../../../Assets/Icons/Globals/minus.svg';
import {ReactComponent as StarIcon} from '../../../../Assets/Icons/Globals/whiteStar.svg';

const RegionItem = ({
  index,
  regionItem,
  isExpanded,
  selectedIndex,
  setSelectedIndex,
  selectedFilteredIndex,
  isFilteredItem = false,
  setSelectedFilteredIndex,
}) => {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const [getParam, setGetParam] = useSearchParams();
  const {getCompetitions, setFavouriteCompetition} = useSocket();

  const wssSocket = useSelector(getWssSocket);
  const isLoggedIn = useSelector(getIsLoggedIn);
  const boostedIds = useSelector(getBoostedGameIds);
  const favouriteCompetitionsObject = useSelector(
    getFavouriteCompetitionsObject,
  );

  const sportParam = useMemo(() => getParam.get('sport'), [getParam]);
  const regionParam = useMemo(() => getParam.get('region'), [getParam]);
  const gameFilterParam = useMemo(() => getParam.get('gameFilter'), [getParam]);

  const [open, setOpen] = useState(false);
  const [competitions, setCompetitions] = useState([]);
  const [isCompetitionsLoading, setIsCompetitionsLoading] = useState(false);

  const {getCollapseProps, getToggleProps} = useCollapse({isExpanded});

  const selectedCompetitionsIds = useMemo(() => {
    const splitArray = regionParam?.split?.('-');
    const competitions =
      splitArray?.map(item => item?.split?.('_')?.slice?.(1)) || [];
    return (competitions || [])?.flat?.(Infinity)?.map?.(item => +item) || [];
  }, [regionParam]);

  const onFavouriteSelect = useCallback(
    competitionId => {
      const newFavouriteCompetitionsObject = {...favouriteCompetitionsObject};
      if (
        Object.keys(newFavouriteCompetitionsObject || {})?.includes(
          `${competitionId}`,
        )
      ) {
        delete newFavouriteCompetitionsObject[`${competitionId}`];
      } else {
        newFavouriteCompetitionsObject[`${competitionId}`] = {};
      }
      dispatch(setFavouriteCompetitionsObject(newFavouriteCompetitionsObject));
      setFavouriteCompetition({idsObject: newFavouriteCompetitionsObject});
    },
    [dispatch, setFavouriteCompetition, favouriteCompetitionsObject],
  );

  const onCompetitionClick = useCallback(
    (regionAlias, competitionId) => {
      const splitArray = !!regionParam ? (regionParam || '')?.split?.('-') : [];

      const findIndex = splitArray.findIndex(item =>
        item?.includes(`${regionAlias}`),
      );

      if (!splitArray?.length) {
        splitArray.push(`${regionAlias}_${competitionId}`);
      } else if (findIndex > -1) {
        const competitions = splitArray?.[findIndex]?.split?.('_');
        const foundCompetitionIndex = competitions.indexOf(`${competitionId}`);
        if (foundCompetitionIndex > -1) {
          competitions.splice(foundCompetitionIndex, 1);
        } else {
          competitions.push(`${competitionId}`);
        }
        if (competitions?.length === 1) {
          splitArray?.splice(findIndex, 1);
        } else {
          splitArray[findIndex] = competitions.join('_');
        }
      } else {
        splitArray.push(`${regionAlias}_${competitionId}`);
      }

      if (!!splitArray?.length) {
        getParam.set('region', splitArray.join('-'));
      } else {
        getParam.delete('region');
      }
      setGetParam(getParam);
    },
    [getParam, regionParam, setGetParam],
  );

  const onMessageCb = useCallback(event => {
    const data = JSON.parse(event?.data);

    switch (data?.rid) {
      case GET_COMPETITIONS:
        setCompetitions(
          Object.values(data?.data?.data?.competition || {})?.sort(
            (a, b) => a?.order - b?.order,
          ),
        );
        setTimeout(() => {
          setIsCompetitionsLoading(false);
        }, 400);
        break;
      default:
        break;
    }
  }, []);

  useEffect(() => {
    if (!!isExpanded) {
      setIsCompetitionsLoading(true);

      getCompetitions({
        activeSportAlias: sportParam,
        regionAlias: regionItem?.alias,
        gameFilters:
          gameFilterParam === FILTERS_FAVOURITE_COMPETITIONS_NAME
            ? {
                competition: {
                  id: {
                    '@in': Object.keys(favouriteCompetitionsObject || {})?.map(
                      item => +item,
                    ),
                  },
                },
              }
            : gameFilterParam === FILTERS_BOOSTED_ODDS_NAME &&
              boostedIds !== null
            ? {
                game: {
                  id: {
                    '@in': Object.keys(boostedIds || {})?.map(item => +item),
                  },
                },
              }
            : MATCH_FILTERS?.[gameFilterParam]?.status,
      });
    } else {
      setTimeout(() => {
        setOpen(false);
        setCompetitions([]);
        setIsCompetitionsLoading(false);
      }, 300);
    }
  }, [
    sportParam,
    isExpanded,
    getCompetitions,
    gameFilterParam,
    regionItem?.alias,
    favouriteCompetitionsObject,
  ]);

  useEffect(() => {
    wssSocket?.addEventListener('message', onMessageCb);

    return () => {
      wssSocket?.removeEventListener('message', onMessageCb);
    };
  }, [onMessageCb, wssSocket]);

  const ArrowIcon = isExpanded ? MinusIcon : PlusIcon;

  return (
    <div
      style={{
        border: !!isExpanded ? '1px solid var(--appYellow)' : '1px solid white',
        backgroundColor: !!isExpanded ? 'var(--appYellow)' : '#003069',
      }}
      className={`rounded-borders`}
      {...getToggleProps({
        onClick: () => {
          setOpen(prevState => !prevState);
          if (!!isFilteredItem) {
            if (selectedFilteredIndex === index) {
              setSelectedFilteredIndex(null);
            } else {
              setSelectedIndex(null);
              setSelectedFilteredIndex(index);
            }
          } else {
            if (selectedIndex === index) {
              setSelectedIndex(null);
            } else {
              setSelectedFilteredIndex(null);
              setSelectedIndex(index);
            }
          }
        },
      })}>
      <div
        className="row items-center justify-between py-sm rounded-borders mx-md "
        style={{height: 45}}>
        <div className="row items-center gap-10">
          <img
            width={18}
            alt={regionItem?.alias}
            src={Flags?.[regionItem?.alias] || Flags.defaultFlag}
          />
          <span
            className={`bold-700 font-12 ${
              isExpanded ? 'blueText' : 'whiteText'
            }`}>
            {regionItem?.alias}
          </span>
        </div>
        <ArrowIcon width={11} />
      </div>
      <div {...getCollapseProps()}>
        <div
          style={{backgroundColor: 'var(--appBlue)'}}
          className="pa-sm rounded-borders gap-5 column">
          {isCompetitionsLoading ? (
            <RegionSkeletons />
          ) : !!competitions?.length ? (
            competitions?.map(competitionItem => (
              <div
                key={competitionItem?.id}
                onClick={e => {
                  e?.preventDefault?.();
                  e?.stopPropagation?.();
                  onCompetitionClick(regionItem?.alias, competitionItem?.id);
                }}
                style={{
                  height: 45,
                  border: selectedCompetitionsIds?.includes(competitionItem?.id)
                    ? '1px solid transparent'
                    : '1px solid white',
                  backgroundColor: selectedCompetitionsIds?.includes(
                    competitionItem?.id,
                  )
                    ? 'var(--appYellow)'
                    : '#003069',
                }}
                className="flex row items-center gap-5 px-md paddingVertical-14 rounded-borders">
                {isLoggedIn && (
                  <div
                    className="flex"
                    onClick={e => {
                      e.preventDefault();
                      e.stopPropagation();
                      onFavouriteSelect(competitionItem?.id);
                    }}>
                    <StarIcon
                      fill={
                        Object.keys(
                          favouriteCompetitionsObject || {},
                        )?.includes(`${competitionItem?.id}`)
                          ? 'var(--appYellow)'
                          : 'transparent'
                      }
                    />
                  </div>
                )}
                <div className="row items-center gap-10 overflow-hidden">
                  <img
                    alt={competitionItem?.name}
                    style={{height: 20, maxWidth: 30, objectFit: 'contain'}}
                    src={`https://statistics.bcapps.site/images/c/m/0/${competitionItem?.id}.png`}
                  />
                  <span
                    className={`font-12 bold-700 ellipsis ${
                      selectedCompetitionsIds?.includes(competitionItem?.id)
                        ? 'blueText'
                        : 'whiteText'
                    }`}
                    style={{maxWidth: 200}}>
                    {competitionItem?.name}
                  </span>
                </div>
              </div>
            ))
          ) : (
            <span className="font-12 bold-700 blueText flex-shrink whiteText">
              {t('noDataToShow')}
            </span>
          )}
        </div>
      </div>
    </div>
  );
};

export default memo(RegionItem);
