import React, {
  memo,
  useRef,
  useMemo,
  useState,
  useEffect,
  useCallback,
} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';

import {getWssSocket} from '../../../../Redux/AppSlice/AppSelectors';

import './index.scss';

import {useSocket} from '../../../../Hooks';
import {
  getGameStartTime,
  convertToTimestamp,
} from '../../../../Utils/TimeConvertors';
import {
  formatEventName,
  convertTeamNameToId,
} from '../../../../Utils/EventNamesFormatter';

import {
  GET_MATCH_SCORES,
  GET_SPECIFIC_GAME_STATS,
} from '../../../../Constants/Socket';

import Circles from '../Circles';
import Scoring from '../../../Scoring';
import EventItem from '../../../Home/Games/EventItem';

import {AppBlinkingLiveIcon, AppLiveIndicator} from '../../../UI';

const BetItemRow = ({betItem, isLast}) => {
  const {t} = useTranslation();
  const {getSpecificGameStats, unSubscribeLiveEvents, getMatchScores} =
    useSocket();

  const wssSocket = useSelector(getWssSocket);

  const team1IdRef = useRef(null);
  const team2IdRef = useRef(null);
  const currentGameRef = useRef(null);

  const [subId, setSubId] = useState(null);
  const [isLive, setIsLive] = useState(null);
  const [passTeam, setPassTeam] = useState(null);
  const [team1Score, setTeam1Score] = useState(0);
  const [team2Score, setTeam2Score] = useState(0);
  const [matchResult, setMatchResult] = useState(null);
  const [statsPasses, setStatsPasses] = useState(null);
  const [currentGameState, setCurrentGameState] = useState(null);
  const [currentGameLastSet, setCurrentGameLastSet] = useState(null);

  const startTime = useMemo(
    () => getGameStartTime(convertToTimestamp(betItem?.StartTime)),
    [betItem?.StartTime],
  );

  const scoringData = useMemo(
    () => ({
      ...(currentGameRef.current || {}),
      sport_alias: currentGameRef.current?.sport_alias,
      info: {
        ...(currentGameRef.current?.info || {}),
        score1: team1Score,
        score2: team2Score,
        pass_team: passTeam,
        current_game_state: currentGameState,
      },
      stats: {
        ...(currentGameRef.current?.stats || {}),
        passes: statsPasses,
        [`score_${currentGameState}`]: currentGameLastSet,
      },
    }),
    [
      passTeam,
      team1Score,
      team2Score,
      statsPasses,
      currentGameState,
      currentGameLastSet,
    ],
  );

  useEffect(() => {
    if (betItem?.MatchId) {
      getMatchScores({
        rid: `${GET_MATCH_SCORES}_${betItem?.MatchId}`,
        match_id_list: [betItem?.MatchId],
      });
    }
  }, [betItem?.MatchId, getMatchScores]);

  useEffect(() => {
    if (betItem?.MatchId && !isLive) {
      getSpecificGameStats({
        gameId: betItem?.MatchId,
        rid: `${GET_SPECIFIC_GAME_STATS}_${betItem?.MatchId}`,
      });
    }
  }, [betItem?.MatchId, getSpecificGameStats, isLive]);

  useEffect(
    () => () => subId && unSubscribeLiveEvents({subId}),
    [subId, unSubscribeLiveEvents],
  );

  const onMessageCb = useCallback(
    event => {
      const data = JSON.parse(event.data);
      switch (data?.rid) {
        case `${GET_SPECIFIC_GAME_STATS}_${betItem?.MatchId}`:
          if (data?.data?.subid) {
            setSubId(data?.data?.subid);
          }
          const currentGame = data?.data?.data?.game?.[betItem?.MatchId];
          currentGameRef.current = currentGame;
          if (!currentGame) {
            setIsLive(false);
          }
          team1IdRef.current = currentGame?.team1_id;
          team2IdRef.current = currentGame?.team2_id;

          if (
            currentGame?.info?.score1 &&
            currentGame?.info?.current_game_state !== 'notstarted'
          ) {
            setTeam1Score(currentGame?.info?.score1);
            setTeam2Score(currentGame?.info?.score2);
          }
          if (typeof currentGame?.is_live !== 'undefined') {
            setIsLive(!!currentGame?.is_live);
          }
          if (typeof currentGame?.info?.current_game_state !== 'undefined') {
            setCurrentGameState(currentGame?.info?.current_game_state);
          }
          if (
            typeof currentGame?.stats?.[
              `score_${currentGame?.info?.current_game_state}`
            ] !== 'undefined'
          ) {
            setCurrentGameLastSet(
              currentGame?.stats?.[
                `score_${currentGame?.info?.current_game_state}`
              ],
            );
          }
          if (typeof currentGame?.stats?.passes !== 'undefined') {
            setStatsPasses(prevState => ({
              ...prevState,
              ...currentGame?.stats?.passes,
            }));
          }
          if (
            typeof currentGame?.info?.pass_team !== 'undefined' &&
            !!currentGame?.info?.pass_team
          ) {
            setPassTeam(currentGame?.info?.pass_team);
          }

          break;
        case `${GET_MATCH_SCORES}_${betItem?.MatchId}`:
          const score = data?.data?.details?.[0]?.score;
          const index = score?.indexOf('(') || score?.indexOf(',');
          setMatchResult(score?.slice(0, index)?.trim?.()?.replace(',', ''));
          break;
        default:
          break;
      }
      if (data?.data?.[subId]) {
        const changedGame = data?.data?.[subId]?.game?.[betItem?.MatchId];
        if (changedGame) {
          if (typeof changedGame?.info?.score1 !== 'undefined') {
            setTeam1Score(changedGame?.info?.score1);
          }
          if (typeof changedGame?.info?.score2 !== 'undefined') {
            setTeam2Score(changedGame?.info?.score2);
          }
          if (typeof changedGame?.info?.current_game_state !== 'undefined') {
            setCurrentGameState(changedGame?.info?.current_game_state);
          }
          if (
            typeof changedGame?.stats?.[`score_${currentGameState}`] !==
            'undefined'
          ) {
            setCurrentGameLastSet(prevState => ({
              ...prevState,
              ...changedGame?.stats?.[`score_${currentGameState}`],
            }));
          }
          if (typeof changedGame?.stats?.passes !== 'undefined') {
            setStatsPasses(prevState => ({
              ...prevState,
              ...changedGame?.stats?.passes,
            }));
          }
          if (
            typeof changedGame?.info?.pass_team !== 'undefined' &&
            !!changedGame?.info?.pass_team
          ) {
            setPassTeam(changedGame?.info?.pass_team);
          }
        }
      }
    },
    [subId, betItem?.MatchId, currentGameState],
  );

  useEffect(() => {
    wssSocket?.addEventListener('message', onMessageCb);

    return () => {
      wssSocket?.removeEventListener('message', onMessageCb);
    };
  }, [onMessageCb, wssSocket]);

  return (
    <div className="betItemRowContainer">
      <div className="betHistoryItemContainer">
        <div
          style={{background: '#E6EAEE'}}
          className="gameInfoContainer column full-width py-sm px-md">
          <div className="row items-center  gap-10 flex-shrink">
            {isLive && (
              <div className="row items-center gap-5">
                <AppBlinkingLiveIcon />
                <AppLiveIndicator />
              </div>
            )}
            {isLive ? (
              <div className="row items-center gap-5">
                <Scoring isTitleScore game={scoringData} />
              </div>
            ) : (
              <span className="font-11 bold-700 blackText">{startTime}</span>
            )}
          </div>
          <div className="row items-center gap-5 teamNamesContainer full-width overflow-hidden">
            {!!betItem?.SportAlias && (
              <img
                width={25}
                alt={betItem?.SportAlias}
                className="flex-shrink"
                src={require(
                  `../../../../Assets/Icons/Sport/${betItem?.SportAlias}.svg`,
                )}
              />
            )}
            <div className="column overflow-hidden full-width">
              <div className="row items-center gap-5">
                {team1IdRef.current && (
                  <img
                    width={10}
                    height={10}
                    alt={team1IdRef.current}
                    src={`https://statistics.bcapps.site/images/e/m/${Math.floor(
                      team1IdRef.current / 2000,
                    )}/${team1IdRef.current}.png`}
                  />
                )}
                <span
                  className={`ellipsis font-12 bold-${
                    +team1Score > +team2Score ? '700' : '500' ? '600' : '700'
                  } blueText full-width`}>
                  {betItem?.HomeTeamName}
                </span>
              </div>

              <div className="row items-center gap-5 full-width">
                {team2IdRef.current && (
                  <img
                    width={10}
                    height={10}
                    alt={team2IdRef.current}
                    src={`https://statistics.bcapps.site/images/e/m/${Math.floor(
                      team2IdRef.current / 2000,
                    )}/${team2IdRef.current}.png`}
                  />
                )}
                <span
                  className={`ellipsis font-12 bold-${
                    +team2Score > +team1Score ? '700' : '500' ? '600' : '700'
                  } blueText full-width`}>
                  {betItem?.AwayTeamName}
                </span>
              </div>
            </div>
            {isLive ? (
              <div className="column items-center">
                <Scoring game={scoringData} />
              </div>
            ) : (
              matchResult &&
              !isLive && (
                <span className="bold-600 text-caption text-right blueText ellipsis flex-shrink">
                  {matchResult}
                </span>
              )
            )}
          </div>
        </div>
        <div
          className={`eventInfoContainer whiteBackground column pa-md ${
            isLast ? '' : ''
          } ${!isLast ? 'dashed' : ''}`}>
          <div className="row items-center justify-between">
            <div className="column">
              <span className="font-10 blueText">
                {formatEventName({
                  team1Name: betItem?.HomeTeamName,
                  team2Name: betItem?.AwayTeamName,
                  name: betItem?.DisplayMarketName,
                })}
              </span>
              <div className="row items-center gap-5">
                {team2IdRef.current &&
                  team1IdRef.current &&
                  !isNaN(
                    convertTeamNameToId({
                      team2Id: team2IdRef.current,
                      team1Id: team1IdRef.current,
                      name: betItem?.DisplaySelectionName,
                    }),
                  ) && (
                    <img
                      width={10}
                      height={10}
                      alt="resultTeamLogo"
                      src={`https://statistics.bcapps.site/images/e/m/${Math.floor(
                        convertTeamNameToId({
                          team2Id: team2IdRef.current,
                          team1Id: team1IdRef.current,
                          name: betItem?.DisplaySelectionName,
                        }) / 2000,
                      )}/${convertTeamNameToId({
                        team2Id: team2IdRef.current,
                        team1Id: team1IdRef.current,
                        name: betItem?.DisplaySelectionName,
                      })}.png`}
                    />
                  )}

                <span className="font-12 bold-600 blueText">
                  {formatEventName({
                    team1Name: betItem?.HomeTeamName,
                    team2Name: betItem?.AwayTeamName,
                    name: betItem?.DisplaySelectionName,
                  })}
                </span>
              </div>
            </div>
            <div className="flex gap-5">
              <EventItem betSlip type={1} price={betItem?.Price} disabled />
            </div>
          </div>
        </div>
        {!isLast && <Circles />}
      </div>
    </div>
  );
};

export default memo(BetItemRow);
