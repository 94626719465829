// const MOBILE_KEY = '8acc1c7d-3919-42fa-8539-2186f2d7eab7'; //SITE_ID = 18753040;
const MOBILE_KEY = 'e0bddf90-4ae0-408c-b25a-d85e391da593'; // SITE_ID = 702

// DEV
// const SITE_ID = 18753040;
// const WSS_ENDPOINT = 'wss://eu-swarm-test.betconstruct.com';

// LIVE
const SITE_ID = 702;
const WSS_ENDPOINT = 'wss://eu-swarm-newm.betconstruct.com/';

//Jackpot
const WSS_JACKPOT_ENDPOINT = 'wss://rgs-wss.betconstructapps.com/jackpot';

export const YOUTUBE_API_KEY = 'AIzaSyCGLCiYQ6V0qwo5Px7Zyc1ExGdsE5auWZo';
//Promotions
export const COMPONENT_ID = 841;
export const LICENSE_ID = 12380304;
export const CMS_URL = 'https://cmsbetconstruct.com';

//Sports Jackpot
export const FUN = 'fun';
export const REAL = 'real';
export const TOKEN = 'TOKEN';
export const OPEN_TYPE = 'OPEN_TYPE';
export const SPORTS_JACKPOT_URL = `https://games.pigabet.co.tz/Inner/authorization.php?partnerId=${SITE_ID}&gameId=408&language=en&openType=${OPEN_TYPE}&devicetypeid=2&token=${TOKEN}`;

export const BET_BUILDER_URL =
  'https://betconstruct-prod-gen2.sportcastlive.com/markets?key=a6aa080b-cea9-4e3f-aa02-9fef8d1f0c8f&fixtureid={fixture_id}&odds=DecimalPrice';

export {WSS_ENDPOINT, SITE_ID, MOBILE_KEY, WSS_JACKPOT_ENDPOINT};

export const GET = 'get';
export const USER = 'user';
export const DO_BET = 'do_bet';
export const BETTING = 'betting';
export const CASH_OUT = 'cashout';
export const LOGIN_USER = 'login';
export const GET_USER = 'get_user';
export const BOOK_BET = 'book_bet';
export const DEPOSIT = 'deposit_v2';
export const LOGOUT_USER = 'logout';
export const GET_GAMES = 'get_games';
export const WITHDRAW = 'withdraw_v2';
export const USER_LIMIT = 'user_limits';
export const GET_IMAGES = 'get_images';
export const GET_MAX_BET = 'get_max_bet';
export const UPDATE_USER = 'update_user';
export const MAKE_DEPOSIT = 'make_deposit';
export const UPLOAD_IMAGE = 'upload_image';
export const USER_MESSAGES = 'user_messages';
export const RESTORE_LOGIN = 'restore_login';
export const REGISTER_USER = 'register_user';
export const NOTIFICATIONS = 'notifications';
export const GET_BETS_HISTORY = 'bet_history';
export const GET_ALL_SPORTS = 'get_all_sports';
export const REMOVE_SESSION = 'remove_session';
export const RESET_PASSWORD = 'reset_password';
export const SET_USER_LIMIT = 'set_user_limits';
export const DO_BET_MULTIPLE = 'do_bet_multiple';
export const REQUEST_SESSION = 'request_session';
export const LOGIN_ENCRYPTED = 'login_encrypted';
export const GET_All_LEAGUES = 'get_all_leagues';
export const GET_All_REGIONS = 'get_all_regions';
export const GET_WITHDRAWALS = 'get_withdrawals';
export const CANCEL_WITHDRAW = 'withdraw_cancel';
export const GET_USER_SIGN_IN = 'get_user_sign_in';
export const GET_PARTNER_CONFIG = 'partner.config';
export const VALIDATE_SESSION = 'validate_session';
export const GET_COMPETITIONS = 'get_competitions';
export const UNSUBSCRIBE_BULK = 'unsubscribe_bulk';
export const GET_MATCH_SCORES = 'get_match_scores';
export const CHECK_BET_STATUS = 'check_bet_status';
export const ADD_USER_MESSAGE = 'add_user_message';
export const UNSUBSCRIBE_LIVE_EVENT = 'unsubscribe';
export const APPLY_PROMO_CODE = 'apply_promo_codes';
export const GET_INITIAL_GAMES = 'get_initial_games';
export const GET_SEARCHED_GAMES = 'get_searched_games';
export const GET_BOOSTED_GAMES = 'get_boosted_games';
export const GET_SPECIFIC_GAME = 'get_specific_game';
export const READ_USER_MESSAGE = 'read_user_message';
export const USER_MESSAGES_SENT = 'user_messages_sent';
export const GET_BOOSTED_SPORTS = 'get_boosted_sports';
export const VALIDATE_RECAPTCHA = 'validate_recaptcha';
export const GET_MULTIPLE_GAMES = 'get_multiple_games';
export const GET_FEATURED_GAMES = 'get_featured_games';
export const GET_PAYMENT_SERVICES = 'payment_services';
export const DELETE_USER_MESSAGE = 'delete_user_message';
export const GET_USER_REGISTERED = 'get_user_registered';
export const GET_BOOKED_BETS = 'get_events_by_booking_id';
export const UPDATE_USER_PASSWORD = 'update_user_password';
export const GET_TRANSACTION_HISTORY = 'balance_history_v2';
export const GET_OPEN_BETS_HISTORY = 'get_open_bets_history';
export const GET_OPENED_BETS_COUNT = 'get_opened_bets_count';
export const GET_SPORT_BONUS_RULES = 'get_sport_bonus_rules';
export const GET_PARTNER_LAST_WINS = 'get_partner_last_wins';
export const SPORT_CAST_CREATE_BET = 'sport_cast_create_bet';
export const LOGIN_USER_AUTOMATICALLY = 'login_automatically';
export const GET_ALL_SPORTS_SIDEBAR = 'get_all_sports_sidebar';
export const USER_MESSAGES_INCOMING = 'user_messages_incoming';
export const SEND_SMS_WITH_USERNAME = 'send_sms_with_username';
export const RESET_PASSWORD_VIA_SMS = 'reset_password_via_sms';
export const GET_BOOSTED_SELECTIONS = 'get_boosted_selections';
export const GET_FIXED_COMPETITIONS = 'get_fixed_competitions';
export const GET_PARTNER_BIG_WINS = 'get_partner_last_big_wins';
export const SUBSCRIBE_TO_EVENTS = 'subscribe_to_specific_event';
export const GET_SPECIFIC_GAME_STATS = 'get_specific_game_stats';
export const BUDDY_TO_BUDDY_TRANSFER = 'buddy_to_buddy_transfer';
export const CHANGE_SESSION_LANGUAGE = 'change_session_language';
export const GET_PREDEFINED_MULTIPLES = 'get_predefined_multiples';
export const SET_CLIENT_SELF_EXCLUSION = 'set_client_self_exclusion';
export const GET_SELECTED_COMPETITIONS = 'get_selected_competitions';
export const GET_FAVOURITE_COMPETITIONS = 'get_favourite_competitions';
export const GET_FREE_BETS_FOR_BETSLIP = 'get_freebets_for_betslip_v3';
export const GET_PARTNER_DOCUMENT_TYPES = 'get_partner_document_types';
export const VALIDATE_REGISTER_RECAPTCHA = 'validate_register_recaptcha';
export const SUBSCRIBE_TO_CASH_OUT_AMOUNT = 'subscribe_to_cashout_amount';
export const GET_ALL_POPULAR_COMPETITIONS = 'get_all_popular_competitions';
export const GET_USER_AFTER_RESTORE_LOGIN = 'get_user_after_restore_login';
export const GET_USER_IN_CREDENTIALS_LOGIN_CASE =
  'get_user_in_credentials_login_case';

export const SET_USER_DATA_PERSONALIZATION = 'set_user_data_personalization';
export const DELETE_USER_DATA_PERSONALIZATION =
  'delete_user_resources_personalization';
export const GET_USER_DATA_PERSONALIZATION = 'get_user_data_personalization';

// Jackpot socket
export const GET_JACKPOTS = 'getjackpots';
export const SUBSCRIBE_TO_JACKPOT_DATA = 'subscribe';
export const UNSUBSCRIBE_FROM_JACKPOT_DATA = 'unsubscribe';

export const SOCKET_RESPONSES = {
  OK: 'OK',
  CANCEL: 'cancel',
  LIMIT_REACHED: 22,
  NEED_RECAPTCHA: 27,
  CLIENT_LOCKED: 2009,
  BET_STAKE_LIMIT: 2425,
  MAX_STAKE_ERROR: 2200,
  CLIENT_EXCLUDED: 2008,
  PARTNER_MISMATCH: 2431,
  DUPLICATE_LOGIN: '-1118',
  WRONG_CLIENT_TOKEN: 1006,
  INVALID_CREDENTIALS: 1002,
  CLIENT_NOT_FOUND: '-1001',
  CHANGE_PASSWORD_ERROR: 1005,
  BET_SELECTION_CHANGED: 1800,
  INCORRECT_OLD_PASSWORD: '-1005',
  PENDING_MESSAGE: 'pending_message',
  INVALID_PHONE_NUMBER_FORMAT: '-3080',
};
