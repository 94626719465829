import React, {useCallback, useMemo, useState} from 'react';
import {useCollapse} from 'react-collapsed';
import {useSelector} from 'react-redux';

import {getBetSlipCount} from '../../../Redux/AppSlice/AppSelectors';

import EventsList from './EventsList';

import {useStorage} from '../../../Hooks';

import {formatEventName} from '../../../Utils/EventNamesFormatter';

import {ReactComponent as StarIcon} from '../../../Assets/Icons/Globals/star.svg';
import {ReactComponent as CashoutIcon} from '../../../Assets/Icons/Globals/cashout.svg';
import {ReactComponent as TopTriangle} from '../../../Assets/Icons/Globals/topArrow.svg';
import {ReactComponent as DownTriangle} from '../../../Assets/Icons/Globals/downArrow.svg';
import {ReactComponent as BoostedIcon} from '../../../Assets/Icons/Globals/BoostedIcon.svg';
import {ReactComponent as CircleTopIcon} from '../../../Assets/Icons/Globals/circleTopIcon.svg';
import {ReactComponent as StarActiveIcon} from '../../../Assets/Icons/Globals/starWithBackground.svg';
import {getBoostedGameIds} from '../../../Redux/SportSlice/SportSelectors';

const MarketListItem = ({
  index,
  gameId,
  team1Name,
  team2Name,
  marketItem,
  favouriteMarkets,
  setFavouriteMarkets,
}) => {
  const boostedIds = useSelector(getBoostedGameIds);

  const [isExpanded, setExpanded] = useState(true);

  const {setFavouriteMarketsToStorage} = useStorage();

  const {getCollapseProps, getToggleProps} = useCollapse({
    isExpanded,
  });

  const TriangleIcon = useMemo(
    () => (isExpanded ? CircleTopIcon : CircleTopIcon),
    [isExpanded],
  );

  const isFavourite = useMemo(
    () => !!favouriteMarkets?.includes(marketItem?.market_type),
    [favouriteMarkets, marketItem?.market_type],
  );

  const marketItemIds = useMemo(
    () => marketItem?.data?.map(item => item?.id),
    [marketItem?.data],
  );

  const showBoostedIcon = useMemo(
    () =>
      Object.keys(boostedIds?.[gameId] || {})?.some(item =>
        marketItemIds?.includes(+item),
      ),
    [boostedIds, gameId, marketItemIds],
  );

  const changeFavouriteItem = useCallback(
    e => {
      setFavouriteMarkets(prevState => {
        e.stopPropagation();
        const newData = [...prevState];
        const itemIndex = newData.indexOf(marketItem?.market_type);
        if (itemIndex > -1) {
          newData.splice(itemIndex, 1);
        } else {
          newData?.push(marketItem?.market_type);
        }
        return newData;
      });
      setFavouriteMarketsToStorage(marketItem?.market_type)?.then?.();
    },
    [
      setFavouriteMarkets,
      marketItem?.market_type,
      setFavouriteMarketsToStorage,
    ],
  );

  return (
    <div className="full-width marketContainerRadius">
      <div
        className="flex justify-between items-center marketsWrapper"
        {...getToggleProps({
          onClick: () => setExpanded(prevState => !prevState),
        })}>
        <div className="row items-center justify-between full-width">
          <div className="flex gap-3 items-center">
            <div onClick={changeFavouriteItem} className="flex rowCenter">
              <StarActiveIcon
                width={12}
                height={12}
                fill={
                  !!isFavourite ? 'var(--appYellow)' : 'var(--semiDarkenGray)'
                }
              />
            </div>
            <span
              className="font-12 bold-700"
              style={{color: 'var(--newLightBlue)'}}>
              {formatEventName({
                team1Name,
                team2Name,
                name: marketItem?.name,
              })}
            </span>
          </div>
          <div className="row items-center gap-3">
            {showBoostedIcon && <BoostedIcon width={12} height={18} />}
            {marketItem?.cashout && <CashoutIcon width={14} height={14} />}
            <TriangleIcon width={16} height={16} fill="var(--appBlue)" />
          </div>
        </div>
      </div>
      <div
        {...getCollapseProps()}
        className={`${!!isExpanded ? '' : 'blueBackground hidden'}`}>
        <div className="column gap-5 pt-sm">
          {marketItem?.data?.map((eventItem, index) => (
            <div
              className="flex row wrap justify-between"
              key={index}
              style={{gap: 2}}>
              <EventsList
                gameId={gameId}
                team1Name={team1Name}
                team2Name={team2Name}
                marketItem={eventItem}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
// DO NOT PUT IN MEMO
export default MarketListItem;
