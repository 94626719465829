import React, {useMemo} from 'react';

const CricketTitleScore = ({game}) => {
  const halfValue = useMemo(() => {
    let gameHalf = game?.info?.current_game_state;
    gameHalf = gameHalf?.replace?.(/set1/g, '1st');
    gameHalf = gameHalf?.replace?.(/set2/g, '2nd');
    gameHalf = gameHalf?.replace?.(/set3/g, '3rd');
    gameHalf = gameHalf?.replace?.(/set4/g, '4th');

    return gameHalf?.trim?.()?.replace?.('notstarted', 'notStarted') || '1st';
  }, [game?.info?.current_game_state]);

  return (
    <>
      {!!game?.is_live && game?.info?.current_game_state && (
        <span className={`bold-700 font-10 orangeText text-capitalize`}>
          {game?.stats?.over?.[`${game?.info?.pass_team}_value`]}{' '}
          <span className="bold-400 font-10 blueText">Overs</span>
        </span>
      )}
      {!!halfValue && (
        <span className={`bold-700 font-10 orangeText text-capitalize`}>
          {halfValue} <span className="bold-400 font-10 blueText">Innings</span>
        </span>
      )}
    </>
  );
};

export default CricketTitleScore;
