import React, {memo, useCallback, useMemo, useState} from 'react';
import {useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {useCollapse} from 'react-collapsed';

import './index.scss';

import {getCurrency} from '../../../../Redux/PartnerSlice/PartnerSelectors';

import {convertToDate} from '../../../../Utils/TimeConvertors';
import {fixedNumberWithoutRound} from '../../../../Utils/GetOddValue';
import {bigNumbersPrettier} from '../../../../Utils/BigNumbersPrettier';
import {getCombinations} from '../../../../Utils/CombinationsGenerator';

import Logo from '../../../../Assets/Layout/HeaderLogo.png';
import currencyImage from '../../../../Assets/Icons/Globals/TZS.svg';
import {ReactComponent as CupIcon} from '../../../../Assets/Icons/Globals/cup.svg';
import {ReactComponent as CopyIcon} from '../../../../Assets/Icons/MainMenu/copy.svg';
import {ReactComponent as UpArrowsIcon} from '../../../../Assets/Icons/Globals/upArrows.svg';
import {ReactComponent as TriangleTop} from '../../../../Assets/Icons/Globals/triangleTop.svg';
import {ReactComponent as TriangleDown} from '../../../../Assets/Icons/Globals/downTriangleWhite.svg';
import {ReactComponent as Chevron} from '../../../../Assets/Icons/Globals/chevronBottom.svg';

import BetHistoryItem from '../BetHistoryItem';
import CashOutActions from '../CashOutActions';
import CalculationRow from '../CalculationRow';
import CashOutCalculations from '../CashOutCalculations';

import {AppButton} from '../../../UI';
import BetSocialShare from '../BetSocialShare';

import {DATE_DEFAULT_FORMAT} from '../../../../Constants/Globals';
import {SINGLE_BET} from '../../../../Constants/Betting';

const BetHistoryContainer = ({
  type,
  stake,
  betId,
  index,
  bonus,
  events,
  payout,
  outcome,
  setIndex,
  date_time,
  totalOdds,
  betDetails,
  tax_amount,
  finalPayout,
  eventsCount,
  possibleWin,
  cashOutAmount,
  systemMinCount,
  cashOutedAmountRef,
  setIsSuccessModalOpen,
  rowType = false,
  setIsBetHistoryDataLoading,
}) => {
  const {t} = useTranslation();

  const currency = useSelector(getCurrency);

  const [isExpanded, setExpanded] = useState(index === 0 || rowType);

  const {getCollapseProps, getToggleProps} = useCollapse({isExpanded});

  const betType = useMemo(
    () =>
      type === 1
        ? 'singleUpper'
        : type === 2
        ? 'multipleUpper'
        : type === 3
        ? 'systemUpper'
        : type === 50
        ? 'betBuilder'
        : 'chainUpper',
    [type],
  );

  const betOutComeInputBackground = useMemo(
    () =>
      outcome &&
      (outcome === 1
        ? '#DFB6A6'
        : outcome === 2
        ? '#C8AD48'
        : outcome === 3
        ? '#A8D4AE'
        : '#C8AD48'),
    [outcome],
  );

  const betOutComeBackground = useMemo(
    () =>
      outcome &&
      (outcome === 1
        ? '#FFDFD3'
        : outcome === 2
        ? '#fad85a'
        : outcome === 3
        ? '#D7FFDD'
        : '#fad85a'),
    [outcome],
  );

  const betOutComeTitleWithAmount = useMemo(
    () =>
      outcome &&
      (outcome === 1
        ? t('lostUpper')
        : outcome === 2
        ? t('returnedWithAmount', {
            amount: `${currency} ${bigNumbersPrettier(payout)}`,
          })
        : outcome === 3
        ? t('wonUpper', {
            amount: `${currency} ${bigNumbersPrettier(payout)}`,
          })
        : t('cashedOutWithAmount', {
            amount: `${currency} ${bigNumbersPrettier(payout)}`,
          })),
    [outcome, t, currency, payout],
  );

  const betOutComeType = useMemo(
    () =>
      outcome &&
      (outcome === 1 ? 5 : outcome === 2 ? 13 : outcome === 3 ? 3 : 1),
    [outcome],
  );

  const TriangleIcon = isExpanded ? TriangleTop : TriangleDown;

  return (
    <div
      className={`betHistoryContainer ${
        rowType ? 'rowType col-xs-12 col-md-6 col-lg-4 col-xl-3' : ''
      }`}>
      <div className="column rounded-borders">
        <div
          {...getToggleProps({
            onClick: () => setExpanded(prevExpanded => !prevExpanded),
          })}>
          <div
            className={`${
              isExpanded
                ? 'yellowBackground blueText'
                : !!outcome
                ? 'semiLightenGrayBackground blueText'
                : 'semiLightenGrayBackground blueText'
            } ${
              isExpanded ? 'rounded-top-borders' : 'rounded-top-borders'
            } px-md paddingVertical-9`}>
            <div className="row items-center justify-between gap-5">
              <div className="row items-center gap-5 overflow-hidden">
                <span className="font-12 bold-600 flex-shrink text-capitalize">
                  {t(betType)?.toLowerCase()}{' '}
                  {!!eventsCount ? `(${eventsCount})` : ''}
                </span>
                {type === 3 && !!systemMinCount && (
                  <span className="font-12 bold-600 flex-shrink text-capitalize">{`${systemMinCount}/${eventsCount} (${
                    getCombinations(eventsCount, systemMinCount)?.length
                  } opt.)`}</span>
                )}
              </div>

              {outcome ? (
                <AppButton
                  height={20}
                  fontSize={11}
                  width="fit-content"
                  disabledWithoutLayer
                  type={betOutComeType}
                  titleClassName="font-11 bold-400"
                  className="py-xs px-sm flex items-center justify-center"
                  title={t(
                    outcome === 1
                      ? 'lost'
                      : outcome === 2
                      ? 'returned'
                      : outcome === 3
                      ? 'won'
                      : 'cashedOut',
                  )}
                />
              ) : (
                type !== 50 && (
                  <BetSocialShare type={4} betId={betId} />
                  // <span className="font-9  ellipsis text-capitalize">
                  //   {convertToDate(date_time * 1000, DATE_DEFAULT_FORMAT)}
                  // </span>
                )
              )}
              <div className="row items-center gap-10 flex-shrink">
                {/*{!isExpanded && (*/}
                {/*  <div className="row items-center gap-5">*/}
                {/*    <img height={12} alt="currency" src={currencyImage} />*/}
                {/*    <span className="text-caption  bold-700">*/}
                {/*      {currency} {bigNumbersPrettier(stake)}*/}
                {/*    </span>*/}
                {/*  </div>*/}
                {/*)}*/}
                {/*{outcome ? (*/}
                {/*  <AppButton*/}
                {/*    fontSize={11}*/}
                {/*    disabledWithoutLayer*/}
                {/*    type={betOutComeType}*/}
                {/*    className="px-md py-xs"*/}
                {/*    title={t(betOutComeTitle)}*/}
                {/*  />*/}
                {/*) : (*/}
                {/*  */}
                {/*)}*/}
                <div
                  style={{
                    width: 25,
                    height: 25,
                    flexShrink: 0,
                    borderRadius: '50%',
                    backgroundColor: '#022751',
                  }}
                  className="row items-center justify-center">
                  <Chevron
                    fill="white"
                    style={isExpanded ? {} : {transform: 'rotate(180deg)'}}
                  />
                </div>
                {/*<div className="row items-center gap-8">*/}
                {/*  <span className="font-9 text-capitalize">*/}
                {/*    {t(isExpanded ? 'hide' : 'seeMore').toLowerCase()}*/}
                {/*  </span>*/}
                {/*  <UpArrowsIcon*/}
                {/*    height={14}*/}
                {/*    className="flex-shrink"*/}
                {/*    fill={!!outcome ? 'white' : 'var(--appBlue)'}*/}
                {/*    style={!isExpanded ? {transform: 'rotate(180deg)'} : {}}*/}
                {/*  />*/}
                {/*</div>*/}
              </div>
            </div>
          </div>
        </div>
        {!isExpanded && (
          // <div className="column">
          //   <div
          //     className="px-sm paddingVertical-8 column gap-5"
          //     style={{
          //       background: outcome ? betOutComeBackground : '#104687',
          //     }}>
          //     {/*{outcome && outcome === 3 && (*/}
          //     <CalculationRow
          //       hideCurrency
          //       amount={totalOdds}
          //       title={t('totalOdds')}
          //       amountClassName={
          //         outcome
          //           ? 'blueText bold-700 font-12'
          //           : 'whiteText bold-700 font-12'
          //       }
          //       titleClassName={
          //         outcome
          //           ? 'blueText bold-700 font-12'
          //           : 'whiteText bold-700 font-12'
          //       }
          //       amountContainerClassName="pr-none"
          //     />
          //     {/*)}*/}
          //     {/*{outcome && (*/}
          //     <CalculationRow
          //       customStyles={
          //         outcome ? {background: betOutComeInputBackground} : {}
          //       }
          //       title={t('stake')}
          //       amountClassName={
          //         outcome
          //           ? 'blueText bold-700 font-12'
          //           : 'whiteText bold-700 font-12'
          //       }
          //       titleClassName={
          //         outcome
          //           ? 'blueText bold-700 font-12'
          //           : 'whiteText bold-700 font-12'
          //       }
          //       amount={bigNumbersPrettier(stake)}
          //     />
          //
          //     {/*{outcome === 5 && (*/}
          //     {!!possibleWin && (
          //       <CalculationRow
          //         title={t('possibleWin')}
          //         amountClassName={
          //           outcome
          //             ? 'blueText bold-700 font-12'
          //             : 'whiteText bold-700 font-12'
          //         }
          //         titleClassName={
          //           outcome
          //             ? 'blueText bold-700 font-12'
          //             : 'whiteText bold-700 font-12'
          //         }
          //         amount={bigNumbersPrettier(possibleWin)}
          //         customStyles={
          //           outcome ? {background: betOutComeInputBackground} : {}
          //         }
          //       />
          //     )}
          //     {/*)}*/}
          //     {/*)}*/}
          //
          //     {/*{(outcome === 3 || !outcome) && (*/}
          //     {!!finalPayout && (
          //       <CalculationRow
          //         title={t('finalPayout')}
          //         amountClassName={
          //           outcome
          //             ? 'blueText bold-700 font-12'
          //             : 'whiteText bold-700 font-12'
          //         }
          //         titleClassName={
          //           outcome
          //             ? 'blueText bold-700 font-12'
          //             : 'whiteText bold-700 font-12'
          //         }
          //         amount={fixedNumberWithoutRound(finalPayout)}
          //         customStyles={
          //           outcome ? {background: betOutComeInputBackground} : {}
          //         }
          //       />
          //     )}
          //     {/*)}*/}
          //   </div>
          //   {!outcome && (
          //     <CashOutActions
          //       betId={betId}
          //       cashOutAmount={cashOutAmount}
          //       cashOutedAmountRef={cashOutedAmountRef}
          //       setIsSuccessModalOpen={setIsSuccessModalOpen}
          //     />
          //   )}
          //   {/*<BetSocialShare betId={betId} type={2} finalPayout={finalPayout} />*/}
          // </div>
          <div
            className="column rounded-bottom-borders newExtraLightenBlueBackground"
            style={{
              borderBottom: '1px solid #B5B5B5',
              borderRight: '1px solid #B5B5B5',
              borderLeft: '1px solid #B5B5B5',
            }}>
            <CashOutCalculations
              stake={stake}
              betId={betId}
              bonus={bonus}
              payout={payout}
              tax={tax_amount}
              outcome={outcome}
              totalOdds={totalOdds}
              finalPayout={finalPayout}
            />
            {!outcome && (
              <CashOutActions
                betId={betId}
                cashOutAmount={cashOutAmount}
                cashOutedAmountRef={cashOutedAmountRef}
                setIsSuccessModalOpen={setIsSuccessModalOpen}
              />
            )}
            {/*<div*/}
            {/*  style={!!cashOutAmount ? {borderTop: '1px solid #B5B5B5'} : {}}*/}
            {/*  className={`column blueBackground items-center pa-md flex justify-end logoContainer rounded-bottom-borders ${*/}
            {/*    rowType ? 'rowType' : ''*/}
            {/*  }`}>*/}
            {/*  <img src={Logo} alt="logo" width={140} className="py-xl" />*/}
            {/*  <BetSocialShare betId={betId} finalPayout={finalPayout} />*/}
            {/*</div>*/}
          </div>
        )}
      </div>
      <div {...getCollapseProps()}>
        {isExpanded && (
          <div className="column rounded-borders">
            {/*<div*/}
            {/*  className="px-md paddingVertical-8 row justify-between items-center newLightBlueBackground rounded-top-borders"*/}
            {/*  style={*/}
            {/*    {*/}
            {/*      // borderTop: '1px solid #B5B5B5',*/}
            {/*      // borderRight: '1px solid #B5B5B5',*/}
            {/*      // borderLeft: '1px solid #B5B5B5',*/}
            {/*    }*/}
            {/*  }>*/}
            {/*  <CupIcon width={12} height={12} />*/}
            {/*  <span className="whiteText bold-600 font-12">*/}
            {/*    {t('ticket')} #{index}*/}
            {/*  </span>*/}
            {/*  <div style={{width: 15}} />*/}
            {/*</div>*/}
            <div
              className={`rounded-borders column ${
                rowType ? 'rowTypeBody' : ''
              }`}>
              <div className="scroll-1 scroll-auto-y bodyContainer">
                {events?.length ? (
                  (
                    events?.sort(
                      (a, b) => a?.game_start_date - b?.game_start_date,
                    ) || []
                  )?.map((event, i) => (
                    <BetHistoryItem
                      betOutCome={outcome}
                      gameId={event?.game_id}
                      team1Name={event?.team1}
                      team2Name={event?.team2}
                      key={event?.selection_id}
                      team1_name={event?.team1}
                      team2_name={event?.team2}
                      eventOutCome={event?.outcome}
                      event_name={event?.event_name}
                      coeficient={event?.coeficient}
                      sportAlias={event?.sport_index}
                      market_name={event?.market_name}
                      isLast={i === events?.length - 1}
                      game_start_date={event?.game_start_date}
                      setIsBetHistoryDataLoading={setIsBetHistoryDataLoading}
                    />
                  ))
                ) : betDetails ? (
                  <div className="column whiteBackground gap-5 full-width py-sm px-md">
                    <span className="ellipsis font-12 bold-700 blueText full-width">
                      {betDetails?.fixture}
                    </span>
                    <div className="column">
                      {betDetails?.description?.map(item => (
                        <span
                          key={item}
                          className="font-12 bold-600 blueText ellipsis">
                          {item}
                        </span>
                      ))}
                    </div>
                  </div>
                ) : null}
              </div>
              <div
                className="column rounded-bottom-borders newExtraLightenBlueBackground"
                style={{
                  borderBottom: '1px solid #B5B5B5',
                  borderRight: '1px solid #B5B5B5',
                  borderLeft: '1px solid #B5B5B5',
                }}>
                <CashOutCalculations
                  stake={stake}
                  betId={betId}
                  bonus={bonus}
                  payout={payout}
                  tax={tax_amount}
                  outcome={outcome}
                  totalOdds={totalOdds}
                  finalPayout={finalPayout}
                />
                {!outcome && (
                  <CashOutActions
                    betId={betId}
                    cashOutAmount={cashOutAmount}
                    cashOutedAmountRef={cashOutedAmountRef}
                    setIsSuccessModalOpen={setIsSuccessModalOpen}
                  />
                )}
                {/*<div*/}
                {/*  style={!!cashOutAmount ? {borderTop: '1px solid #B5B5B5'} : {}}*/}
                {/*  className={`column blueBackground items-center pa-md flex justify-end logoContainer rounded-bottom-borders ${*/}
                {/*    rowType ? 'rowType' : ''*/}
                {/*  }`}>*/}
                {/*  <img src={Logo} alt="logo" width={140} className="py-xl" />*/}
                {/*  <BetSocialShare betId={betId} finalPayout={finalPayout} />*/}
                {/*</div>*/}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default memo(BetHistoryContainer);
