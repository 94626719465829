import React, {memo, useCallback, useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {useSearchParams} from 'react-router-dom';
import {useSelector} from 'react-redux';

import './index.scss';

import {getIsGameDataLoading} from '../../../Redux/SportSlice/SportSelectors';

import {AppButton} from '../../UI';

const ListItem = ({item}) => {
  const {t} = useTranslation();
  const [getParam, setGetParam] = useSearchParams();

  const regionParam = getParam.get('region');

  const isGameDataLoading = useSelector(getIsGameDataLoading);

  const isActive = useMemo(() => {
    const splitArray = regionParam?.split?.('-');
    const competitions =
      splitArray?.map(item => item?.split?.('_')?.slice?.(1)) || [];
    return (
      (competitions || [])?.flat?.(Infinity)?.map?.(item => +item) || []
    )?.includes(item?.competitionId);
  }, [item?.competitionId, regionParam]);

  const clickHandler = useCallback(
    ({regionAlias, competitionId}) => {
      const splitArray = !!regionParam ? (regionParam || '')?.split?.('-') : [];

      const findIndex = splitArray.findIndex(item =>
        item?.includes(`${regionAlias}`),
      );

      if (!splitArray?.length) {
        splitArray.push(`${regionAlias}_${competitionId}`);
      } else if (findIndex > -1) {
        const competitions = splitArray?.[findIndex]?.split?.('_');
        const foundCompetitionIndex = competitions.indexOf(`${competitionId}`);
        if (foundCompetitionIndex > -1) {
          competitions.splice(foundCompetitionIndex, 1);
        } else {
          competitions.push(`${competitionId}`);
        }
        if (competitions?.length === 1) {
          splitArray?.splice(findIndex, 1);
        } else {
          splitArray[findIndex] = competitions.join('_');
        }
      } else {
        splitArray.push(`${regionAlias}_${competitionId}`);
      }

      if (!!splitArray?.length) {
        getParam.set('region', splitArray.join('-'));
      } else {
        getParam.delete('region');
      }
      setGetParam(getParam);
    },
    [getParam, regionParam, setGetParam],
  );

  return (
    <AppButton
      small
      type={19}
      height={32}
      active={isActive}
      disabledWithoutLayer={isGameDataLoading}
      className="flex justify-center items-center"
      onClick={() =>
        clickHandler({
          regionAlias: item?.regionAlias,
          competitionId: item?.competitionId,
        })
      }
      title={
        <div className="flex row items-center gap-5 px-xs">
          <img
            height={20}
            alt={item?.title}
            className="competitionIcon"
            src={
              item?.logo ||
              `https://statistics.bcapps.site/images/c/m/0/${item?.competitionId}.png`
            }
          />
          <span className="font-12 bold-600 flex-shrink">{t(item?.title)}</span>
        </div>
      }
    />
  );
};

export default memo(ListItem);
