import React, {memo} from 'react';
import {useSearchParams} from 'react-router-dom';
import {Swiper, SwiperSlide} from 'swiper/react';
import {FreeMode, Keyboard} from 'swiper/modules';

import './index.scss';

import {MOBILE_FIXED_COMPETITIONS} from '../../../Constants/MobileFixedCompetitionsData';

import ListItem from './ListItem';

const FixedCompetitions = () => {
  const [getParam] = useSearchParams();

  const sportParam = getParam.get('sport');

  return (
    <Swiper
      freeMode
      keyboard
      mousewheel
      spaceBetween={5}
      slidesPerView="auto"
      modules={[Keyboard, FreeMode]}
      className={`${
        !MOBILE_FIXED_COMPETITIONS?.[sportParam]?.length && 'hide'
      } fixedCompetitionsSwiper`}>
      {MOBILE_FIXED_COMPETITIONS?.[sportParam]?.map(item => (
        <SwiperSlide key={item?.competitionId}>
          <ListItem item={item} />
        </SwiperSlide>
      ))}
    </Swiper>
  );
};

export default memo(FixedCompetitions);
