import React, {memo, useCallback, useEffect, useState} from 'react';
import axios from 'axios';
import {useDispatch, useSelector} from 'react-redux';
import {Swiper, SwiperSlide} from 'swiper/react';
import {Autoplay} from 'swiper/modules';
import {
  useNavigate,
  useLocation,
  useSearchParams,
  createSearchParams,
} from 'react-router-dom';

import 'swiper/css';
import './index.scss';
import 'swiper/css/navigation';

import {
  getLanguage,
  getIsLoggedIn,
  getShowMobileBetSlip,
} from '../../../Redux/AppSlice/AppSelectors';
import {setAuthModal} from '../../../Redux/AppSlice';

import {useScreenSize} from '../../../Hooks';
import useCasino from '../../../Hooks/UseCasino';

import {BREAKPOINT_XS} from '../../../Constants/Globals';
import {APP_LANGUAGES} from '../../../Constants/Languages';
import {CMS_URL, SITE_ID, SOCKET_RESPONSES} from '../../../Constants/Socket';

import CarouselSkeletons from '../Skeletons/CarouselSkeletons';

const autoplay = {
  delay: 2500,
  disableOnInteraction: false,
};

const Carousel = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const {width} = useScreenSize();
  const dispatch = useDispatch();
  const [getParam, setGetParam] = useSearchParams();

  const {goToCasinoGameScreen} = useCasino();

  const sportGameIdParam = getParam.get('sportGameId');
  const casinoGameIdParam = getParam.get('casinoGameId');

  const language = useSelector(getLanguage);
  const isLoggedIn = useSelector(getIsLoggedIn);
  const showMobileBetSlip = useSelector(getShowMobileBetSlip);

  const [carouselData, setCarouselData] = useState([]);
  const [isDataLoading, setIsDataLoading] = useState(false);

  const onClickHandler = useCallback(
    item => {
      if (item?.href?.includes('openGames')) {
        goToCasinoGameScreen({
          game: {
            extearnal_game_id: +item?.href
              ?.split('openGames=')?.[1]
              ?.split('-')?.[0],
          },
          mode: 'real',
        });
        if (!isLoggedIn) {
          dispatch(setAuthModal({tabIndex: 0, isVisible: true}));
        }
      } else {
        getParam.set('showPromotions', 'all');
        setGetParam(getParam);
      }
    },
    [dispatch, getParam, goToCasinoGameScreen, isLoggedIn, setGetParam],
  );

  useEffect(() => {
    const selectedLanguage = language === APP_LANGUAGES.CHN ? 'chi' : language;
    setIsDataLoading(true);
    axios
      .get(
        location?.pathname.includes('casino')
          ? `https://cms.qaxcraveniq.com/api/public/v1/${selectedLanguage}/partners/${SITE_ID}/components/casino_banners/contents?platform=${
              width < BREAKPOINT_XS ? '1' : '0'
            }`
          : `https://cms.qaxcraveniq.com/api/public/v1/${selectedLanguage}/partners/${SITE_ID}/components/841/contents?use_webp=1&platform=${
              width < BREAKPOINT_XS ? '1' : '0'
            }`,
        // `${CMS_URL}/api/public/v1/${selectedLanguage}/partners/${SITE_ID}/promotions?platform=0&category=all&exclude=content`,
        // `${CMS_URL}/api/public/v1/${selectedLanguage}/partners/${SITE_ID}/components/casino_banners/contents?platform=1`,
        // `${CMS_URL}/api/public/v1/${selectedLanguage}/partners/${SITE_ID}/components/casino_banners/contents?platform=0`
      )
      .then(data => {
        if (data?.data && data?.data?.text === SOCKET_RESPONSES.OK) {
          const carouselDataArray = data?.data?.data?.map?.(item => ({
            id: item?.id,
            href: item?.href,
            target: item?.target,
            src: `${CMS_URL}${item?.src}`,
          }));
          setCarouselData(carouselDataArray);
        } else {
          setCarouselData(null);
        }
      })
      .catch(() => {})
      .finally(() => setIsDataLoading(false));
  }, [language, location?.pathname?.split?.('/')?.[1], width < BREAKPOINT_XS]);

  return (
    <div
      key={`${showMobileBetSlip}_betslip_${
        (location?.pathname?.includes('/casino') &&
          !!casinoGameIdParam &&
          isLoggedIn) ||
        (!!sportGameIdParam && width < BREAKPOINT_XS)
      }_condition`}
      style={{background: 'var(--appBlue)'}}
      className={`homeCarouselContainer my-md ${
        (location?.pathname?.includes('/casino') &&
          !!casinoGameIdParam &&
          isLoggedIn) ||
        (!!sportGameIdParam && width < BREAKPOINT_XS)
          ? 'hidden'
          : 'block'
      } ${
        location?.pathname?.includes('/casino') &&
        !(width < BREAKPOINT_XS) &&
        'casinoCarousel'
      }`}>
      {isDataLoading ? (
        <CarouselSkeletons />
      ) : (
        !!carouselData?.length && (
          <Swiper
            loop
            centeredSlides
            autoplay={autoplay}
            slidesPerView="auto"
            modules={[Autoplay]}
            className="appCarousel"
            spaceBetween={width < BREAKPOINT_XS ? 20 : 40}>
            {carouselData?.map(item => (
              <SwiperSlide key={item?.id}>
                <div
                  onClick={() => onClickHandler(item)}
                  className="cursor-pointer">
                  <img src={item?.src} alt={item?.id} />
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        )
      )}
    </div>
  );
};

export default memo(Carousel);
