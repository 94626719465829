import React, {useMemo} from 'react';
import {useTranslation} from 'react-i18next';

const BasketballTitleScore = ({game}) => {
  const {t} = useTranslation();

  const halfValue = useMemo(() => {
    let gameHalf = game?.info?.current_game_state;
    gameHalf = gameHalf?.replace?.(/set1/g, '1st');
    gameHalf = gameHalf?.replace?.(/set2/g, '2nd');
    gameHalf = gameHalf?.replace?.(/set3/g, '3rd');
    gameHalf = gameHalf?.replace?.(/set4/g, '4th');
    gameHalf = gameHalf?.replace?.(/set5/g, 'OT');

    return gameHalf?.trim?.()?.replace?.('notstarted', 'notStarted') || '1st';
  }, [game?.info?.current_game_state]);

  return (
    !!game?.is_live && (
      <div className="flex gap-5">
        <span className={`bold-700 font-10 orangeText`}>{t(halfValue)}</span>
        {game?.info?.current_game_time &&
          game?.info?.current_game_state !== 'Half End' && (
            <span className="blueText bold-400 font-10">
              {halfValue !== 'OT' ? t('quarter') : ''}{' '}
              <span className="bold-700 font-10 orangeText">
                {game?.info?.current_game_time?.split?.(':')?.[0]}'
              </span>
            </span>
          )}
      </div>
    )
  );
};

export default BasketballTitleScore;
