import {LICENSE_ID} from './Socket';
import {FILTERS_TODAY_NAME} from './MatchFilters';
export const PHONE_CODE = '255';
export const PHONE_NUMBER_PREFIX = 255;
export const PLACE_BET_WARNING = 'place_bet';
export const LOGGED_OUT_WARNING = 'logged_out';
export const DATE_DEFAULT_FORMAT = 'DD/MM/YYYY';
export const CALL_TO_ACTION_EXAMPLE_STAKE = 2000;
export const NAME_VALIDATION_REGEX = /^[A-Za-z]+$/;
export const BIRTH_DATE_PLACEHOLDER = 'YYYY-MM-DD';
export const HTML_FORMATTER_REGEX = /<\/?[^>]+(>|$)/g;
export const PHONE_INPUT_PLACEHOLDER = 'e.g 623 123456';
export const PHONE_NUMBER_PLACEHOLDER = 'e.g 623 123456';
export const URL_FINDER_REGEX = /(http[s]?:\/\/[^\s<]+)/g;
export const CANT_BE_COMBINED_WARNING = 'cant_be_combined';
export const DATE_FORMAT_WITH_HOURS_MINUTES = 'DD/MM/YYYY - HH:mm';
export const NUMBERS_FORMAT_WITH_COMMA_REGEX = /\B(?=(\d{3})+(?!\d))/g;

export const CONTACT_NUMBER = '+255620818181';
export const CONTACT_EMAIL = 'support@pigabet.co.tz';

export const IOS_APP_URL = 'https://apps.apple.com/tz/app/pigabet/id1658994137';
export const ANDROID_APP_URL =
  'https://play.google.com/store/apps/details?id=com.pigabet';

export const USER_ID = 'USER_ID';
export const COMMUNITY_CHAT_ROOM_ID = 969;

export const LIVE_CHAT_URI = `https://secure.livechatinc.com/customer/action/open_chat?license_id=${LICENSE_ID}`;
export const COMMUNITY_CHAT_URI = `https://web-pigabet.dev.watchers.io/?roomId=${COMMUNITY_CHAT_ROOM_ID}&userId=${USER_ID}`;

export const APP_MODAL_CLOSE_TIMEOUT_MS = 300;

export const GAME_VISUALIZATION_TOKEN =
  'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJjb21wYW55X25hbWUiOiJiZXRjb25zdHJ1Y3RfZGV2IiwiaXNzIjoibWxwcm94eSIsInNwb3J0IjpbImFsbCJdLCJmZWVkX3R5cGUiOlsiYWxsIl0sInByb3ZpZGVycyI6WyJhbGwiXX0.XrRZDu-SYt4yOKTuW8_8XoF5Y0R8K5wPsSvh01g5-d0';

export const SUPPORTED_SPORTS_FOR_VISUALIZATION = [
  'Darts',
  'Tennis',
  'Soccer',
  'Hockey',
  'Cricket',
  'Baseball',
  'Handball',
  'WaterPolo',
  'RugbyUnion',
  'Volleyball',
  'Basketball',
  'RugbyLeague',
  'TableTennis',
  'AmericanFootball',
];

export const GENDERS = [
  {value: 'M', name: 'male'},
  {value: 'F', name: 'female'},
];

export const BREAKPOINT_XXS = 768;
export const BREAKPOINT_XS = 1024;
export const BREAKPOINT_SM = 1023;
export const BREAKPOINT_MD = 1439;
export const BREAKPOINT_LG = 1919;

export const SPORTS_PAGE_SIDEBAR_WIDTH = 255;

export const COUNTRIES = [
  {
    value: 'ZW',
    name: 'Zimbabwe',
    logo: require('../Assets/Images/Flags/Zimbabwe.png'),
  },
  {
    value: 'TZ',
    name: 'Tanzania',
    logo: require('../Assets/Images/Flags/UnitedRepublicofTanzania.png'),
  },
];

export const SELF_EXCLUSIONS = [
  {value: 1, title: '6months', limit: {months: 6}},
  {value: 2, title: '1yearLower', limit: {years: 1}},
  {value: 3, title: '2years', limit: {years: 2}},
  {value: 4, title: '5years', limit: {years: 5}},
];

export const TIME_OUT = [
  {value: 1, title: '24hours', limit: {days: 1}},
  {value: 2, title: '3days', limit: {days: 3}},
  {value: 3, title: 'oneWeek', limit: {days: 7}},
];

export const BONUS_INFO_DATA = [
  {title: 'count', id: 0},
  {title: 'minOdds', id: 1},
  {title: 'minStake', id: 2},
  {title: '%', id: 3},
];

export const TOP_WINNERS_HEADER_TITLE = 'TOP WINNERS';
export const LAST_WINNERS_HEADER_TITLE = 'LAST WINNERS';

export const WINNERS_HEADER_DATA = [
  {
    id: 2,
    name: 'topWinners',
    title: TOP_WINNERS_HEADER_TITLE,
  },
  {
    id: 1,
    name: 'lastWinners',
    title: LAST_WINNERS_HEADER_TITLE,
  },
];

export const MOBILE_BOTTOM_TABS_SPORTS = [
  {
    id: 1,
    icon: 'casino',
    title: 'casino',
    path: '/casino/lobby',
  },
  {
    id: 2,
    icon: 'explore',
    title: 'sports',
    path: '/sports',
    routePath: `/sports?sport=Soccer&gameFilter=${FILTERS_TODAY_NAME}`,
  },
  {
    id: 3,
    icon: 'betSlip',
    // path: '/betSlip',
    action: 'betSlip',
    title: 'betSlipLower',
  },
  {
    id: 4,
    icon: 'jackpot',
    path: '/jackpot',
    title: 'jackpot',
  },
  {
    id: 5,
    icon: 'liveChat',
    // path: '/liveChat',
    title: 'liveChat',
    action: 'liveChat',
  },
];

export const MOBILE_BOTTOM_TABS_CASINO = [
  {
    id: 1,
    icon: 'casino',
    title: 'lobby',
    path: '/casino/lobby',
    routePath: '/casino/lobby?provider=all',
  },
  {
    id: 2,
    icon: 'explore',
    title: 'sports',
    path: `/sports?sport=Soccer&gameFilter=${FILTERS_TODAY_NAME}`,
  },
  {
    id: 3,
    icon: 'slots',
    title: 'slots',
    path: '/casino/slots',
  },
  {
    id: 4,
    icon: 'liveCasino',
    title: 'liveCasino',
    path: '/casino/liveCasino',
  },
  {
    id: 5,
    icon: 'poker',
    title: 'poker',
    action: 'poker',
    path: '/casino/poker',
  },
];

export const BALANCE_MANAGEMENT_CARDS = [
  {
    id: 1,
    icon: 'balance',
    title: 'mainBalance',
  },
  {
    id: 2,
    icon: 'bonus',
    title: 'totalBonusBalance',
  },
];
