import {useCallback} from 'react';
import axios from 'axios';
import {useSelector} from 'react-redux';
import {
  useNavigate,
  useLocation,
  createSearchParams,
  useSearchParams,
} from 'react-router-dom';

import {useStorage} from './index';

import {getLanguage} from '../Redux/AppSlice/AppSelectors';

import {
  CATEGORY_LIVE_CASINO_ID,
  CATEGORY_SKILL_GAMES_ID,
  CATEGORY_SLOTS_GAMES_ID,
  CATEGORY_BOOSTED_RTP_GAMES_ID,
} from '../Constants/CasinoMenuFilter';
import {SITE_ID} from '../Constants/Socket';
import {APP_LANGUAGES} from '../Constants/Languages';

const UseCasino = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [getParam, setGetParam] = useSearchParams();

  const {setLastPlayedGamesToStorage} = useStorage();

  const language = useSelector(getLanguage);

  const goToCasinoGameScreen = useCallback(
    ({game, mode}) => {
      if (game?.categories) {
        const categoryId = game?.categories?.find(
          item =>
            +item === CATEGORY_LIVE_CASINO_ID ||
            +item === CATEGORY_SLOTS_GAMES_ID ||
            +item === CATEGORY_SKILL_GAMES_ID ||
            +item === CATEGORY_BOOSTED_RTP_GAMES_ID,
        );

        setLastPlayedGamesToStorage(
          game?.extearnal_game_id,
          categoryId,
          game?.provider,
        )
          ?.then?.()
          ?.catch?.();
      }
      getParam.set('mode', mode);
      getParam.set('casinoGameId', game?.extearnal_game_id);
      setGetParam(getParam);
      // navigate({
      //   pathname: path || location?.pathname,
      //   search: createSearchParams({
      //     game: game?.extearnal_game_id,
      //   }).toString(),
      // });
    },
    [getParam, setGetParam, setLastPlayedGamesToStorage],
  );

  const getCasinoGames = useCallback(
    ({
      limit = 30,
      offset = 0,
      category = '',
      provider = '',
      searchQuery = '',
      externalIds = '',
      extra_category_id = '',
    } = {}) => {
      const baseUrl = `https://cmsbetconstruct.com/casino/getGames?partner_id=${SITE_ID}&lang=${
        language === APP_LANGUAGES.CHN ? 'chi' : language
      }&offset=${offset}&limit=${limit}${
        !!searchQuery ? `&search=${searchQuery}` : ''
      }${category ? `&category=${category}` : ''}${
        provider ? `&provider=${provider}` : ''
      }${externalIds ? `&external_id=${externalIds}` : ''}${
        extra_category_id ? `&extra_category_id=${extra_category_id}` : ''
      }`;
      return axios.get(`${baseUrl}`);
    },
    [language],
  );

  const getSubCategories = useCallback(
    ({categoryId = null, extra_category_id = null}) => {
      return categoryId === CATEGORY_LIVE_CASINO_ID
        ? axios.get(
            'https://cmsbetconstruct.com/casino/getOptionsByGameOrder' +
              `?partner_id=${SITE_ID}&lang=${
                language === APP_LANGUAGES.CHN ? 'chi' : language
              }&category_id=${CATEGORY_LIVE_CASINO_ID}&with_extra_categories=1${
                extra_category_id
                  ? `&extra_category_id=${extra_category_id}`
                  : ''
              }`,
          )
        : axios.get(
            'https://cmsbetconstruct.com/casino/getOptions' +
              `?partner_id=${SITE_ID}&only_categories=1&lang=${
                language === APP_LANGUAGES.CHN ? 'chi' : language
              }` +
              (categoryId ? `&categories=${categoryId}` : ''),
          );
    },
    [language],
  );

  const getProviders = useCallback(
    ({categoryId = null} = {}) => {
      return axios.get(
        'https://cmsbetconstruct.com/casino/getOptions' +
          `?partner_id=${SITE_ID}&only_providers=1&lang=${
            language === APP_LANGUAGES.CHN ? 'chi' : language
          }` +
          (categoryId ? `&categories=${categoryId}` : ''),
      );
    },
    [language],
  );

  return {
    getProviders,
    getCasinoGames,
    getSubCategories,
    goToCasinoGameScreen,
  };
};

export default UseCasino;
