import React, {memo} from 'react';
import {useSelector} from 'react-redux';

import {getCurrency} from '../../../../Redux/PartnerSlice/PartnerSelectors';

import {bigNumbersPrettier} from '../../../../Utils/BigNumbersPrettier';
import {ReactComponent as CurrencyIcon} from '../../../../Assets/Icons/Globals/TZS.svg';

const CalculationRow = ({
  title,
  amount,
  customStyles = {},
  amountTextClassName = {},
  hideCurrency = false,
  amountContainerWidth = 170,
  amountContainerHeight = 35,
  titleClassName = 'whiteText bold-700 font-12',
  amountClassName = 'whiteText bold-700 font-12',
  amountContainerClassName = 'newLightBlueBackground pa-md overflow-hidden',
}) => {
  const currency = useSelector(getCurrency);

  return (
    <div className={`row justify-between items-center`}>
      {typeof title === 'string' ? (
        <span className={`${titleClassName} bold-700 font-12`}>{title}</span>
      ) : (
        title
      )}
      <div
        style={{
          width: amountContainerWidth,
          height: amountContainerHeight,
          ...customStyles,
        }}
        className={`${amountClassName} gap-10 row justify-end items-center rounded-borders ${
          amountContainerClassName ?? amountContainerClassName
        }`}>
        {hideCurrency ? (
          <></>
        ) : (
          <CurrencyIcon
            width={18}
            height={10}
            className="ml-sm"
            style={{boxShadow: '0px 4px 4px 0px #00000040'}}
          />
        )}
        {typeof amount === 'string' || typeof amount === 'number' ? (
          <span className={`ellipsis bold-700 font-12 ${amountTextClassName}`}>
            {bigNumbersPrettier(amount)}
          </span>
        ) : (
          amount
        )}
      </div>
    </div>
  );
};

export default memo(CalculationRow);
