import React, {memo, useCallback, useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {Swiper, SwiperSlide} from 'swiper/react';
import {FreeMode, Keyboard} from 'swiper/modules';
import {useNavigate} from 'react-router-dom';

import './index.scss';

import {getWssSocket} from '../../../../Redux/AppSlice/AppSelectors';
import {getCurrency} from '../../../../Redux/PartnerSlice/PartnerSelectors';
import {setMainMenuModal, setSuccessModal} from '../../../../Redux/AppSlice';

import {useScreenSize, useSocket} from '../../../../Hooks';

import {bigNumbersPrettier} from '../../../../Utils/BigNumbersPrettier';

import {
  BREAKPOINT_XS,
  APP_MODAL_CLOSE_TIMEOUT_MS,
} from '../../../../Constants/Globals';
import {BANK, PAYMENT_SERVICES} from '../../../../Constants/PaymentServices';
import {MAKE_DEPOSIT, SOCKET_RESPONSES} from '../../../../Constants/Socket';

import {AppButton, AppInput} from '../../../UI';
import OperatorInfoText from '../OperatorInfoText';

const Deposit = ({userOperator}) => {
  const {t} = useTranslation();
  const {deposit} = useSocket();
  const dispatch = useDispatch();
  const {width} = useScreenSize();
  const navigate = useNavigate();

  const currency = useSelector(getCurrency);
  const wssSocket = useSelector(getWssSocket);

  const inputRef = useRef(null);

  const [errorMsg, setErrorMsg] = useState(null);
  const [depositAmount, setDepositAmount] = useState('');
  const [isDepositLoading, setIsDepositLoading] = useState(false);
  const [activeOperator, setActiveOperator] = useState(
    PAYMENT_SERVICES.find(item => item?.title === userOperator) ||
      PAYMENT_SERVICES?.[0],
  );

  const depositClickHandler = useCallback(() => {
    setIsDepositLoading(true);
    deposit({
      amount: depositAmount,
      service: activeOperator?.id,
    });
  }, [activeOperator, deposit, depositAmount]);

  const onMessageCb = useCallback(
    event => {
      const data = JSON.parse(event.data);
      switch (data?.rid) {
        case MAKE_DEPOSIT:
          if (!Object.keys(data?.data || {})?.length) {
            inputRef?.current.blur();
            setErrorMsg(t('somethingWentWrong'));
          } else if (data?.data?.status === 'error') {
            inputRef?.current.blur();
            setErrorMsg(data?.data?.message);
          } else {
            if (data?.data?.status === 'ok' && !!data?.data?.action) {
              window.open(data?.data?.action, '_blank');
              dispatch(
                setMainMenuModal({
                  isVisible: false,
                }),
              );
            } else {
              if (data?.data?.status === SOCKET_RESPONSES?.PENDING_MESSAGE) {
                dispatch(
                  setMainMenuModal({
                    isVisible: false,
                  }),
                );
                setTimeout(() => {
                  dispatch(
                    setSuccessModal({
                      isVisible: true,
                      successTitle: t('depositCompletedTitle'),
                      successDescription: t('depositCompletedDescription'),
                    }),
                  );
                }, APP_MODAL_CLOSE_TIMEOUT_MS);
              } else {
                inputRef?.current.blur();
                setErrorMsg(data?.data?.details?.error);
              }
            }
          }
          setIsDepositLoading(false);
          break;
        default:
          break;
      }
    },
    [dispatch, t],
  );

  useEffect(() => {
    wssSocket?.addEventListener('message', onMessageCb);

    return () => {
      wssSocket?.removeEventListener('message', onMessageCb);
    };
  }, [onMessageCb, wssSocket]);

  return (
    <div className="menu-balance-depositContainer pt-lg column">
      <span className="whiteText font-12 bold-500">
        {t('pleaseSelectAPaymentMethod')}
      </span>
      <div className="column gap-15 pt-sm pb-lg">
        <div className="row gap-10">
          {/*<div className="flex items-center justify-center rounded-borders pa-sm paymentsHeaderContainer flex-shrink">*/}
          {/*  <span className="text-center grayText bold-500">MOBILE MONEY</span>*/}
          {/*</div>*/}
          <Swiper
            keyboard
            freeMode
            mousewheel
            spaceBetween={7}
            modules={[Keyboard, FreeMode]}
            className="paymentsSwiper full-width"
            slidesPerView={width < BREAKPOINT_XS ? 'auto' : 5}
            initialSlide={
              PAYMENT_SERVICES?.findIndex?.(
                item => item?.title === userOperator,
              ) || 0
            }>
            {PAYMENT_SERVICES?.filter(item => item?.show !== false).map(
              item => (
                <SwiperSlide key={item?.id}>
                  <img
                    src={item?.logo}
                    alt={item?.title}
                    onClick={() => setActiveOperator(item)}
                    className={`block rounded-borders paymentSwiperImage cursor-pointer ${
                      activeOperator?.title !== item?.title
                        ? 'not-selected'
                        : ''
                    }`}
                  />
                </SwiperSlide>
              ),
            )}
          </Swiper>
        </div>
      </div>

      <div className="column gap-2">
        <span className="font-12 whiteText bold-500">{t('amount')}</span>
        <div className="row gap-10">
          <div className="flex-1">
            <AppInput
              type={9}
              height={35}
              error={errorMsg}
              inputRef={inputRef}
              className="bold-700"
              errorNumberOfLines={2}
              setErrorMsg={setErrorMsg}
              inputValue={depositAmount}
              onChange={setDepositAmount}
              placeholder={t('enterAmountToDeposit', {currency})}
            />
          </div>
          <div>
            <AppButton
              type={3}
              height={35}
              fontSize={15}
              titleClassName="bold-600"
              loading={isDepositLoading}
              onClick={depositClickHandler}
              title={t('deposit')?.toUpperCase?.()}
              className="flex items-center justify-center px-xxl"
              disabled={!+depositAmount || +depositAmount < 1000}
            />
          </div>
        </div>
        <span className="font-10 bold-400 " style={{color: '#FFFFFF59'}}>
          {t('minMaxLimits', {
            currency,
            minValue:
              activeOperator?.title === BANK ? bigNumbersPrettier(10000) : 1000,
            maxValue: bigNumbersPrettier(
              activeOperator?.title === BANK ? 10000000 : 5000000,
            ),
          })}
        </span>
      </div>
      {activeOperator?.title !== BANK && (
        <OperatorInfoText activeOperator={activeOperator?.title} />
      )}
    </div>
  );
};

export default memo(Deposit);
