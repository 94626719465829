import React, {useMemo} from 'react';
import {useSelector} from 'react-redux';

import {getBetSlipCount} from '../../../Redux/AppSlice/AppSelectors';

import EventListItem from './EventListItem';

const EventsList = ({marketItem, team1Name, team2Name, gameId}) => {
  const betSlipCount = useSelector(getBetSlipCount);

  const eventsArray = useMemo(
    () =>
      Object.values(marketItem?.event || {})?.sort(
        (a, b) => a?.order - b?.order,
      ) || [],
    [marketItem?.event],
  );

  return eventsArray?.map((eventItem, index) => {
    return (
      <EventListItem
        gameId={gameId}
        eventItem={eventItem}
        team1Name={team1Name}
        team2Name={team2Name}
        marketItem={marketItem}
        key={
          `${betSlipCount}_count_${eventItem?.id}_id_${eventItem?.name}_name` ||
          index
        }
      />
    );
  });
};

// DO NOT PUT IN MEMO
export default EventsList;
