import React, {memo, useEffect, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useSearchParams} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';

import './index.scss';

import {useOutSideClickHandler, useScreenSize} from '../../Hooks';

import {
  getIsLoggedIn,
  getShowMobileBetSlip,
} from '../../Redux/AppSlice/AppSelectors';
import {getIsGameDataLoading} from '../../Redux/SportSlice/SportSelectors';
import {getHeaderTabIndex} from '../../Redux/BettingSlice/BettingSelectors';

import OpenBets from './Tabs/OpenBets';
import MainBetting from './Tabs/MainBetting';
import SettledBets from './Tabs/SettledBets';
import HeaderTabs from './Components/HeaderTabs';

import {BREAKPOINT_XS} from '../../Constants/Globals';
import coloredClose from '../../Assets/Icons/Globals/coloredClose.svg';
import {setShowBetSlip, setShowMobileBetSlip} from '../../Redux/AppSlice';

const BetSlip = () => {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const [getParam] = useSearchParams();
  const {width, height} = useScreenSize();

  const isLoggedIn = useSelector(getIsLoggedIn);
  const headerTabIndex = useSelector(getHeaderTabIndex);
  const showMobileBetSip = useSelector(getShowMobileBetSlip);
  const isGameDataLoading = useSelector(getIsGameDataLoading);

  const [maxHeight, setMaxHeight] = useState(
    document.querySelector('.homePageContainer')?.offsetHeight +
      document.querySelector('.topWinnersContainer')?.offsetHeight,
  );

  const sportGameIdParam = getParam.get('sportGameId');

  useEffect(() => {
    if (!isGameDataLoading) {
      setMaxHeight(
        document.querySelector('.homePageContainer')?.offsetHeight +
          document.querySelector('.topWinnersContainer')?.offsetHeight,
      );
    }
  }, [isGameDataLoading]);

  return (
    <>
      <div
        className={`column appBetSlipContainer ${
          width < BREAKPOINT_XS
            ? 'mobile flex items-center justify-center fixed rounded-top-borders'
            : 'desktop'
        } ${headerTabIndex === 0 && !!showMobileBetSip && ''}`}
        style={{
          zIndex: 15,
          borderTop: width < BREAKPOINT_XS ? '1px solid var(--appYellow)' : '',
          borderLeft: width < BREAKPOINT_XS ? '1px solid var(--appYellow)' : '',
          borderRight:
            width < BREAKPOINT_XS ? '1px solid var(--appYellow)' : '',
          width: width < BREAKPOINT_XS ? 'calc(100% - 10px)' : 'auto',
          backgroundColor: '#003069',
          // height: !!sportGameIdParam
          //   ? height - 46 - 40 - (isLoggedIn ? 0 : 54) - 15
          //   : width < BREAKPOINT_XS
          //   ? isLoggedIn
          //     ? 'calc(100dvh - 105px)'
          //     : 'calc(100dvh - 160px)' //height - 40 - (isLoggedIn ? 0 : 54)
          //   : 'auto',
          height:
            width > BREAKPOINT_XS
              ? '100%'
              : isLoggedIn
              ? 'calc(100dvh - 102px)'
              : 'calc(100dvh - 156px)',

          // maxHeight: maxHeight - 50, // no need for now if will be request regarding betslip max height just uncomment this line
        }}>
        {width < BREAKPOINT_XS && (
          <div
            id="headerDragArea"
            className="row items-center  justify-between yellowBackground pa-sm full-width"
            style={{borderTopRightRadius: 10, borderTopLeftRadius: 10}}>
            <div
              style={{
                width: 26,
                height: 26,
              }}
            />
            <span className="font-15 blueText bold-600">{t('myBets')}</span>
            <div
              style={{
                width: 26,
                height: 26,
              }}
              id="betslipCloseContainer"
              onClick={() => dispatch(setShowMobileBetSlip(false))}
              className="whiteBackground items-center justify-center flex rounded-borders cursor-pointer z-index-1">
              <img
                alt="close"
                width={10}
                height={10}
                src={coloredClose}
                id="betslipCloseIcon"
              />
            </div>
          </div>
        )}
        {isLoggedIn && (
          <div
            className={`flex full-width ${
              width < BREAKPOINT_XS ? 'pb-sm' : 'px-sm'
            }`}>
            <HeaderTabs />
          </div>
        )}
        <div
          className={`full-width flex overflow-hidden betSlipContentWrapper full-height ${
            width < BREAKPOINT_XS
              ? 'mobile px-sm'
              : `desktop pa-sm ${
                  headerTabIndex !== 1 && headerTabIndex !== 2
                    ? 'full-height'
                    : ''
                }`
          }`}>
          <MainBetting />
          {headerTabIndex === 1 ? (
            <OpenBets />
          ) : headerTabIndex === 2 ? (
            <SettledBets />
          ) : null}
        </div>
      </div>
    </>
  );
};

export default memo(BetSlip);
