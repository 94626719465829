import React, {
  memo,
  useRef,
  useMemo,
  useState,
  useEffect,
  useCallback,
} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useSearchParams} from 'react-router-dom';

import './index.scss';

import {
  getWssSocket,
  getBetSlipCount,
} from '../../../Redux/AppSlice/AppSelectors';
import {setSelectedGameIds} from '../../../Redux/SportSlice';
import {setIsBetSlipDataLoading} from '../../../Redux/BettingSlice';
import {
  getBoostedGameIds,
  getSelectedGameIds,
} from '../../../Redux/SportSlice/SportSelectors';

import {useScreenSize, useSocket, useStorage} from '../../../Hooks';

import {formatEventName} from '../../../Utils/EventNamesFormatter';
import {fixedNumberWithoutRound} from '../../../Utils/GetOddValue';

import {
  UNSUBSCRIBE_BULK,
  UNSUBSCRIBE_LIVE_EVENT,
} from '../../../Constants/Socket';

import {MARKETS_HANDICAP} from '../../../Constants/MatchFilters';

import {ReactComponent as IncreaseIcon} from '../../../Assets/Icons/Globals/increased.svg';
import {ReactComponent as DecreaseIcon} from '../../../Assets/Icons/Globals/decreased.svg';
import {ReactComponent as BoostedIcon} from '../../../Assets/Icons/Globals/BoostedIcon.svg';

let debounceTimeout = null;

const EventListItem = ({
  gameId,
  eventItem,
  marketItem,
  team1Name,
  team2Name,
  showBoostedIcon,
}) => {
  const dispatch = useDispatch();
  const {width} = useScreenSize();
  const [getParam] = useSearchParams();
  const {getBetSlipDataFromStorage} = useStorage();
  const {subscribeToEvent, unSubscribeLiveEvents} = useSocket();

  const wssSocket = useSelector(getWssSocket);
  const betSlipCount = useSelector(getBetSlipCount);
  const boostedIds = useSelector(getBoostedGameIds);
  const selectedGameIds = useSelector(getSelectedGameIds);

  const eventId = useMemo(() => eventItem?.id, [eventItem?.id]);

  const sportGameIdParam = useMemo(
    () => +getParam.get('sportGameId'),
    [getParam],
  );
  const marketId = useMemo(() => marketItem?.id, [marketItem?.id]);

  const isOddIncreased = useMemo(
    () => eventItem?.price > eventItem?.lastPrice,
    [eventItem?.lastPrice, eventItem?.price],
  );

  const isBoostedIconVisible = useMemo(
    () => boostedIds?.[gameId]?.[marketId]?.[eventId],
    [boostedIds, eventId, gameId, marketId],
  );

  const previousLastPriceRef = useRef(eventItem?.lastPrice);

  const [showOddChange, setShowOddChange] = useState(false);
  const [selectedEventId, setSelectedEventId] = useState(
    getBetSlipDataFromStorage()?.find(item => item?.unique_id === eventId)
      ?.unique_id || null,
  );

  const clickHandler = useCallback(
    e => {
      e.stopPropagation();
      clearTimeout(debounceTimeout);
      debounceTimeout = setTimeout(() => {
        if (!formatEventName({team1Name, team2Name, name: eventItem?.name})) {
          return;
        }
        setSelectedEventId(eventId);

        const newSelectedGameIds = [...selectedGameIds];

        if (eventId === selectedEventId) {
          const index = newSelectedGameIds.indexOf(gameId);
          if (index > -1) {
            newSelectedGameIds.splice(index, 1); // 2nd parameter means remove one item only
          }

          setSelectedEventId(null);
          unSubscribeLiveEvents({
            subId: getBetSlipDataFromStorage()?.find(
              item => item?.unique_id === eventId,
            )?.subId,
            rid: `${UNSUBSCRIBE_LIVE_EVENT}_${eventId}`,
          });
        } else {
          newSelectedGameIds.push(gameId);

          subscribeToEvent({eventId, gameId: sportGameIdParam, marketId});
          setSelectedEventId(eventId);
          dispatch(setIsBetSlipDataLoading(true));
        }

        dispatch(setSelectedGameIds(newSelectedGameIds));
      }, 500);
    },
    [
      gameId,
      eventId,
      team1Name,
      marketId,
      dispatch,
      team2Name,
      selectedGameIds,
      eventItem?.name,
      selectedEventId,
      subscribeToEvent,
      sportGameIdParam,
      unSubscribeLiveEvents,
      getBetSlipDataFromStorage,
    ],
  );

  const onMessageCb = useCallback(
    event => {
      const data = JSON.parse(event.data);
      if (data?.rid?.includes(`${UNSUBSCRIBE_LIVE_EVENT}_`)) {
        const unsubscribedEventId = +data?.rid?.split?.('_')?.[1];
        if (unsubscribedEventId === eventId) {
          setSelectedEventId(null);
        }
      }
      if (data?.rid === `${UNSUBSCRIBE_BULK}_betSlip`) {
        setSelectedEventId(null);
      }
    },
    [eventId],
  );

  useEffect(() => {
    wssSocket?.addEventListener('message', onMessageCb);

    return () => {
      wssSocket?.removeEventListener('message', onMessageCb);
    };
  }, [onMessageCb, wssSocket]);

  const TriangleIcon = isOddIncreased ? IncreaseIcon : DecreaseIcon;

  useEffect(() => {
    if (previousLastPriceRef.current !== eventItem?.lastPrice) {
      previousLastPriceRef.current = eventItem?.lastPrice;
      if (typeof eventItem?.lastPrice !== 'undefined') {
        setShowOddChange(true);
        setTimeout(() => setShowOddChange(false), 1500);
      }
    }
  }, [eventItem?.lastPrice]);

  return (
    <div
      key={betSlipCount}
      onClick={clickHandler}
      style={{
        maxHeight: 60,
        minHeight: 35,
        width: `calc(${100 / marketItem?.col_count}% - ${
          !!marketItem?.col_count && marketItem?.col_count > 1 ? '2px' : '0px'
        })`,
      }}
      className={`flex rowCenter gap-5 justify-between items-center cursor-pointer eventListItemContainer relative whiteText ${
        eventId === selectedEventId ? 'selected' : 'no-selected'
      }`}>
      <div
        className={`threeLineText bold-600 font-12 ${
          eventId === selectedEventId ? 'blueText' : 'whiteText'
        }`}>
        <span>
          {formatEventName({team1Name, team2Name, name: eventItem?.name}) ||
            '-'}
        </span>
        <span>
          {marketItem?.display_key?.includes(MARKETS_HANDICAP)
            ? `(${+eventItem?.base > 0 ? '+' : ''}${eventItem?.base})`
            : eventItem?.base
            ? ` (${eventItem?.base})`
            : ''}
        </span>
      </div>
      <div className="rowCenter gap-3">
        {isBoostedIconVisible && isBoostedIconVisible && (
          <BoostedIcon width={14} height={14} />
        )}
        <span
          className={`bold-600 font-${
            typeof eventItem?.lastPrice === 'undefined' || !showOddChange
              ? '14'
              : '15'
          }`}
          style={{
            color:
              typeof eventItem?.lastPrice === 'undefined' || !showOddChange
                ? eventId === selectedEventId
                  ? 'var(--appBlue)'
                  : 'var(--white)'
                : isOddIncreased
                ? '#39FF14'
                : '#FC0E0B',
          }}>
          {fixedNumberWithoutRound(eventItem?.price)}
        </span>
      </div>
      {/*{typeof eventItem?.lastPrice !== 'undefined' && (*/}
      {/*  <TriangleIcon*/}
      {/*    width={8}*/}
      {/*    height={6}*/}
      {/*    className={`oddChangeIcon absolute ${*/}
      {/*      !isOddIncreased ? 'increased' : 'decreased'*/}
      {/*    }`}*/}
      {/*  />*/}
      {/*)}*/}
    </div>
  );
};

export default memo(EventListItem);
