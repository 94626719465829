import {useCallback} from 'react';
import I18n from 'i18next';
import moment from 'moment';
import {useDispatch, useSelector} from 'react-redux';

import store from '../Redux/store';

import {
  setLanguage,
  setAuthModal,
  setIsLoggedIn,
  setMainMenuModal,
} from '../Redux/AppSlice';
import {setPartnerSubId} from '../Redux/PartnerSlice';
import {setOpenBetsCount} from '../Redux/BettingSlice';
import {setUser, setUserSubId} from '../Redux/UserSlice';
import {getLanguage, getSid} from '../Redux/AppSlice/AppSelectors';

import {useSocket, useStorage} from './index';

const useAppFunctions = () => {
  const dispatch = useDispatch();

  const sid = useSelector(getSid);
  const language = useSelector(getLanguage);

  const {changeSessionLanguage, unSubscribeBulk} = useSocket();
  const {setLoginDataToStorage, removeLoginDataFromStorage} = useStorage();

  const changeLanguage = useCallback(
    lang => {
      if (sid) {
        changeSessionLanguage(lang || language || 'eng');
      }
      dispatch(setLanguage(lang || language || 'eng'));
      I18n.changeLanguage(lang || language || 'eng').then(() => {});
    },
    [language, changeSessionLanguage, dispatch, sid],
  );

  const loginCb = useCallback(
    async ({user, user_id, jwe_token, auth_token, closeModal = true}) => {
      dispatch(
        setUser({
          ...user,
          user_id,
          auth_token,
          lastUpdatedTime: moment?.()?.unix?.(),
        }),
      );
      dispatch(setIsLoggedIn(true));
      if (closeModal) {
        dispatch(setAuthModal({isVisible: false}));
      }
      await setLoginDataToStorage({
        user_id,
        jwe_token,
        auth_token,
      });
    },
    [dispatch, setLoginDataToStorage],
  );

  const logoutCb = useCallback(
    async (removeLoginData = true) => {
      unSubscribeBulk({
        subIdsArray: [
          store.getState().user.userSubId,
          store.getState().partner.partnerSubId,
        ],
      });
      dispatch(setUser(null));
      dispatch(setUserSubId(null));
      dispatch(setIsLoggedIn(false));
      dispatch(setPartnerSubId(null));
      dispatch(setOpenBetsCount(null));
      dispatch(setMainMenuModal({isVisible: false}));
      // dispatch(setCasinoGame({game_options: null, isFullScreen: false}));
      // dispatch(
      //     setAuthModal({
      //         forceToOpenCasinoGameId: null,
      //         forceToOpenCasinoGameCategoryId: null,
      //     }),
      // );
      // dispatch(
      //     setCasinoGame({
      //         triggerCasinoBackButton: true,
      //     }),
      // );
      if (removeLoginData) {
        await removeLoginDataFromStorage();
      }
    },
    [dispatch, removeLoginDataFromStorage, unSubscribeBulk],
  );

  return {changeLanguage, loginCb, logoutCb};
};

export default useAppFunctions;
