import {useEffect, useMemo} from 'react';
import {useNavigate, useSearchParams} from 'react-router-dom';

import {useStorage} from '../../../Hooks';

const Affiliates = () => {
  const navigate = useNavigate();
  const [getParam] = useSearchParams();
  const {setBTagToStorage} = useStorage();

  const btagParam = useMemo(() => getParam.get('btag'), [getParam]);

  useEffect(() => {
    if (!!btagParam) {
      setBTagToStorage(btagParam);
    }
    navigate(`/sports`);
  }, [btagParam, navigate, setBTagToStorage]);

  return null;
};

export default Affiliates;
