import React, {memo, useCallback, useState} from 'react';
import {useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {useCollapse} from 'react-collapsed';

import './index.scss';

import {getTaxPercentage} from '../../../../Redux/PartnerSlice/PartnerSelectors';

import {fixedNumberWithoutRound} from '../../../../Utils/GetOddValue';

import CalculationRow from '../CalculationRow';

import {ReactComponent as CopyIcon} from '../../../../Assets/Icons/MainMenu/copy.svg';
import {ReactComponent as CurrencyIcon} from '../../../../Assets/Icons/Globals/TZS.svg';
import {ReactComponent as Chevron} from '../../../../Assets/Icons/Globals/chevronBottom.svg';
import {ReactComponent as WinBoosterIcon} from '../../../../Assets/Icons/Globals/winBooster.svg';

import {bigNumbersPrettier} from '../../../../Utils/BigNumbersPrettier';

let copyTimeout = null;

const CashOutCalculations = ({
  tax,
  stake,
  betId,
  bonus,
  payout,
  outcome,
  totalOdds,
  finalPayout,
}) => {
  const {t} = useTranslation();

  const taxPercentage = useSelector(getTaxPercentage);

  const [isCopied, setIsCopied] = useState(false);
  const [showRestCalculations, setShowRestCalculations] = useState(false);

  const {getCollapseProps, getToggleProps} = useCollapse({
    isExpanded: showRestCalculations,
  });

  const copyIdHandler = useCallback(
    async e => {
      e.stopPropagation();
      clearTimeout(copyTimeout);
      await navigator.clipboard.writeText(betId);
      setIsCopied(true);
      copyTimeout = setTimeout(() => {
        setIsCopied(false);
      }, 1000);
    },
    [betId],
  );

  return (
    <div
      className="column gap-10 cashOutCalculationsContainer px-md pb-lg pt-xl"
      style={{borderBottom: !outcome ? '1px solid #B5B5B5' : ''}}>
      <div className="column">
        {!!betId && (
          <div className="row justify-between items-center overflow-hidden">
            <span className="semiLightGrayText bold-600 font-12 flex-shrink">
              {t('betId')} {betId}
            </span>
            <div className="gap-5 row justify-between bold-600 font-12 items-center overflow-hidden">
              <div className="row items-center gap-5 overflow-hidden">
                {isCopied ? (
                  <span className="semiLightGrayText bold-600 font-12">
                    {t('copied')}
                  </span>
                ) : (
                  <CopyIcon
                    width={11}
                    height={11}
                    fill="#B5B5B5"
                    onClick={copyIdHandler}
                  />
                )}
              </div>
            </div>
          </div>
        )}
        <CalculationRow
          amountTextClassName={`whiteText bold-700 font-18`}
          hideCurrency
          amountContainerHeight={20}
          amount={totalOdds}
          title={
            <span className={`bold-700 font-16 flex-shrink whiteText`}>
              {t('totalOdds')}
            </span>
          }
          amountContainerClassName="pr-none"
          titleClassName={
            outcome
              ? 'whiteText bold-700 font-12'
              : 'whiteText bold-700 font-12'
          }
          amountClassName={
            outcome
              ? 'whiteText bold-700 font-12'
              : 'whiteText bold-700 font-12'
          }
        />
        <CalculationRow
          amountContainerClassName="pr-none"
          amountTextClassName={`whiteText bold-700 font-18`}
          hideCurrency
          amountContainerHeight={20}
          amount={
            <div className="row items-center gap-10 overflow-hidden">
              <CurrencyIcon
                width={18}
                height={10}
                className="flex-shrink"
                style={{boxShadow: '0px 4px 4px 0px #00000040'}}
              />
              <span className={`ellipsis bold-700 whiteText font-18`}>
                {bigNumbersPrettier(fixedNumberWithoutRound(stake))}
              </span>
            </div>
          }
          title={
            <span className={`bold-700 font-16 flex-shrink whiteText`}>
              {t('betAmount')}
            </span>
          }
          titleClassName={
            outcome
              ? 'whiteText bold-700 font-12'
              : 'whiteText bold-700 font-12'
          }
          amountClassName={
            outcome
              ? 'whiteText bold-700 font-12'
              : 'whiteText bold-700 font-12'
          }
        />
        {!!bonus && (
          <CalculationRow
            amountTextClassName={`whiteText bold-700 font-18`}
            hideCurrency
            amountContainerClassName="pr-none"
            amountContainerHeight={20}
            amount={
              <div className="row items-center gap-5 overflow-hidden">
                <WinBoosterIcon
                  className="flex-shrink mt-sm"
                  style={{boxShadow: '0px 4px 4px 0px #00000040'}}
                />

                <span
                  className={`ellipsis bold-700 font-12 whiteText bold-700 font-18`}>
                  {bigNumbersPrettier(fixedNumberWithoutRound(bonus))}
                </span>
              </div>
            }
            amountClassName="whiteText"
            title={
              <span className={`bold-700 font-16 flex-shrink whiteText`}>
                {t('accumulatorBonus')}
              </span>
            }
          />
        )}

        {!!payout && (
          <CalculationRow
            amountTextClassName={`whiteText bold-700 font-18`}
            hideCurrency
            amountContainerClassName="pr-none"
            amountContainerHeight={20}
            amount={payout}
            amountClassName="whiteText"
            title={
              <span className="whiteText bold-700 font-16 flex-shrink">
                {outcome === 5
                  ? t('cashedOut')
                  : outcome === 3
                  ? t('wonAmount')
                  : t('returned')}
              </span>
            }
          />
        )}
        <div {...getCollapseProps()}>
          {!!tax && (
            <CalculationRow
              amountTextClassName={`whiteText bold-700 font-18`}
              hideCurrency
              amountContainerClassName="pr-none"
              amountContainerHeight={20}
              title={
                <span className="whiteText bold-700 font-16 flex-shrink">
                  <span className="">{t('tax')}</span>
                  <span> ({taxPercentage}%)</span>
                </span>
              }
              amount={tax}
              amountClassName={
                outcome
                  ? 'whiteText bold-700 font-12'
                  : 'whiteText bold-700 font-12'
              }
            />
          )}
        </div>
        {!!finalPayout && (
          <CalculationRow
            amountTextClassName={`whiteText bold-700 font-18`}
            amountContainerHeight={20}
            amountContainerClassName="pr-none"
            title={
              <div className="row items-center gap-10 flex-shrink whiteText">
                <span className={`bold-700 font-16 flex-shrink`}>
                  {t('finalPayout')}
                </span>
                <div
                  {...getToggleProps({
                    onClick: () => setShowRestCalculations(prev => !prev),
                  })}>
                  <div
                    style={{
                      width: 25,
                      height: 25,
                      flexShrink: 0,
                      borderRadius: '50%',
                      backgroundColor: showRestCalculations
                        ? 'var(--appYellow)'
                        : '#022751',
                      border: `1px solid ${
                        showRestCalculations ? 'transparent' : 'white'
                      }`,
                    }}
                    className="row items-center justify-center">
                    <Chevron
                      fill={showRestCalculations ? 'var(--appBlue)' : 'white'}
                      style={
                        showRestCalculations
                          ? {}
                          : {transform: 'rotate(180deg)'}
                      }
                    />
                  </div>
                </div>
              </div>
            }
            hideCurrency
            amount={
              <div className="row items-center gap-10 overflow-hidden">
                <CurrencyIcon
                  width={18}
                  height={10}
                  className="flex-shrink"
                  style={{boxShadow: '0px 4px 4px 0px #00000040'}}
                />
                <span
                  style={{
                    textShadow: '0px 4px 4px #000000D9',
                  }}
                  className={`yellowText font-18 bold-700 ellipsis`}>
                  {bigNumbersPrettier(fixedNumberWithoutRound(finalPayout))}
                </span>
              </div>
            }
            amountClassName="whiteText"
          />
        )}
      </div>
    </div>
  );
};

export default memo(CashOutCalculations);
